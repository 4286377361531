import { reset } from 'redux-form';
import { push, replace } from 'react-router-redux';
import { isEmpty, not, omit, pathOr } from 'ramda';
import { actions as toastrActions } from 'react-redux-toastr';
import FileSaver from 'file-saver';
import moment from 'moment';
import {
  call,
  delay,
  throttle,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import type { Saga } from 'redux-saga';
import ResidentService from '../../services/residentService';
import ApplicationStatusService from '../../services/applicationStatusService';
import LeaseService from '../../services/leaseService';
import CustomersService from '../../services/customersService';
import PayLeaseService from '../../services/payLeaseService';
import PortalService from '../../services/portalService';
import TransUnionService from '../../services/transUnionService';
import UnitService from '../../services/unitService';
import WriteOffService from '../../services/writeOffService';
import ApplicationService from '../../services/applicationService';
import { base64FileDowload } from '../../services/socketService';
import CamRecordService from '../../services/camRecordService';
import * as ActionTypes from './constants';
import * as residentActions from './actions';
import type { Action, Property } from '../App/types';
import messages from './messages.js';
import appMessages from '../ApplicationProfile/messages';
import type { DoNotRenew, ResidentMoveOutRequest } from './types.js';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import {
  addWriteOffsForCustomer,
  getWriteOffsForCustomer,
} from '../App/actions';
import { getCurrentResidentId, getCustomerId } from './selectors';
import { refreshLedger } from '../Ledger/actions';
import { getSignatureStatuses } from '../LeaseDataTab/actions';
import { getAllDocuments } from '../ManageDocuments/actions';
import {
  getProspectCompletedActivities,
  getProspectAllActivities,
} from '../PeopleProfile/actions';
import {
  DOCUMENTS_DEFAULT_PAGE,
  DOCUMENTS_DEFAULT_LIMIT,
  DOCUMENTS_DEFAULT_SORTING,
} from '../ManageDocuments/constants';

import { getResidentHousehold } from '../EditResidentHousehold/actions';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import type { FasReady } from './types';
import { getSelectedOptionalAddendumIds } from '../LeaseDataTab/LeaseDataTabForm/LeaseDataTabFormSections/LeasePacket/reducer.js'; // eslint-disable-line
import {
  handleLeaseDownloadErrorMessage,
  handleLeaseSaveErrorMessage,
} from '../../utils/errors';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchUpdateRecompleteStatus({ payload }: any): Saga<void> {
  const { applicationId, voucherEffectiveDate, residentId, showQualTab } =
    payload;
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const selectedProperty = yield select(selectSelectedProperty);
  validateSelectedProperty(selectedProperty);
  const applicationService = new ApplicationService();
  yield applicationService.setRecompleteStarted(
    organizationId,
    selectedProperty.id,
    applicationId,
    voucherEffectiveDate,
  );
  yield put(residentActions.getOneResident(residentId));
  showQualTab();
}

export function* fetchUpdateRecertStatus({ payload }: any): Saga<void> {
  const { applicationId, voucherEffectiveDate, residentId, showQualTab } =
    payload;
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const selectedProperty = yield select(selectSelectedProperty);
  validateSelectedProperty(selectedProperty);
  const applicationService = new ApplicationService();
  yield applicationService.setRecertStatusStarted(
    organizationId,
    selectedProperty.id,
    applicationId,
    voucherEffectiveDate,
  );
  yield put(residentActions.getOneResident(residentId));
  showQualTab();
}

export function* fetchgetOneResident(action: Action<string>): Saga<void> {
  /**
   * TODO: This is terrible and I feel bad. The payload should only ever be a
   * string, and we need to unravel the way we're pulling leases such that they
   * come from their own endpoint instead of being pulled off the resident.
   */
  const { payload } = action;
  let residentId, updateCurrentLeaseCb;
  if (typeof payload === 'string') {
    residentId = payload;
  } else {
    residentId = payload.residentId;
    updateCurrentLeaseCb = payload.updateCurrentLeaseCb;
  }
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);

    const pathname = yield select(
      (state) => state.routing?.location?.pathname ?? '',
    );
    const includeSoftDeletedUnits = pathname.includes('prior-resident');

    const organizationId = yield select(selectCurrentUserOrganizationId);
    const residentService = new ResidentService();
    const response = yield residentService.getResident(
      organizationId,
      selectedProperty.id,
      residentId,
      includeSoftDeletedUnits,
    );
    const applicationId = pathOr(null, ['applicantInfo', 'id'], response);
    if (applicationId !== null) {
      yield put(residentActions.getScreeningStatus(applicationId));
    }
    const pathArray: Array<any> = ['residentInfo', 'lease', 'units', 0, 'id'];
    const unitId = pathOr('', pathArray, response);
    if (not(isEmpty(unitId))) {
      yield put(residentActions.getUnitById(unitId));
    }
    yield put(residentActions.getOneResidentSuccess(response));
    const leases = response.leases;
    if (updateCurrentLeaseCb) {
      updateCurrentLeaseCb(leases);
    }
    const leaseId = leases.length > 0 ? leases[0].id : null;
    if (leaseId) {
      yield put(residentActions.getMonthlyTransactionsResident(leaseId));
    }
  } catch (err) {
    yield put(residentActions.getOneResidentError(err));
  }
}

export function* getOneResidentSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_RESIDENT, fetchgetOneResident);
}

export function* fetchMoveOutResident(
  action: Action<ResidentMoveOutRequest>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const residentService = new ResidentService();
    const isAllCommercial = selectedProperty.hasCommercialFloorPlans === 'ALL';
    yield residentService.moveOut(
      organizationId,
      selectedProperty.id,
      action.payload.residentId,
      action.payload.moveOutData,
    );
    yield put(residentActions.moveOutResidentSuccess());
    yield put(push('/'));
    yield put(reset('processMoveOut'));
    yield put(
      push(
        getUrlWithSelectedPropertyId(
          `/prior-resident/${action.payload.residentId}`,
        ),
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: isAllCommercial
          ? renderTranslatedMessage(messages.successDescriptionTenant)
          : renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    let message = err.toString();
    if (err === 'Insufficient scope') {
      yield put(push('/forbidden'));
      message = 'You do not have permissions for this action.';
    }
    yield put(
      toastrActions.add({
        message,
        type: 'error',
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* moveOutResidentSaga(): Saga<void> {
  yield throttle(500, ActionTypes.MOVE_OUT_RESIDENT, fetchMoveOutResident);
}

export function* updateResident(action: Action<any>): Saga<void> {
  try {
    const { id, type, residentId, residentUpdates } = action.payload;
    const isAdult = type === 'adult';
    const updates = isAdult
      ? residentUpdates
      : omit(['annualIncome'], residentUpdates);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const residentService = new ResidentService();
    yield residentService.updateResident(
      organizationId,
      selectedProperty.id,
      id,
      updates,
      isAdult,
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successEditResidentDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(getResidentHousehold(residentId));
    yield put(residentActions.getOneResident(residentId));
  } catch (err) {
    let message = err.toString();
    if (err === 'Insufficient scope') {
      yield put(push('/forbidden'));
      message = 'You do not have permissions for this action.';
    }
    yield put(
      toastrActions.add({
        message,
        type: 'error',
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateResidentSaga(): Saga<void> {
  yield throttle(500, ActionTypes.UPDATE_RESIDENT, updateResident);
}

export function* fetchDoNotRenewResident(
  action: Action<DoNotRenew>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const customersService = new CustomersService();
    yield customersService.doNotRenew(
      organizationId,
      selectedProperty.id,
      action.payload.customers,
    );
    yield put(
      residentActions.updateDoNotRenewSuccess(action.payload.doNotRenewStatus),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.doNotRenewSuccessDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        message: err.toString(),
        type: 'error',
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchUnderEvictionResident(action: Action<any>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const customersService = new CustomersService();
    yield customersService.underEviction(
      organizationId,
      selectedProperty.id,
      action.payload.customers,
      action.payload.leaseId,
    );
    yield put(
      residentActions.updateUnderEvictionSuccess(
        action.payload.underEvictionStatus,
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.underEvictionSuccessDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        message: err.toString(),
        type: 'error',
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* doNotRenewResidentSaga(): Saga<void> {
  yield throttle(500, ActionTypes.UPDATE_DO_NOT_RENEW, fetchDoNotRenewResident);
}

export function* fetchAutoEmailMonthlyInvoice(action: Action<any>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const customersService = new CustomersService();
    yield customersService.autoEmailMonthlyInvoice(
      organizationId,
      selectedProperty.id,
      action.payload.householdId,
      action.payload.autoEmailMonthlyInvoiceStatus,
    );
    yield put(
      residentActions.updateAutoEmailMonthlyInvoiceSuccess(
        action.payload.autoEmailMonthlyInvoiceStatus,
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.autoEmailMonthlyInvoice),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        message: err.toString(),
        type: 'error',
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}
export function* autoEmailMonthlyInvoiceSaga(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.UPDATE_AUTO_EMAIL_MONTHLY_INVOICE,
    fetchAutoEmailMonthlyInvoice,
  );
}

export function* fetchUpdateFasReadiness(action: Action<FasReady>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const leaseService = new LeaseService();
    const res = yield leaseService.updateFasReadiness(
      organizationId,
      selectedProperty.id,
      action.payload.leaseId,
      action.payload.fasReadyStatus,
    );
    yield put(residentActions.updateFasReadySuccess(res.fasReady));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.fasReadinessSuccessDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        message: err.toString(),
        type: 'error',
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateFasReadinessSaga(): Saga<void> {
  yield throttle(500, ActionTypes.UPDATE_FAS_READY, fetchUpdateFasReadiness);
}

export function* underEvictionResidentSaga(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.UPDATE_UNDER_EVICTION,
    fetchUnderEvictionResident,
  );
}

export function* postSaveNoticeToVacate({ payload }: Object): Saga<void> {
  try {
    const leaseService = new LeaseService();
    const residentId = yield select(getCurrentResidentId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);

    yield call(
      leaseService.saveNoticeToVacate,
      ...[organizationId, selectedProperty.id, payload.noticeToVacate],
    );
    yield put(residentActions.saveNoticeToVacateSuccess());
    payload?.refreshActivityTable?.();
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successNTVSaveDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(residentActions.getOneResident(residentId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveNoticeToVacate(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.SAVE_NOTICE_TO_VACATE,
    postSaveNoticeToVacate,
  );
}

export function* deleteDeleteNoticeToVacate({ payload }: Object): Saga<void> {
  try {
    const leaseService = new LeaseService();
    const residentId = yield select(getCurrentResidentId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    yield call(
      leaseService.deleteNoticeToVacate,
      ...[organizationId, selectedProperty.id, payload.noticeToVacate],
    );
    yield put(residentActions.saveNoticeToVacateSuccess());
    payload?.refreshActivityTable?.();
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successNTVDeleteDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(residentActions.getOneResident(residentId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* deleteNoticeToVacate(): Saga<void> {
  yield takeLatest(
    ActionTypes.DELETE_NOTICE_TO_VACATE,
    deleteDeleteNoticeToVacate,
  );
}

export function* fetchSaveMonthlyChargeResident({
  payload,
}: Object): Saga<void> {
  const { leaseData, leaseId, residentId, transaction, updateTransactionsCb } =
    payload;
  try {
    const residentService = new ResidentService();
    const organizationId: string = yield select(
      selectCurrentUserOrganizationId,
    );
    const property = yield select(selectSelectedProperty);

    yield put(
      residentActions.saveLeaseDataTabResident(leaseId, leaseData, residentId),
    );
    yield residentService.saveMonthlyChargeResident(
      organizationId,
      property.id,
      leaseId,
      transaction,
    );
    yield put(residentActions.saveMonthlyChargeResidentSuccess(leaseId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successMonthlyChargeSaved),
        title: renderTranslatedMessage(messages.successMonthlyChargeTitle),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );

    /**
     * TODO: Remove Cb when transactions are fully handled on Lease Data Tab
     */
    updateTransactionsCb();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetMonthlyTransactionsResident({
  payload,
}: Object): Saga<void> {
  try {
    const residentService = new ResidentService();
    const response = yield residentService.getMonthlyTransactionsResident(
      payload,
    );
    yield put(residentActions.getMonthlyTransactionsResidentSuccess(response));
  } catch (err) {
    yield put(residentActions.getMonthlyTransactionsResidentError(err));
  }
}

export function* saveMonthlyChargeResident(): any {
  yield throttle(
    500,
    ActionTypes.SAVE_MONTHLY_CHARGE_RESIDENT,
    fetchSaveMonthlyChargeResident,
  );
}

export function* getMonthlyTransactionsResident(): any {
  yield takeLatest(
    ActionTypes.GET_MONTHLY_TRANSACTIONS_RESIDENT,
    fetchGetMonthlyTransactionsResident,
  );
}

export function* watchSaveMonthlyChargeResidentSuccess(): Saga<void> {
  yield takeLatest(
    ActionTypes.SAVE_MONTHLY_CHARGE_RESIDENT_SUCCESS,
    fetchGetMonthlyTransactionsResident,
  );
}

export function* fetchGetAllApplicationStatus(): Saga<void> {
  try {
    const applicationStatusService = new ApplicationStatusService();
    const response = yield applicationStatusService.getAll();
    yield put(residentActions.getAllApplicationStatusSuccess(response));
  } catch (err) {
    yield put(residentActions.getAllApplicationStatusError(err));
  }
}

export function* getAllApplicationStatusSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_STATUS,
    fetchGetAllApplicationStatus,
  );
}

export function* fetchGetScreeningStatus({ payload }: Object): Saga<void> {
  try {
    const transUnionService = new TransUnionService();
    const response = yield call(
      transUnionService.getScreeningStatus,
      payload.applicationId,
    );
    yield put(
      residentActions.getResidentScreeningStatusSuccess(response.results || {}),
    );
  } catch (err) {
    yield put(residentActions.getResidentScreeningStatusError(err));
  }
}

export function* getScreeningStatus(): any {
  yield takeLatest(
    ActionTypes.GET_RESIDENT_SCREENING_STATUS,
    fetchGetScreeningStatus,
  );
}

export function* fetchRenewLease({ payload }: Object): Saga<void> {
  /**
   * TODO: This callback is the devil. Leases shouldn't have to be refreshed by
   * refreshing the entire resident.
   */
  const { previousLeaseId, lease, residentId, updateCurrentLeaseCb } = payload;
  try {
    const residentService = new ResidentService();
    yield residentService.renewLease(previousLeaseId, lease);
    yield put(
      residentActions.renewLeaseSuccess(residentId, updateCurrentLeaseCb),
    );
  } catch (err) {
    yield put(residentActions.renewLeaseFail());
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* renewLease(): Saga<void> {
  yield throttle(500, ActionTypes.RENEW_LEASE, fetchRenewLease);
}

export function* watchRenewLeaseSuccess(): Saga<void> {
  yield takeLatest(ActionTypes.RENEW_LEASE_SUCCESS, fetchgetOneResident);
}

export function* fetchTransferLease({ payload }: Object): Saga<void> {
  try {
    const organizationId: string = yield select(
      selectCurrentUserOrganizationId,
    );
    const property = yield select(selectSelectedProperty);
    const residentService = new ResidentService();
    yield residentService.startTransferLease(
      organizationId,
      property.id,
      payload.residentId,
      payload.applicationId,
      payload.unitId,
      payload.userId,
    );
    yield put(residentActions.transferLeaseSuccess(payload.residentId));
    yield put(
      push(
        getUrlWithSelectedPropertyId(`/resident/${payload.residentId}?tab=3`),
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescriptionTransfer),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(residentActions.transferLeaseFail());
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* transferLease(): Saga<void> {
  yield throttle(500, ActionTypes.TRANSFER_LEASE, fetchTransferLease);
}
export function* watchTransferLeaseSuccess(): Saga<void> {
  yield takeLatest(ActionTypes.TRANSFER_LEASE_SUCCESS, fetchgetOneResident);
}

export function* fetchSaveLeaseDataTabResident({
  payload,
}: Object): Saga<void> {
  const { leaseId, lease, residentId } = payload;
  try {
    const residentService = new ResidentService();
    const organizationId: string = yield select(
      selectCurrentUserOrganizationId,
    );
    const property = yield select(selectSelectedProperty);
    const optionalAddendums = yield select(getSelectedOptionalAddendumIds);
    yield residentService.saveLeaseDataTabResident(
      organizationId,
      property.id,
      leaseId,
      { ...lease, optionalAddendums },
    );
    yield put(residentActions.saveLeaseDataTabResidentSuccess(residentId));
    const { isRenewalComplete } = lease;
    if (isRenewalComplete) {
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(
            appMessages.successLeaseRenewalComplete,
          ),
          title: renderTranslatedMessage(appMessages.successLeaseSavedTitle),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(appMessages.successLeaseSaved),
          title: renderTranslatedMessage(appMessages.successLeaseSavedTitle),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    }
    payload?.refreshActivityTable?.();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: handleLeaseSaveErrorMessage(err),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchRemoveLease({ payload }: Object): Saga<void> {
  const { applicationId, leaseId, residentId, prospectId } = payload;
  try {
    const residentService = new ResidentService();
    yield residentService.removeLease(leaseId);
    yield put(residentActions.removeLeaseSuccess(residentId));
    yield put(
      getAllDocuments(
        applicationId,
        DOCUMENTS_DEFAULT_PAGE,
        DOCUMENTS_DEFAULT_LIMIT,
        DOCUMENTS_DEFAULT_SORTING,
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.removeLeaseComplete),
        title: renderTranslatedMessage(messages.successMonthlyChargeTitle),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(getSignatureStatuses(leaseId));
    yield put(getProspectCompletedActivities(prospectId));
    yield put(getProspectAllActivities(prospectId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveLeaseDataTabResident(): any {
  yield throttle(
    500,
    ActionTypes.SAVE_LEASE_DATA_TAB_RESIDENT,
    fetchSaveLeaseDataTabResident,
  );
}

export function* watchSaveLeaseDataTabResidentSuccess(): Saga<void> {
  yield takeLatest(
    ActionTypes.SAVE_LEASE_DATA_TAB_RESIDENT_SUCCESS,
    fetchgetOneResident,
  );
}

export function* fetchGetUnitById({ payload }: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);

    const pathname = yield select(
      (state) => state.routing?.location?.pathname ?? '',
    );
    const includeSoftDeleted = pathname.includes('prior-resident');

    const unitService = new UnitService();
    const response = yield unitService.getById(
      property.id,
      organizationId,
      payload,
      includeSoftDeleted,
    );
    yield put(residentActions.getUnitByIdSuccess(response));
  } catch (err) {
    yield put(residentActions.getUnitByIdError(err));
  }
}

export function* getUnitById(): Saga<void> {
  yield takeLatest(ActionTypes.GET_UNIT_BY_ID, fetchGetUnitById);
}

export function* fetchGenerateFutureLeaseDocument({ payload }: Object): any {
  try {
    yield put(
      toastrActions.add({
        type: 'info',
        message: renderTranslatedMessage(messages.pendingDownload),
        title: renderTranslatedMessage(messages.pendingDownloadHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    const residentService = new ResidentService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    const optionalAddendums = yield select(getSelectedOptionalAddendumIds);
    const lease = yield residentService.saveLeaseDataTabResident(
      organizationId,
      property.id,
      payload.leaseId,
      { ...payload.lease, optionalAddendums },
    );
    const leaseService = new LeaseService();
    let file = {};
    if (payload.transfer) {
      file = yield leaseService.generateTransferLeaseDocument(lease);
    } else {
      file = yield leaseService.generateRenewalLeaseDocument(lease);
    }
    yield put(
      residentActions.saveLeaseDataTabResidentSuccess(payload.residentId),
    );

    yield FileSaver.saveAs(file, `Lease_${moment().unix()}.pdf`);
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: handleLeaseDownloadErrorMessage(err),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* generateFutureLeaseDocument(): Saga<void> {
  yield takeLatest(
    ActionTypes.GENERATE_FUTURE_LEASE_DOCUMENT,
    fetchGenerateFutureLeaseDocument,
  );
}

export function* removeLease(): Saga<void> {
  yield takeLatest(ActionTypes.REMOVE_LEASE, fetchRemoveLease);
}

export function* watchRemoveLeaseSuccess(): Saga<void> {
  yield takeLatest(ActionTypes.REMOVE_LEASE_SUCCESS, fetchgetOneResident);
}

function* manualWriteOff(): Saga<void> {
  try {
    const service = new WriteOffService();
    const customerId: ?string = yield select(getCustomerId);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!organizationId || !property)
      throw new Error('Property must be selected to write off.');
    if (!customerId) throw new Error('Invalid resident. Cannot write off.');

    const response = yield call(
      service.writeOff,
      customerId,
      property.id,
      organizationId,
    );

    if (!response.message) {
      yield put(refreshLedger(customerId, true));
      yield put(addWriteOffsForCustomer(customerId, response));
      yield put(getWriteOffsForCustomer(customerId));
    }

    yield put(
      toastrActions.add({
        type: response.message ? 'info' : 'success',
        message: response.message || 'Balance written off.',
        title: response.message ? 'Write Off' : 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.message || err.toString(),
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchApproveApplicant({
  payload,
}: Action<Object>): Saga<void> {
  try {
    const residentId = yield select(getCurrentResidentId);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicationService = new ApplicationService();
    yield applicationService.approveApplicant(
      organizationId,
      selectedProperty.id,
      payload.applicationId,
      payload.id,
    );
    yield put(residentActions.updateApplicantApprovedSucess());
    yield put(getResidentHousehold(residentId));
    yield put(residentActions.getOneResident(residentId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchCancelApplicant({ payload }: Action<Object>): Saga<void> {
  try {
    const residentId = yield select(getCurrentResidentId);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicationService = new ApplicationService();
    yield applicationService.cancelApplicant(
      organizationId,
      selectedProperty.id,
      payload.applicationId,
      payload.id,
      payload.cancelReasonId,
    );
    yield put(residentActions.updateApplicantCancelSucess());
    yield put(getResidentHousehold(residentId));
    yield put(residentActions.getOneResident(residentId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* waitForManualWriteOff(): Saga<void> {
  yield takeLatest(ActionTypes.MANUAL_WRITE_OFF, manualWriteOff);
}

function* approveApplicantSaga(): Saga<void> {
  yield takeLatest(ActionTypes.UPDATE_APPROVE_APPLICANT, fetchApproveApplicant);
}

function* setRecompleteStartedSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SET_RECOMPLETE_STARTED,
    fetchUpdateRecompleteStatus,
  );
}

function* setRecertStatusStartedSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SET_RECERT_FLAG_STARTED,
    fetchUpdateRecertStatus,
  );
}

function* cancelApplicantSaga(): Saga<void> {
  yield takeLatest(ActionTypes.UPDATE_CANCEL_APPLICANT, fetchCancelApplicant);
}

export function* sendResidentPayLeaseEmail({
  payload,
}: Action<string>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const payLeaseService = new PayLeaseService();
    yield payLeaseService.sendIndividualResidentEmail(
      organizationId,
      selectedProperty.id,
      payload,
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'PayLease Registration Email Sent',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* sendPayLeaseEmailSaga(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.SEND_RESIDENT_PAYLEASE_EMAIL,
    sendResidentPayLeaseEmail,
  );
}

export function* sendResidentPortalClaimEmail({
  payload,
}: Action<Object>): Saga<void> {
  try {
    const { customerId, residentId, leaseId } = payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const portalService = new PortalService();
    yield portalService.sendPortalInviteEmail(
      organizationId,
      selectedProperty.id,
      customerId,
      'resident',
    );
    yield put(residentActions.getOneResident(residentId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'Portal Invite Email Sent',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    if (leaseId) {
      yield put(getSignatureStatuses(leaseId));
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* sendResidentPortalClaimEmailSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SEND_RESIDENT_PORTAL_CLAIM_EMAIL,
    sendResidentPortalClaimEmail,
  );
}

export function* fetchUpdateResidentMoveInDate({
  payload,
}: Action<Object>): Saga<void> {
  try {
    const { residentId, modify } = payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const propertyId = selectedProperty.id;

    const successMessage = pathOr('All Leases Updated', ['success'], payload);

    const residentService = new ResidentService();
    yield residentService.updateResidentMoveInDate(
      residentId,
      organizationId,
      propertyId,
      modify,
    );
    payload?.refreshActivityTable?.();
    yield put(
      toastrActions.add({
        type: 'success',
        message: successMessage,
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(residentActions.saveLeaseDataTabResidentSuccess(residentId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchUpdatePriorResidentMoveOutDate({
  payload,
}: Action<Object>): Saga<void> {
  try {
    const { residentId, modify } = payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const propertyId = selectedProperty.id;

    const successMessage = pathOr('All Leases Updated', ['success'], payload);

    const residentService = new ResidentService();
    yield residentService.updatePriorResidentMoveOutDate(
      residentId,
      organizationId,
      propertyId,
      modify,
    );

    yield put(
      toastrActions.add({
        type: 'success',
        message: successMessage,
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(residentActions.saveLeaseDataTabResidentSuccess(residentId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchUpdateResidentPropertyMoveInDate({
  payload,
}: Action<Object>): Saga<void> {
  try {
    const { residentId, modify } = payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const propertyId = selectedProperty.id;

    const successMessage = pathOr('', ['success'], payload);
    const residentService = new ResidentService();
    yield residentService.updateResidentPropertyMoveInDate(
      residentId,
      organizationId,
      propertyId,
      modify,
    );

    yield put(
      toastrActions.add({
        type: 'success',
        message: successMessage,
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(residentActions.saveLeaseDataTabResidentSuccess(residentId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* updateResidentMoveInDate(): Saga<void> {
  yield takeLatest(
    ActionTypes.UPDATE_RESIDENT_MOVE_IN_DATE,
    fetchUpdateResidentMoveInDate,
  );
}

function* updatePriorResidentMoveOutDate(): Saga<void> {
  yield takeLatest(
    ActionTypes.UPDATE_PRIOR_RESIDENT_MOVE_OUT_DATE,
    fetchUpdatePriorResidentMoveOutDate,
  );
}

export function* fetchGetFirstUnitMoveInAfterPriorResident({
  payload,
}: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const residentService = new ResidentService();
    const response = yield residentService.getFirstUnitMoveInAfterPriorResident(
      organizationId,
      property.id,
      payload,
    );
    const date =
      response && response.actualMoveInDate ? response.actualMoveInDate : null;
    yield put(
      residentActions.getFirstUnitMoveInAfterPriorResidentSuccess(date),
    );
  } catch (err) {
    yield put(residentActions.getFirstUnitMoveInAfterPriorResidentError(err));
  }
}

function* getFirstUnitMoveInAfterPriorResident(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT,
    fetchGetFirstUnitMoveInAfterPriorResident,
  );
}

export function* handleSocketFasSuccessEvent({ payload }: Object): Saga<void> {
  const { applicationId, customerId, fileData, fileName, residentId } = payload;
  yield put(
    toastrActions.add({
      type: 'success',
      message: renderTranslatedMessage(messages.fasSuccessNotification),
      title: renderTranslatedMessage(messages.successHeader),
      options: {
        showCloseButton: true,
        closeOnToastrClick: true,
        removeOnHover: true,
        progressBar: true,
        timeOut: 15000, // Default 5000
      },
    }),
  );
  const currentResidentId = yield select(getCurrentResidentId);
  if (residentId === currentResidentId) {
    // Refresh all data on Resident profile
    yield put(residentActions.getOneResident(residentId));
    // Update list of documents
    yield put(
      getAllDocuments(
        applicationId,
        DOCUMENTS_DEFAULT_PAGE,
        DOCUMENTS_DEFAULT_LIMIT,
        DOCUMENTS_DEFAULT_SORTING,
      ),
    );
    // Update Ledger
    yield put(refreshLedger(customerId, true));
    // reset url for fas
    yield put(
      replace(getUrlWithSelectedPropertyId(`/prior-resident/${residentId}`)),
    );
  }
  yield delay(500);
  base64FileDowload(fileData, fileName, 'application/pdf');
}

function* handleSocketFasSuccessEventSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SOCKET_EVENT_FAS_SUCCESS,
    handleSocketFasSuccessEvent,
  );
}
function* handleSocketFasErrorEvent({ payload }: Object): Saga<void> {
  const {
    payload: { residentId },
  } = payload;
  yield put(
    toastrActions.add({
      type: 'error',
      message: renderTranslatedMessage(messages.fasErrorNotification),
      title: renderTranslatedMessage(messages.errorHeader),
      options: {
        showCloseButton: true,
        closeOnToastrClick: true,
        removeOnHover: true,
        progressBar: true,
        timeOut: 15000, // Default 5000
      },
    }),
  );
  const currentResidentId = yield select(getCurrentResidentId);
  if (residentId === currentResidentId) {
    // Refresh all data on Resident profile
    yield put(residentActions.getOneResident(residentId));
  }
}

function* handleSocketFasErrorEvenSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SOCKET_EVENT_FAS_ERROR,
    handleSocketFasErrorEvent,
  );
}

export function* handleSocketFasReversalSuccessEvent({
  payload: message,
}: Object): Saga<void> {
  const { payload } = message;
  const { customerId, residentId } = payload;
  yield put(
    toastrActions.add({
      type: 'success',
      message: message.text,
      title: renderTranslatedMessage(messages.successHeader),
      options: {
        showCloseButton: true,
        closeOnToastrClick: true,
        removeOnHover: true,
        progressBar: true,
        timeOut: 15000, // Default 5000
      },
    }),
  );
  const currentResidentId = yield select(getCurrentResidentId);
  if (residentId === currentResidentId) {
    // Refresh all data on Resident profile
    yield put(residentActions.getOneResident(residentId));
    // Update Ledger
    yield put(refreshLedger(customerId, true));
  }
}

function* handleSocketFasReversalSuccessEventSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SOCKET_EVENT_FAS_REVERSAL_SUCCESS,
    handleSocketFasReversalSuccessEvent,
  );
}
function* handleSocketFasReversalErrorEvent({
  payload: message,
}: Object): Saga<void> {
  const { payload } = message;
  const { residentId } = payload;
  yield put(
    toastrActions.add({
      type: 'error',
      message: message.text,
      title: renderTranslatedMessage(messages.errorHeader),
      options: {
        showCloseButton: true,
        closeOnToastrClick: true,
        removeOnHover: true,
        progressBar: true,
        timeOut: 15000, // Default 5000
      },
    }),
  );
  const currentResidentId = yield select(getCurrentResidentId);
  if (residentId === currentResidentId) {
    // Refresh all data on Resident profile
    yield put(residentActions.getOneResident(residentId));
  }
}

function* handleSocketFasReversalErrorEvenSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SOCKET_EVENT_FAS_REVERSAL_ERROR,
    handleSocketFasReversalErrorEvent,
  );
}

function* updateResidentPropertyMoveInDate(): Saga<void> {
  yield takeLatest(
    ActionTypes.UPDATE_RESIDENT_PROPERTY_MOVE_IN_DATE,
    fetchUpdateResidentPropertyMoveInDate,
  );
}

function* fetchGetCamPools(action: Action<any>): Saga<void> {
  try {
    const { householdId } = action.payload;
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const camRecordService = new CamRecordService();
    const response = yield camRecordService.getCamPools(
      organizationId,
      selectedProperty.id,
      householdId,
      false,
    );
    yield put(residentActions.fetchCamPoolsSuccess(response));
  } catch (err) {
    yield put(residentActions.fetchCamPoolsError(err));
  }
}

function* getCamPools(): Saga<void> {
  yield takeLatest(ActionTypes.FETCH_CAM_POOLS, fetchGetCamPools);
}

export default [
  getOneResidentSaga,
  moveOutResidentSaga,
  saveNoticeToVacate,
  deleteNoticeToVacate,
  doNotRenewResidentSaga,
  updateFasReadinessSaga,
  underEvictionResidentSaga,
  saveMonthlyChargeResident,
  getMonthlyTransactionsResident,
  watchSaveMonthlyChargeResidentSuccess,
  getAllApplicationStatusSaga,
  getScreeningStatus,
  renewLease,
  watchRenewLeaseSuccess,
  transferLease,
  watchTransferLeaseSuccess,
  saveLeaseDataTabResident,
  watchSaveLeaseDataTabResidentSuccess,
  getUnitById,
  generateFutureLeaseDocument,
  waitForManualWriteOff,
  removeLease,
  watchRemoveLeaseSuccess,
  updateResidentSaga,
  approveApplicantSaga,
  cancelApplicantSaga,
  sendPayLeaseEmailSaga,
  updateResidentMoveInDate,
  updatePriorResidentMoveOutDate,
  setRecompleteStartedSaga,
  setRecertStatusStartedSaga,
  sendResidentPortalClaimEmailSaga,
  handleSocketFasSuccessEventSaga,
  handleSocketFasErrorEvenSaga,
  handleSocketFasReversalSuccessEventSaga,
  handleSocketFasReversalErrorEvenSaga,
  updateResidentPropertyMoveInDate,
  autoEmailMonthlyInvoiceSaga,
  getFirstUnitMoveInAfterPriorResident,
  getCamPools,
];
