import { useMemo, useCallback } from 'react';
import { useQuery } from 'react-query';
import keyBy from 'lodash.keyby';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useTableManageColumns,
  useTableFilterSortSearchManager,
  useTableFilterSortData,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { TextingIndicator } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { buildHeaders, parsePriorResidents } from './PriorResidentsTable/utils';
import { PROPERTY_PATH_MAP } from './constantsV2';
import { useFetchConversations } from '../../hooks/data-fetching/useFetchConversations';
import useHasPermission from '../../hooks/useHasPermission';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import PriorResidentsService from '../../services/priorResidentsService';
import {
  appendFilterTextToCSV,
  processDataToCSV,
} from '../../utils/csv-helpers';
import { download } from '../../utils/downloadFile';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';
import useUniqueTableName from '../../hooks/useUniqueTableName';

const NAME = 'manage-prior-residents';

export const useManagePriorResidents = ({
  intl,
  selectedProperty,
  userId,
  locale,
  organizationId,
  propertyId,
  isAllCommercial,
  onPageChange,
  currentPage,
  perPage,
}) => {
  const name = useUniqueTableName(NAME);
  const hasCommunicationCreatePermission = useHasPermission(
    'communication-create',
  );
  const showTextingColumn =
    selectedProperty.isTwoWayCommunicationActive &&
    hasCommunicationCreatePermission;
  const flags = useFlags();
  const showCollectionsColumns = flags?.collectionsOneWay;
  const headers = useMemo(
    () =>
      buildHeaders({
        intl,
        isAllCommercial,
        showTextingColumn,
        showCollectionsColumns,
      }),
    [intl, isAllCommercial, showTextingColumn, showCollectionsColumns],
  );

  const {
    order,
    orderBy,
    handleSortChange,
    filterState,
    filterTypeState,
    dateState,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    initialOrderBy: 'moveOutDate',
    initialOrder: 'DESC',
    headers,
  });
  const { results, paginationMeta, isLoading, isSuccess } =
    useFetchPriorResidents({
      organizationId,
      propertyId,
      isAllCommercial,
      onPageChange,
      currentPage,
      perPage,
      searchState,
      enabled: true,
    });

  const parsedResults = useParsePriorResidentResults(results, isAllCommercial);

  const twoWayRecipientIds = useMemo(
    () => parsedResults.map((r) => r.twoWayRecipientIds).flat(),
    [parsedResults],
  );
  const [conversations] = useFetchConversations(
    twoWayRecipientIds,
    showTextingColumn,
  );
  const conversationsDictionary = useMemo(() => {
    return keyBy(conversations, (c) => c.recipient.customerId);
  }, [conversations]);
  const resultsWithConvo = useMemo(
    () =>
      parsedResults.map((resident) => {
        const householdConversationsStatus = resident.twoWayRecipientIds.reduce(
          (acc, customerId) => {
            const conversation = conversationsDictionary[customerId];

            if (conversation) {
              acc[conversation.conversationStatus] += 1;
            }

            return acc;
          },
          { NEW: 0, UNRESOLVED: 0, RESOLVED: 0 },
        );

        householdConversationsStatus.TOTAL =
          householdConversationsStatus.NEW +
          householdConversationsStatus.UNRESOLVED;

        resident.texting = householdConversationsStatus;

        return resident;
      }),
    [parsedResults, conversationsDictionary],
  );

  const sortedAndFilteredResults = useTableFilterSortData({
    results: resultsWithConvo,
    order,
    orderBy,
    PROPERTY_PATH_MAP,
    searchState,
    filterState,
    filterTypeState,
    dateState,
  });

  const rows = useBuildPriorResidentResults(
    sortedAndFilteredResults,
    isAllCommercial,
    resultsWithConvo,
  );

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const { handleCSVButtonClick } = useCSVExport({
    hasAnyFilters:
      Object.keys(filterState)?.length ||
      Object.keys(dateState)?.length ||
      Object.keys(searchState)?.length,
    filteredHeaders,
    rows,
    enabled: flags?.exportPriorResidentScreen,
  });

  return {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    name,
    searchState,
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    isLoading,
    isSuccess,
    results: rows,
    handleCSVButtonClick,
    ...paginationMeta,
  };
};

export const useFetchPriorResidents = ({
  organizationId,
  propertyId,
  enabled,
  isAllCommercial,
  onPageChange,
  currentPage,
  perPage,
  searchState,
}) => {
  const queryKey = [
    NAME,
    organizationId,
    propertyId,
    enabled,
    onPageChange,
    currentPage,
    perPage,
  ];
  let { data, isLoading, isSuccess } = useQuery(
    queryKey,
    () => {
      return new PriorResidentsService().getAllPriorResidents(
        currentPage,
        3000,
        {},
        '',
        organizationId,
        propertyId,
        true,
      );
    },
    { enabled: Boolean(enabled && organizationId && propertyId) },
  );
  const results = useMemo(() => data?.results ?? [], [data?.results]);
  const paginationMeta = useMemo(() => data?.meta ?? {}, [data?.meta]);
  return {
    results,
    paginationMeta,
    isLoading,
    isSuccess,
  };
};

export const useParsePriorResidentResults = (
  results = [],
  isCommercial = false,
) => {
  return useMemo(() => {
    return results?.map((result) => ({
      ...result,
      balance: +result.balance,
      subsidyBalance: +result.subsidyBalance,
      priorTenants: parsePriorResidents(result, isCommercial),
      priorResidents: parsePriorResidents(result, isCommercial),
    }));
  }, [results, isCommercial]);
};

export const useBuildPriorResidentResults = (
  results = [],
  isCommercial = false,
) => {
  return useMemo(() => {
    return results?.map((result) => ({
      priorTenants: {
        variant: 'link',
        to: getUrlWithSelectedPropertyId(
          `/prior-resident/${result.residentId}`,
        ),
        value: parsePriorResidents(result, isCommercial),
      },
      priorResidents: {
        variant: 'link',
        to: getUrlWithSelectedPropertyId(
          `/prior-resident/${result.residentId}`,
        ),
        value: parsePriorResidents(result, isCommercial),
      },
      texting: {
        variant: result.texting.TOTAL > 0 ? 'link' : undefined,
        value: <TextingIndicator {...result.texting} />,
        to:
          result.texting.TOTAL > 0
            ? getUrlWithSelectedPropertyId(
                `/resident/${result.residentId}?tab=texting`,
              )
            : undefined,
      },
      unit: {
        variant: 'link',
        to: getUrlWithSelectedPropertyId(`/unit/${result.unitId}`),
        value: result.unit,
        disabled: result.isUnitSoftDeleted,
      },
      moveInDate: {
        variant: 'date',
        value: result?.moveInDate,
      },
      moveOutDate: {
        variant: 'date',
        value: result?.moveOutDate,
      },
      FASDate: {
        variant: 'date',
        value: result?.FASDate,
      },
      monthsLateFeesCharges: {
        value: result?.monthsLateFeesCharges,
        variant: 'number',
      },
      underEviction: {
        variant: 'boolean',
        value: result?.underEviction,
        iconName: 'AlertInfoIcon',
        color: 'error',
      },
      doNotRenew: {
        variant: 'boolean',
        value: result?.doNotRenew,
        iconName: 'AlertInfoIcon',
        color: 'error',
      },
      collectionStatus: {
        variant: 'string',
        value: result?.collectionStatus?.split('_').map(_.capitalize).join(' '),
      },
      claimCreated: {
        variant: 'date',
        value: result?.claimCreated,
      },
      residentBalance: {
        variant: 'currency',
        value: result?.balance,
      },
      subsidyBalance: {
        variant: 'currency',
        value: result?.subsidyBalance,
      },
    }));
  }, [results, isCommercial]);
};

const useCSVExport = ({ hasAnyFilters, filteredHeaders, rows, enabled }) => {
  const handleCSVButtonClick = useCallback(() => {
    const csvHeaders = [];
    for (const { label } of filteredHeaders) {
      csvHeaders.push(
        typeof label === 'string' ? label : label.props?.defaultMessage,
      );
    }

    const csvRows = rows.map((row) => {
      const {
        texting: {
          value: { props: textingProps },
        },
      } = row;
      const csvRow = {
        ...row,
        texting: {
          variant: 'string',
          value: `New = ${textingProps?.NEW ?? 0}, Unresolved = ${
            textingProps?.UNRESOLVED ?? 0
          }`,
        },
        underEviction: {
          ...row.underEviction,
          variant: 'string',
          value: row.underEviction.value ? 'Yes' : '',
        },
        doNotRenew: {
          ...row.doNotRenew,
          variant: 'string',
          value: row.doNotRenew.value ? 'Yes' : '',
        },
      };
      return csvRow;
    });

    const processedCSVRows = processDataToCSV({
      rows: csvRows,
      filteredHeaders,
    });

    const csv = appendFilterTextToCSV({
      headers: csvHeaders.join(','),
      rows: processedCSVRows,
      hasAnyFilters,
    });
    download(
      csv,
      `Prior Residents_${formatDateDB(new Date())}.csv`,
      'text/csv;charset=utf-8',
    );
  }, [hasAnyFilters, filteredHeaders, rows]);
  return { handleCSVButtonClick: enabled ? handleCSVButtonClick : undefined };
};
