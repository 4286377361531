import moment from 'moment-business-days';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { isEmpty, isNil } from 'ramda';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Spinner,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { FilterDrawer } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import FilterGroupSection from './FilterGroupSection';
import {
  orange,
  grey,
  red,
} from '@fortress-technology-solutions/fortress-component-library/design';

import { promptToaster } from '../App/actions';
import SearchField from '../Fields/Search';
import ComplianceOverviewDetails from './ComplianceOverviewDetails';
import CertHistoryDetails from './CertHistoryDetails';

import { useFetchComplianceOverview } from './hooks';

import type { OrderValue } from '../App/types';

import {
  FILTERS,
  FILTER_DESCRIPTIONS,
  COMPLIANCE_TYPES,
  FEATURE_FLAG_HEADERS,
} from './constants';
import {
  buildRows,
  getHeaders,
  handleDownload,
  cleanupFilters,
  adaptFiltersForBackendCall,
} from './utils';
import { COMPLIANCE_APPROVAL_STATUSES } from '../../utils/affordable';

import generalMessages from '../App/messages';
import componentMessages from './messages';

import { useLocalization } from './hooks/useLocalization';
import { SubmitButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { DownloadIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

const messages = { ...generalMessages, ...componentMessages };

type ComplianceOverviewProps = {
  intl: Object,
  organizationId: string,
};

const SquareLegend = styled.span`
  background-color: ${(props) => (props.color ? props.color : 'white')};
  width: 12px;
  height: 12px;
  border: 1px solid ${grey.main};
  box-sizing: border-box;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
`;

const ActionsContainer = styled.div`
  & > button {
    margin-top: 0;
    margin-bottom: 0;
    &:not(:first-child) {
      margin-right: 4px;
    }
    min-height: 40px;
  }
`;

const ComplianceOverview = ({
  intl,
  organizationId,
  actions: { promptToaster },
}: ComplianceOverviewProps) => {
  moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5],
  });
  const ldClient = useLDClient();
  const {
    complianceOverviewReviewedAndNumberOfDays,
    complianceCertHistorySummary,
    newFilterStyleCompliance,
  } = useFlags();

  const YES = intl.formatMessage(messages.yes);
  const NO = intl.formatMessage(messages.no);
  const FILTER_OPTIONS: Object = useMemo(
    () => ({
      certificationType: [
        'Initial',
        'Interim',
        'Move-in',
        'Move-out',
        'Recert',
        'Termination',
      ],
      complianceApprovalStatus: [
        COMPLIANCE_APPROVAL_STATUSES.APPROVED_PENDING_SIGN,
        COMPLIANCE_APPROVAL_STATUSES.APPROVED,
        COMPLIANCE_APPROVAL_STATUSES.PENDING,
        COMPLIANCE_APPROVAL_STATUSES.PENDING_FINAL_APPROVAL,
        COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED,
        COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED_FINAL,
        COMPLIANCE_APPROVAL_STATUSES.ON_NOTICE_HOLD,
        COMPLIANCE_APPROVAL_STATUSES.NONE,
      ],
      isActive: [YES, NO],
      isResident: [YES, NO],
      programType: ['HUD', 'LIHTC'],
      waitlistApplicant: [intl.formatMessage(messages.doNotInclude)],
    }),
    [YES, NO, intl],
  );

  // #region State
  const [currentSorting, setCurrentSorting] = useState({
    fieldName: complianceOverviewReviewedAndNumberOfDays
      ? 'voucherEffectiveDate'
      : 'uploadDate',
    order: 'DESC',
  });
  const [searchText, setSearchText] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [complianceType, setComplianceType] = useState(
    complianceOverviewReviewedAndNumberOfDays
      ? COMPLIANCE_TYPES.ALL_OPEN_CERTS
      : COMPLIANCE_TYPES.NEEDS_REVIEW,
  );
  const [title, setTitle] = useState(
    complianceOverviewReviewedAndNumberOfDays
      ? messages.allOpenCerts
      : messages.needsReview,
  );
  const [showCertHistory, setShowCertHistory] = useState(false);
  const [currentCertHistory, setCurrentCertHistory] = useState({});

  const assignAllFilters = useCallback(
    (value: boolean, isDefault?: boolean = false) => {
      if (newFilterStyleCompliance && isDefault) {
        return {};
      }
      return (
        !complianceOverviewReviewedAndNumberOfDays ||
        complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
          ? FILTERS.filter((filter) => filter !== 'waitlistApplicant')
          : FILTERS
      ).reduce((acc, filter) => {
        acc[filter] = FILTER_OPTIONS[filter].reduce((a, option) => {
          a[option] =
            filter === 'waitlistApplicant' && isDefault ? true : value;
          return a;
        }, {});
        return acc;
      }, {});
    },
    [
      FILTER_OPTIONS,
      complianceOverviewReviewedAndNumberOfDays,
      complianceType,
      newFilterStyleCompliance,
    ],
  );

  const buildFilters = useCallback(
    (): Array<Object> =>
      (!complianceOverviewReviewedAndNumberOfDays ||
      complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
        ? FILTERS.filter((filter) => filter !== 'waitlistApplicant')
        : FILTERS
      ).map((filter) => ({
        name: filter,
        description: intl.formatMessage(messages[FILTER_DESCRIPTIONS[filter]]),
        options: (FILTER_OPTIONS[filter] ?? []).map((e) => ({
          text: e,
          value: e,
        })),
        variant: 'list',
      })),
    [
      FILTER_OPTIONS,
      complianceOverviewReviewedAndNumberOfDays,
      complianceType,
      intl,
    ],
  );

  const [drawerFilters, setDrawerFilters] = useState(buildFilters());
  const [currentFilter, setCurrentFilter] = useState(
    assignAllFilters(false, true),
  );
  const [filter, setFilter] = useState(assignAllFilters(false, true));
  // #endregion

  const {
    data: complianceOverviewData,
    isError,
    isLoading,
    refetch,
  } = useFetchComplianceOverview({
    organizationId,
    intl,
    complianceType,
  });

  // #region Events
  const clearFilters = () => {
    setFilter(assignAllFilters(false));
  };
  const handleFilterClick = newFilterStyleCompliance
    ? () => {
        setShowFilter((currentShowFilter) => !currentShowFilter);
      }
    : () => {
        setShowFilter(true);
      };

  const handleSort = ({ fieldName, order }: OrderValue) => {
    setCurrentSorting({ fieldName, order });
  };
  const handleSearchSubmit = ({ searchText }: Object) => {
    setSearchText(searchText);
  };
  const onCloseFilterClick = () => {
    setFilter(currentFilter);
    setShowFilter(false);
  };
  const onApplyFilterClick = () => {
    setCurrentFilter(filter);
    setShowFilter(false);
  };
  const onFilterGroupChange = (filtersObject) => {
    setCurrentFilter(filtersObject);
  };
  const onComplianceTypeClick = (complianceType: string) => {
    setComplianceType(complianceType);
  };
  const onFilterChange = (
    field: string,
    filterValue: string,
    value: boolean | Array<string>,
  ) => {
    const newFilter = isNil(filterValue)
      ? {
          ...filter,
          [field]: value,
        }
      : {
          ...filter,
          [field]: {
            ...filter[field],
            [filterValue]: value,
          },
        };
    setFilter(newFilter);
  };

  const onDowloadClick = (fileType: string) => {
    const cleanupStrategy = newFilterStyleCompliance
      ? () => adaptFiltersForBackendCall(currentFilter)
      : () => cleanupFilters(currentFilter);
    const filters = cleanupStrategy();
    handleDownload({
      complianceType,
      includeNotStartedCerts: false,
      fileType,
      organizationId,
      searchText,
      currentFilter: filters,
      currentSorting,
      promptToaster,
      intl,
    });
  };

  const onViewCertHistoryClick = (certHistory: Object) => {
    setCurrentCertHistory(certHistory);
    setShowCertHistory(true);
  };
  const onCloseCertHistoryDetailsClick = () => {
    setShowCertHistory(false);
  };

  // #endregion

  const headersToFilter = [
    ...(!complianceOverviewReviewedAndNumberOfDays
      ? FEATURE_FLAG_HEADERS.complianceOverviewReviewedAndNumberOfDays
      : []),
    ...(!complianceCertHistorySummary
      ? FEATURE_FLAG_HEADERS.complianceCertHistorySummary
      : []),
  ];
  const headerRows = (getHeaders(complianceType) ?? []).filter(
    (header) => !headersToFilter.includes(header.id),
  );
  const headers = [
    {
      headers: headerRows.map((header) => ({
        ...header,
        title: !isEmpty(header.title)
          ? intl.formatMessage(messages[header.title])
          : '',
      })),
    },
  ];

  const totalMessage = intl.formatMessage(messages.total);

  const locale = useLocalization();

  useEffect(() => {
    if (isLoading) {
      setDrawerFilters(buildFilters());
      setFilter(assignAllFilters(false, true));
      setCurrentFilter(assignAllFilters(false, true));
    }
  }, [assignAllFilters, buildFilters, isLoading]);

  // #region Effects
  useEffect(() => {
    ldClient.identify({
      kind: 'user',
      key: 'fortressUser',
      organizationId,
    });
  });

  useEffect(() => {
    const hasFiltersStrategy = newFilterStyleCompliance
      ? () => setHasFilters(Object.entries(currentFilter).length > 0)
      : () =>
          setHasFilters(
            Object.keys(currentFilter).some((key) =>
              Object.values(currentFilter[key]).some((e) => e),
            ),
          );
    hasFiltersStrategy();
  }, [currentFilter, newFilterStyleCompliance]);

  useEffect(() => {
    setTitle(messages[complianceType]);
    if (!newFilterStyleCompliance) {
      setDrawerFilters(buildFilters());
      setFilter(assignAllFilters(false, true));
      setCurrentFilter(assignAllFilters(false, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceType, newFilterStyleCompliance]);

  useEffect(() => {
    if (complianceOverviewData) {
      // to preserve order of original data retrieved from backend
      const complianceOverDataShallowCopy = [...complianceOverviewData];
      const builtRows = buildRows(
        complianceOverDataShallowCopy,
        complianceType,
        currentSorting,
        hasFilters,
        currentFilter,
        searchText,
        onViewCertHistoryClick,
        {
          complianceOverviewReviewedAndNumberOfDays,
          complianceCertHistorySummary,
          newFilterStyleCompliance,
        },
        locale,
      );
      setRows(builtRows);
      setTotal(builtRows.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSorting,
    complianceType,
    currentFilter,
    searchText,
    complianceOverviewData,
    hasFilters,
  ]);
  // #endregion

  const filterGroupSectionProps = {
    organizationId,
    showBody: showFilter,
    onChange: onFilterGroupChange,
    complianceType,
  };

  return (
    <Grid
      container
      rowSpacing={1.5}
      sx={{ pl: '10px', pr: '10px', maxHeight: 'calc(100% - 86px)' }}
    >
      <CertHistoryDetails
        intl={intl}
        certHistory={currentCertHistory}
        onCloseCertHistoryDetailsClick={onCloseCertHistoryDetailsClick}
        show={showCertHistory}
      />
      {complianceOverviewReviewedAndNumberOfDays && (
        <Grid item xs={12}>
          <ButtonGroup>
            <button
              id="compliance-all"
              className={`btn btn-primary--ghost-white${
                complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS
                  ? ' active'
                  : ''
              }`}
              type="button"
              onClick={() =>
                onComplianceTypeClick(COMPLIANCE_TYPES.ALL_OPEN_CERTS)
              }
              style={{ zIndex: 0 }}
            >
              <FormattedMessage {...messages.allOpenCerts} />
            </button>
            <button
              id="compliance-review"
              className={`btn btn-primary--ghost-white${
                complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
                  ? ' active'
                  : ''
              }`}
              type="button"
              onClick={() =>
                onComplianceTypeClick(COMPLIANCE_TYPES.NEEDS_REVIEW)
              }
              style={{ zIndex: 0 }}
            >
              <FormattedMessage {...messages.needsReview} />
            </button>
          </ButtonGroup>
        </Grid>
      )}
      <Grid item xs={3} md={4}>
        {complianceOverviewReviewedAndNumberOfDays ? (
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12}>
              <Typography variant="h2" mb={'10px'}>
                <FormattedMessage {...title} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS ? (
                <Grid container columnSpacing={2.5}>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      sx={{ fontSize: '12px' }}
                    >
                      <SquareLegend color={orange.lighter} />
                      <FormattedMessage {...messages.attentionRequired} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      sx={{ fontSize: '12px' }}
                    >
                      <SquareLegend color={red.lighter} />
                      <FormattedMessage {...messages.effectivePastDue} />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h1">
            <FormattedMessage {...messages.summary} />
          </Typography>
        )}
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <Spinner className="spinner" />
        </Grid>
      ) : isError ? (
        <Grid item xs={12}>
          <Typography sx={{ fontSize: '12px' }}>
            <FormattedMessage {...messages.loadError} />
            <button onClick={refetch}>
              <FormattedMessage {...messages.retry} />
            </button>
          </Typography>
        </Grid>
      ) : (
        [
          <Grid key="actionsContainer" item xs={9} md={8}>
            <ActionsContainer className="search-actions-small">
              <button
                className={`button-filter ${
                  hasFilters ? 'filters-are-active' : ''
                }`}
                onClick={handleFilterClick}
              >
                <i className="et-filter" />
              </button>
              <SearchField
                className="search-input-active padright10"
                form={'ComplianceOverview'}
                placeholder={`${intl.formatMessage(messages.search)}`}
                onSubmit={handleSearchSubmit}
              />
              {
                <SubmitButton
                  startIcon={<DownloadIcon />}
                  onClick={() => onDowloadClick('csv')}
                  isSubmitting={isLoading}
                >
                  CSV
                </SubmitButton>
              }
              {
                <SubmitButton
                  startIcon={<DownloadIcon />}
                  onClick={() => onDowloadClick('pdf')}
                  isSubmitting={isLoading}
                >
                  PDF
                </SubmitButton>
              }
            </ActionsContainer>
          </Grid>,
          <Grid key="FilterGroupSection" item xs={12}>
            <FilterGroupSection {...filterGroupSectionProps} />
          </Grid>,
          <Grid key="tableAndDrawer" item xs={12}>
            <div
              className="table-scroll table-units-container"
              style={{ maxHeight: '69vh' }}
            >
              <ComplianceOverviewDetails
                rows={rows}
                headers={headers}
                name="ComplianceOverview"
                onSort={handleSort}
                intl={intl}
              />
            </div>

            {!newFilterStyleCompliance && (
              <FilterDrawer
                squareChecked
                title={intl.formatMessage(messages.filterBy)}
                clearBtnText={intl.formatMessage(messages.clearFilters)}
                applyBtnText={intl.formatMessage(messages.apply)}
                filters={drawerFilters}
                onFilterChange={onFilterChange}
                onCloseClick={onCloseFilterClick}
                onApplyClick={onApplyFilterClick}
                show={showFilter}
                onClearFiltersClick={clearFilters}
                currentFilter={filter}
              />
            )}
          </Grid>,
          <Grid key="tableFootnotes" item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography variant="caption">
                  {totalMessage}: {total}
                </Typography>
              </Grid>
              {complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW && (
                <Grid item>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: '14px' }}
                  >
                    <FormattedMessage
                      {...messages.noteCustom}
                      values={{ note: '' }}
                    />
                  </Typography>
                  <Typography
                    variant="helper"
                    component="span"
                    sx={{ fontSize: '14px' }}
                  >
                    <FormattedMessage {...messages.needsReviewHouseholdNote} />
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>,
        ]
      )}
    </Grid>
  );
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(ComplianceOverview));
