import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import {
  Box,
  Stack,
  Button,
  SubmitButton,
  Image,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  PencilIcon,
  FloppyIcon,
  ExpandLessIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  always,
  compose,
  ifElse,
  is,
  isEmpty,
  pathOr,
  propOr,
  reduce,
  trim,
  uniqBy,
} from 'ramda';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ElementWithPermissions from '../../components/ElementWithPermissions';
import SocketService from '../../services/socketService';
import {
  validateEmail,
  validatePhoneNumber,
  validateZipCode,
} from '../../utils/validations';
import { promptToaster, selectProperty } from '../App/actions';
import { useAsyncStates } from '../ManageBuildings/hooks';
import AffordableSetup from './AffordableSetup';
import { BankInformationModal } from './BankInformationModal';
import BasicDetails from './BasicDetails';
import {
  useAsyncMarketingSection,
  useAsyncMasterLeaseTerms,
  useAsyncPropertyDetails,
  useAsyncProrateMethods,
  useAsyncValidateFirstOfMonthPostEnabled,
  useForm,
  usePostFirstOfMonthMLT,
  usePropertyRoles,
  useMoveOutProrateMethods,
} from './hooks';
import IntegrationsSetup from './IntegrationsSetup';
import { ASSET_PROTECT } from './IntegrationsSetup/constants';
import LeaseSetup from './LeaseSetup';
import MarketingDetails from './MarketingDetails';
import messages from './messages';
import MobileAppSetup from './MobileAppSetup';
import SmartPriceSetup from './SmartPriceSetup';
import {
  getEquivalentDeprecatedMoveOutProrateMethodId,
  getEquivalentMoveOutProrateMethodId,
} from './utils';

type Props = {
  history: Object,
  intl: any,
  match: Object,
  organizationId: any,
  userOrganization: any,
  actions: Object,
  userPermissions: any[],
  selectedProperty: Object,
  userId: string,
  currentUserOrg: Object,
  locale: string,
};

const cleanData = (data: Object, property: string) =>
  compose(ifElse(is(String), trim, always('')), propOr('', property))(data);

export const ViewProperty = (props: Props) => {
  const {
    intl,
    history,
    organizationId,
    userOrganization,
    actions,
    match,
    userPermissions,
    selectedProperty,
    userId,
    currentUserOrg,
    locale,
  } = props;

  const propertyId = pathOr(
    propOr('', 'id', selectedProperty),
    ['params', 'propertyId'],
    match,
  );
  const defaultCollectionAgency = currentUserOrg?.defaultCollectionAgencyId;

  const isPropertyHomeTab = match?.params?.propertyId === undefined;

  const orgPropUrl = `${organizationId}/${propertyId}`;

  const hasPropertyUpdatePermission = userPermissions.find(
    (permission) => permission.scope === 'property-update',
  );

  const { states } = useAsyncStates();
  const [prorateMethods] = useAsyncProrateMethods();
  const moveOutProrateMethods = useMoveOutProrateMethods();
  const [masterLeaseTerms] = useAsyncMasterLeaseTerms(organizationId);
  const [leaseTerms, setLeaseTerms] = useState([]);
  const propertyLeaseTermsRef = useRef([]);
  const [showBankInformationModal, setShowBankInformationModal] =
    useState(false);

  const [
    propertyDetails,
    updateProperty,
    updateIntegrations,
    propertyDetailsIsLoading,
  ] = useAsyncPropertyDetails(
    organizationId,
    propertyId,
    intl,
    actions.selectProperty,
  );

  const [marketingInfo, updateMarketingSection, marketingSectionIsLoading] =
    useAsyncMarketingSection(organizationId, propertyId);

  const [marketingData, setMarketingData, onMarketingDataChange] =
    useForm(marketingInfo);
  const [propertyData, setPropertyData, onPropertyDataChange] =
    useForm(propertyDetails);

  const [isMltStatusLoading, enabledStatusMLT, errorMLTValidation] =
    useAsyncValidateFirstOfMonthPostEnabled({ organizationId, propertyId });

  const [postMLT, isPostingMLT] = usePostFirstOfMonthMLT(
    intl,
    propertyId,
    organizationId,
    actions.promptToaster,
  );
  const isAffordableSetupShown = useMemo(
    () =>
      ['Affordable', 'Mixed'].includes(propertyDetails?.propertyClass ?? ''),
    [propertyDetails],
  );
  const isSmartPriceShown = useMemo(
    () =>
      !['Affordable', 'Mixed'].includes(propertyDetails?.propertyClass ?? '') &&
      !propertyDetails?.isCommercialProperty, // this is to hide only Commercial only properties
    [propertyDetails],
  );

  const affordableDetails = useMemo(
    () => ({
      programs: propertyDetails?.pap ?? [],
      hudSubsidyTypes: (propertyDetails?.propertyHUDSubsidy ?? []).map(
        pathOr('', ['masterHUDSubsidy', 'name']),
      ),
      hudSecondarySubsidyTypes: (
        propertyDetails?.propertyHUDSecondarySubsidy ?? []
      ).map(pathOr('', ['masterHUDSecondarySubsidyType', 'name'])),
      organizationId,
      propertyId,
      state: propertyData.state,
    }),
    [propertyDetails, organizationId, propertyId, propertyData],
  );

  const affordableSetupValues = useMemo(
    () => ({
      stateId: propertyData?.setup?.stateId,
      projectIdTic: propertyData?.setup?.projectIdTic,
      HUDContractNumber: propertyData?.setup?.HUDContractNumber,
      HUDProjectNumber: propertyData?.setup?.HUDProjectNumber,
      requiresInitialCertificationForNonHUD:
        propertyData?.setup?.requiresInitialCertificationForNonHUD ?? false,
      XMLPropertyName: propertyData?.setup?.XMLPropertyName,
      XMLProjectName: propertyData?.setup?.XMLProjectName,
      isOverrideQualificationEnabled:
        propertyData?.setup?.isOverrideQualificationEnabled,
      RDProjectNumber: propertyData?.setup?.RDProjectNumber,
      RDProgramType: propertyData?.setup?.rdProgramType,
      RDSiteId: propertyData?.setup?.RDSiteId,
      RDProjectName: propertyData?.setup?.RDProjectName,
      RDBorrowerId: propertyData?.setup?.RDBorrowerId,
      RDProjectType: propertyData?.setup?.rdProjectType,
      isRDRAEnabled: propertyData?.setup?.isRDRAEnabled,
      HUDPrimarySubsidyTypeId: propertyData?.setup?.HUDPrimarySubsidyTypeId,
      hudPrimarySubsidyType: propertyData?.setup?.hudPrimarySubsidyType,
      HUDSecondarySubsidyTypeId: propertyData?.setup?.HUDSecondarySubsidyTypeId,
      hudSecondarySubsidyType: propertyData?.setup?.hudSecondarySubsidyType,
      HUDCompleteGRCertsWithoutSigned59As:
        propertyData?.setup?.HUDCompleteGRCertsWithoutSigned59As,
      HOMEUnitType: propertyData?.setup?.HOMEUnitType,
      HOMEAnnualIncomeType: propertyData?.setup?.HOMEAnnualIncomeType,
      HOMEPropertyType: propertyData?.setup?.HOMEPropertyType,
      autoSendToTRACS: propertyData?.config?.autoSendToTRACS,
      autoRolloverIncomeAndAssets: {
        HUD: propertyData?.setup?.HUDAutoRolloverIncomeAndAssets,
        LIHTC: propertyData?.setup?.LIHTCAutoRolloverIncomeAndAssets,
        HOME: propertyData?.setup?.HOMEAutoRolloverIncomeAndAssets,
        RD: propertyData?.setup?.RDAutoRolloverIncomeAndAssets,
      },
    }),
    [propertyData],
  );

  const {
    riskMgmtAssetProtectAdmin,
    mobileCommunicationsToResidents,
    passbookRate,
    propertyRoleAssignments,
    prorateRentsForMoveOuts,
  } = useFlags();

  const hasWorkOrderMobileFeature = useMemo(() => {
    if (propertyDetailsIsLoading) return false;
    return (
      propertyDetails.hubPremiumFeatures.find(
        (f) => f.name === 'workOrders',
      ) !== undefined
    );
  }, [propertyDetails, propertyDetailsIsLoading]);
  const showMobileAppSetupPanel =
    hasWorkOrderMobileFeature && mobileCommunicationsToResidents;

  const ldClient = useLDClient();

  const {
    propertyRoles,
    roleAssignments,
    setRoleAssignments,
    handleAssignmentChange,
    assignPropertyRoles,
  } = usePropertyRoles({ organizationId, propertyId });

  useEffect(() => {
    setMarketingData(marketingInfo);
  }, [marketingInfo, setMarketingData]);
  useEffect(() => {
    setPropertyData(propertyDetails);
  }, [propertyDetails, setPropertyData]);
  useEffect(() => {
    propertyLeaseTermsRef.current = uniqBy(
      (lt) => lt.label,
      propertyDetails.propertyLeaseTerms.map((propertyLeaseTerm) => ({
        value: propertyLeaseTerm.id,
        label: propertyLeaseTerm.masterLeaseTerm.nMonths,
      })),
    );
    setLeaseTerms(propertyLeaseTermsRef.current);
  }, [propertyDetails]);

  useEffect(() => {
    ldClient.identify({
      kind: 'user',
      key: userId,
      propertyId,
      organizationId,
    });

    const onMLTPostingSocketResponse = (message) => {
      actions.promptToaster({
        type: message.status || 'error',
        title: 'MLT posting result',
        message: message.text,
      });
    };

    if (SocketService && SocketService.socket) {
      if (SocketService.socket) {
        SocketService.socket.off('mmlt.success');
        SocketService.socket.off('mmlt.error');
      }
      SocketService.socket.on('mmlt.success', onMLTPostingSocketResponse);
      SocketService.socket.on('mmlt.error', onMLTPostingSocketResponse);
    }

    return () => {
      if (SocketService.socket) {
        SocketService.socket.off('mmlt.success');
        SocketService.socket.off('mmlt.error');
      }
    };
  }, [propertyId]); // eslint-disable-line

  const [editMode, setEditMode] = useState(false);

  const [submit, setSubmit] = useState(false);

  const arePropertiesEmpty = (data: Object, ignoreKeys: string[] = []) =>
    reduce(
      (acc, [key, value]: any) =>
        acc || (ignoreKeys.includes(key) ? false : isEmpty(value)),
      false,
      Object.entries(data),
    );

  const numberInRange = (num: number, min: number, max: number) =>
    num >= min && num <= max;

  const onEdit = async () => {
    if (editMode) {
      const { setup, config } = propertyData;
      const munibillingSettingSync =
        propertyData.isMunibillingEnabled?.value !== undefined
          ? propertyData.isMunibillingEnabled.value
          : propertyData.propertyIntegrations.find(
              (i) => i.integration?.name === 'Munibilling',
            )?.isActive;

      let deprecated_moveOutProrateMethodId =
        propertyData.deprecated_moveOutProrateMethodId;
      let moveOutProrateMethodId = propertyData.moveOutProrateMethodId;

      if (prorateRentsForMoveOuts) {
        deprecated_moveOutProrateMethodId =
          getEquivalentDeprecatedMoveOutProrateMethodId(
            propertyData.moveOutProrateMethodId,
            prorateMethods,
            moveOutProrateMethods,
          );
      } else {
        moveOutProrateMethodId = getEquivalentMoveOutProrateMethodId(
          propertyData.deprecated_moveOutProrateMethodId,
          prorateMethods,
          moveOutProrateMethods,
        );
      }

      const data = {
        marketing: {
          pitch1: cleanData(marketingData, 'pitch1'),
          officeHoursDescription: cleanData(
            marketingData,
            'officeHoursDescription',
          ),
          propertyDescription: cleanData(marketingData, 'propertyDescription'),
        },
        property: {
          name: cleanData(propertyData, 'name'),
          propertyEmail: cleanData(propertyData, 'email'),
          phoneNumber: propertyData.phoneNumber || '',
          faxNumber: propertyData.fax || '',
          physicalStreetAddress1: cleanData(propertyData, 'address1'),
          physicalStreetAddress2: cleanData(propertyData, 'address2'),
          physicalState: propertyData.state,
          physicalCity: cleanData(propertyData, 'city'),
          physicalZip: propertyData.zip,
          leaseRentPercentage: cleanData(propertyData, 'leaseRentPercentage'),
          sitePetWeightMax: cleanData(propertyData, 'sitePetWeightMax'),
          turnMoveOutDays: propertyData.turnMoveOutDays,
          leaseTerms,
          cashPrepaidGLCode: cleanData(propertyData, 'cashPrepaidGLCode'),
          applicationIncomeMultiplier: cleanData(
            propertyData,
            'applicationIncomeMultiplier',
          ),
          isDomusoActive: propertyData.isDomusoActive,
          // Keeping both permissions in sync, can be cleaned up after deprecating old method
          isMunibillingEnabled: munibillingSettingSync,
          isTransUnionActive: propertyData.isTransUnionActive,
          domusoPropertyCode: cleanData(propertyData, 'domusoPropertyCode'),
          transunionPropertyId: cleanData(propertyData, 'transunionPropertyId'),
          renewalProrateMethodId: propertyData.renewalProrateMethodId,
          moveOutProrateMethodId,
          deprecated_moveOutProrateMethodId,
          quoteExpTime: propertyData.quoteExpTime,
          isNoEndDateActive: propertyData.isNoEndDateActive,
          leaseEndDateGreaterThan12m: propertyData.leaseEndDateGreaterThan12m,
          leaseEndDateLesserThan12m: propertyData.leaseEndDateLesserThan12m,
          config,
          setup: setup
            ? {
                id: setup.id,
                stateId: setup.stateId,
                projectIdTic: setup.projectIdTic,
                HUDContractNumber: setup.HUDContractNumber,
                HUDProjectNumber: setup.HUDProjectNumber,
                requiresInitialCertificationForNonHUD:
                  setup.requiresInitialCertificationForNonHUD,
                XMLPropertyName: setup.XMLPropertyName,
                XMLProjectName: setup.XMLProjectName,
                isOverrideQualificationEnabled:
                  setup.isOverrideQualificationEnabled,
                RDProjectNumber: setup.RDProjectNumber,
                RDSiteId: setup.RDSiteId,
                RDProjectName: setup.RDProjectName,
                RDBorrowerId: setup.RDBorrowerId,
                isRDRAEnabled: setup.isRDRAEnabled,
                HOMEAffordabilityPeriodStartDate:
                  setup.HOMEAffordabilityPeriodStartDate,
                HOMEUnitType: setup.HOMEUnitType,
                HOMEAnnualIncomeType: setup.HOMEAnnualIncomeType,
                HOMEPropertyType:
                  setup.HOMEPropertyType === 'None'
                    ? null
                    : setup.HOMEPropertyType,
                HUDAutoRolloverIncomeAndAssets:
                  setup.HUDAutoRolloverIncomeAndAssets,
                LIHTCAutoRolloverIncomeAndAssets:
                  setup.LIHTCAutoRolloverIncomeAndAssets,
                RDAutoRolloverIncomeAndAssets:
                  setup.RDAutoRolloverIncomeAndAssets,
                HOMEAutoRolloverIncomeAndAssets:
                  setup.HOMEAutoRolloverIncomeAndAssets,
                HUDCompleteGRCertsWithoutSigned59As:
                  setup.HUDCompleteGRCertsWithoutSigned59As,
              }
            : undefined,
          isSetLeaseEndDateToEndOfMonth:
            propertyData.isSetLeaseEndDateToEndOfMonth,
          insuranceType: propertyData.insuranceType,
          insuranceFeeAmount: propertyData.insuranceFeeAmount,

          propertyCollectionSetting: propertyData.propertyCollectionSetting,
          mobileAppSetup: propertyData.mobileAppSetup,
          // I'm adding this flag here so Joi can do the check automatically
          // instead of adding checks on the handler
          isCommercialProperty: propertyData.hasCommercialFloorPlans === 'ALL',
        },
        integrations: {
          theWorkNumberAffordable: propertyData.theWorkNumberAffordable,
          isConserviceEnabled: propertyData.isConserviceEnabled,
          // New way of handling munibillig access:
          isMunibillingEnabled: propertyData.isMunibillingEnabled,
        },
      };

      if (
        !arePropertiesEmpty(data.marketing, [
          'officeHoursDescription',
          'propertyDescription',
        ]) &&
        !arePropertiesEmpty(data.property, [
          ...(propertyData.isDomusoActive ? [] : ['domusoPropertyCode']),
          ...(propertyData.isTransUnionActive ? [] : ['transunionPropertyId']),
          ...(propertyData.fax ? [] : ['faxNumber']),
          ...(propertyData.hasCommercialFloorPlans !== 'ALL'
            ? []
            : ['applicationIncomeMultiplier']),
          'physicalStreetAddress2',
          'leaseRentPercentage',
          'sitePetWeightMax',
          'leaseTerms',
          'cashPrepaidGLCode',
          'phoneNumber',
          'config',
          'isCommercialProperty',
        ])
      ) {
        try {
          setSubmit(true);
          const throwErrorFromHookFunction = true;
          if (propertyRoleAssignments) {
            assignPropertyRoles();
          }
          await updateMarketingSection(
            data.marketing,
            intl,
            throwErrorFromHookFunction,
          );
          if (hasPropertyUpdatePermission) {
            // update integrations

            await updateIntegrations(
              data.integrations,
              intl,
              throwErrorFromHookFunction,
            );

            await updateProperty(
              data.property,
              intl,
              throwErrorFromHookFunction,
            );
          }
          actions.promptToaster({
            title: intl.formatMessage(messages.success),
            message: intl.formatMessage(messages.successBody),
          });
        } catch (e) {
          actions.promptToaster({
            type: 'error',
            title: intl.formatMessage(messages.error),
            message: intl.formatMessage(messages.errorBody),
          });
        }
        setEditMode(false);
        setSubmit(false);
      }
    } else {
      setEditMode(true);
    }
  };

  const onCancel = () => {
    setMarketingData(marketingInfo);
    setPropertyData(propertyDetails);
    setLeaseTerms(propertyLeaseTermsRef.current);
    setEditMode(false);
  };

  const hasLIHTCProgram = useMemo(() => {
    const affordablePrograms = affordableDetails?.programs ?? [];
    const programNames = affordablePrograms.map(
      (pap) => pap?.masterAffordableProgram?.name,
    );
    return programNames.includes('LIHTC');
  }, [affordableDetails?.programs]);

  const isFormInvalid = useMemo(() => {
    if (!cleanData(propertyData, 'name')) return true;

    if (!cleanData(propertyData, 'email') || !validateEmail(propertyData.email))
      return true;

    if (!validatePhoneNumber(propertyData.phoneNumber)) return true;

    if (!validatePhoneNumber(propertyData.fax)) return true;

    if (!cleanData(propertyData, 'address1')) return true;

    if (!cleanData(propertyData, 'city')) return true;
    if (!validateZipCode(propertyData.zip)) return true;

    if (
      propertyData.setup &&
      (hasLIHTCProgram || !passbookRate) &&
      (!cleanData(propertyData.setup, 'stateId') ||
        !cleanData(propertyData.setup, 'projectIdTic'))
    )
      return true;

    if (
      propertyData.isDomusoActive &&
      (!numberInRange(propertyData.domusoPropertyCode.length, 4, 5) ||
        !cleanData(propertyData, 'domusoPropertyCode'))
    )
      return true;

    if (
      propertyData.isTransUnionActive &&
      !cleanData(propertyData, 'transunionPropertyId')
    )
      return true;

    if (
      propertyData.hasCommercialFloorPlans !== 'ALL' &&
      !cleanData(propertyData, 'applicationIncomeMultiplier')
    )
      return true;

    if (propertyData.turnMoveOutDays < 1 || propertyData.turnMoveOutDays === '')
      return true;
    if (!cleanData(marketingData, 'pitch1')) return true;

    if (propertyData?.setup?.XMLPropertyName) {
      const isWhitespace =
        (propertyData?.setup?.XMLPropertyName || '').trim().length === 0;
      return isWhitespace;
    }
    if (propertyData?.setup?.XMLProjectName) {
      const isWhitespace =
        (propertyData?.setup?.XMLProjectName || '').trim().length === 0;
      return isWhitespace;
    }
    if (
      propertyData.propertyCollectionSetting &&
      propertyData.propertyCollectionSetting.enabled &&
      //minimumAmount or daysAfterFAS not between 0-999
      (propertyData.propertyCollectionSetting?.minimumAmount < 100 ||
        propertyData.propertyCollectionSetting.minimumAmount > 999 ||
        propertyData.propertyCollectionSetting.daysAfterFAS <= 0 ||
        propertyData.propertyCollectionSetting.daysAfterFAS > 999)
    ) {
      return true;
    }
    if (
      propertyData.config?.smartPricingEnabled &&
      (propertyData.config?.smartPricingTargetOccupancy <= 0 ||
        propertyData.config?.smartPricingTargetOccupancy > 100)
    ) {
      return true;
    }

    if (
      riskMgmtAssetProtectAdmin &&
      propertyData.insuranceType === ASSET_PROTECT &&
      propertyData.insuranceFeeAmount < 10
    )
      return true;

    return false;
  }, [
    propertyData,
    marketingData,
    riskMgmtAssetProtectAdmin,
    passbookRate,
    hasLIHTCProgram,
  ]);
  return (
    <>
      <BankInformationModal
        show={showBankInformationModal}
        onHide={() => setShowBankInformationModal(false)}
        intl={intl}
        promptToaster={actions.promptToaster}
        organizationId={organizationId}
        propertyId={propertyId}
        propertyBankAccounts={propertyDetails.propertyBankAccounts}
      />
      <DocumentTitle title={intl.formatMessage(messages.title)}>
        <Box sx={{ maxWidth: 1400, margin: 'auto' }}>
          {!isPropertyHomeTab && (
            <Button
              variant={'text'}
              onClick={() => history.goBack()}
              startIcon={
                <ExpandLessIcon sx={{ transform: 'rotate(-90deg)' }} />
              }
            >
              <FormattedMessage {...messages.goBack} />
            </Button>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'column', sm: 'row-reverse' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
                spacing={4}
                sx={{ marginBottom: 2, maxWidth: { md: 'calc(50% - 8px)' } }}
              >
                <Image
                  alt={marketingData.name}
                  src={marketingData.propertyLogoLink}
                  sx={{
                    width: {
                      xs: 'calc(100vw - 32px)',
                      sm: 'unset',
                    },
                    maxWidth: {
                      sm: 250,
                    },
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: 'background.paper',
                  }}
                  initialSX={{
                    width: {
                      sm: 250,
                    },
                    height: {
                      xs: 100,
                      sm: 'unset',
                    },
                  }}
                />
                <Typography
                  variant={'h1'}
                  loading={!marketingData.name}
                  minWidth={{ sm: 200 }}
                >
                  {marketingData.name}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <ElementWithPermissions
                scope={['property-update', 'property-update-marketing-gallery']}
              >
                <MarketingDetails
                  marketingInfo={marketingData}
                  editMode={editMode}
                  onChange={onMarketingDataChange}
                />
              </ElementWithPermissions>
            </Grid>
            <Grid item xs={12} md={6}>
              <ElementWithPermissions scope={['property-update']}>
                <BasicDetails
                  propertyDetails={propertyData}
                  orgPropUrl={orgPropUrl}
                  editMode={editMode}
                  onChange={onPropertyDataChange}
                  states={states}
                  intl={intl}
                  promptToaster={actions.promptToaster}
                  organizationId={organizationId}
                  propertyRoles={propertyRoles}
                  roleAssignments={roleAssignments}
                  setRoleAssignments={setRoleAssignments}
                  handleAssignmentChange={handleAssignmentChange}
                />
              </ElementWithPermissions>
            </Grid>
            {isAffordableSetupShown && (
              <Grid item xs={12} md={6}>
                <ElementWithPermissions scope={['property-update']}>
                  <AffordableSetup
                    promptToaster={actions.promptToaster}
                    editMode={editMode}
                    onChange={onPropertyDataChange}
                    {...affordableSetupValues}
                    {...affordableDetails}
                  />
                </ElementWithPermissions>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <ElementWithPermissions scope={['property-update']}>
                <LeaseSetup
                  propertyDetails={propertyData}
                  editMode={editMode}
                  onChange={onPropertyDataChange}
                  leaseTerms={leaseTerms}
                  setLeaseTerms={setLeaseTerms}
                  propertyLeaseTermsRef={propertyLeaseTermsRef}
                  masterLeaseTerms={masterLeaseTerms}
                  prorateMethods={prorateMethods}
                  moveOutProrateMethods={moveOutProrateMethods}
                  firstOfMonthPostEnabled={
                    isMltStatusLoading ? false : enabledStatusMLT
                  }
                  mltPostingError={errorMLTValidation}
                  mltPosting={isPostingMLT}
                  postMLT={postMLT}
                  isLeaseExpirationLimitsActive={
                    propertyData.isLeaseExpirationLimitsActive
                  }
                  isAutoChargeMtmFees={propertyData?.config?.autoChargeMtmFees}
                  organizationId={organizationId}
                  toasterFn={actions.promptToaster}
                  history={history}
                  intl={intl}
                  propertyDetailsIsLoading={propertyDetailsIsLoading}
                  locale={locale}
                />
              </ElementWithPermissions>
            </Grid>
            <Grid item xs={12} md={6}>
              <ElementWithPermissions scope={['property-update']}>
                <IntegrationsSetup
                  propertyDetails={propertyData}
                  organization={{
                    ...userOrganization,
                    defaultCollectionAgencyId: defaultCollectionAgency,
                  }}
                  editMode={editMode}
                  onChange={onPropertyDataChange}
                  intl={intl}
                  organizationId={organizationId}
                  propertyId={propertyId}
                  isLoading={propertyDetailsIsLoading}
                />
              </ElementWithPermissions>
            </Grid>
            {isSmartPriceShown && (
              <Grid item xs={12} md={6}>
                <ElementWithPermissions scope={['property-update']}>
                  <SmartPriceSetup
                    editMode={editMode}
                    config={propertyData.config}
                    onChange={onPropertyDataChange}
                    intl={intl}
                  />
                </ElementWithPermissions>
              </Grid>
            )}
            {showMobileAppSetupPanel && (
              <Grid item xs={12} md={6}>
                <ElementWithPermissions scope={['property-update']}>
                  <MobileAppSetup
                    editMode={editMode}
                    config={propertyData.config}
                    onChange={onPropertyDataChange}
                  />
                </ElementWithPermissions>
              </Grid>
            )}
          </Grid>
          <Stack
            spacing={2}
            direction={'row'}
            justifyContent={'flex-end'}
            flexWrap={'wrap'}
            sx={{
              marginY: 2,
            }}
          >
            <ElementWithPermissions scope={['bank-accounts-view-edit']} hidden>
              <Button
                variant={'shout'}
                onClick={() => setShowBankInformationModal(true)}
              >
                {intl.formatMessage(messages.viewEditBankInformation)}
              </Button>
            </ElementWithPermissions>

            {editMode ? (
              <Stack
                spacing={2}
                direction={'row'}
                sx={{ mt: { xs: '16px !important', sm: 'unset !important' } }}
              >
                <ElementWithPermissions
                  scope={[
                    'property-update',
                    'property-update-marketing-gallery',
                  ]}
                >
                  <SubmitButton
                    data-testid={'cancel-btn'}
                    onClick={onCancel}
                    disabled={isFormInvalid || submit}
                    isSubmitting={submit}
                  >
                    Cancel
                  </SubmitButton>
                  <SubmitButton
                    data-testid={'save-btn'}
                    variant={'primary'}
                    onClick={onEdit}
                    disabled={isFormInvalid || submit}
                    isSubmitting={submit}
                    startIcon={<FloppyIcon />}
                  >
                    Save Changes
                  </SubmitButton>
                </ElementWithPermissions>
              </Stack>
            ) : (
              <ElementWithPermissions
                scope={['property-update', 'property-update-marketing-gallery']}
              >
                <Button
                  data-testid={'edit-btn'}
                  type="button"
                  variant={'primary'}
                  onClick={onEdit}
                  disabled={
                    marketingSectionIsLoading && propertyDetailsIsLoading
                  }
                  startIcon={<PencilIcon />}
                >
                  Edit
                </Button>
              </ElementWithPermissions>
            )}
          </Stack>
        </Box>
      </DocumentTitle>
    </>
  );
};

const InjectedViewProperty = injectIntl(ViewProperty);

export const mapStateToProps = ({
  app: { currentUser, selectedProperty },
  languageProvider,
}: any): any => {
  return {
    userId: currentUser?.user?.id ?? '',
    organizationId: currentUser.user.organizationId,
    userPermissions: currentUser.permissions,
    userOrganization: currentUser.user.organization,
    selectedProperty,
    currentUserOrg: currentUser.user.organization,
    locale: languageProvider.locale ?? 'en_US',
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  actions: bindActionCreators(
    {
      promptToaster,
      selectProperty,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedViewProperty);
