import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageBuildings.Title',
    defaultMessage: 'Fortress - Manage Buildings',
  },
  header: {
    id: 'App.ManageBuildings.Header',
    defaultMessage: 'Manage Buildings',
  },
  buildingNumber: {
    id: 'App.ManageBuildings.BuildingsTable.BuildingNumber',
    defaultMessage: 'Building Number',
  },
  buildingAddress: {
    id: 'App.ManageBuildings.BuildingsTable.BuildingAddress',
    defaultMessage: 'Building Address',
  },
  buildingBIN: {
    id: 'App.ManageBuildings.BuildingsTable.BuildingBIN',
    defaultMessage: 'Building BIN',
  },
  edit: {
    id: 'App.ManageBuildings.BuildingsTable.Edit',
    defaultMessage: 'Edit',
  },
  success: {
    id: 'App.ManageBuildings.EditBuilding.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.ManageBuildings.EditBuilding.Error',
    defaultMessage: 'Error',
  },
  successBody: {
    id: 'App.ManageBuildings.EditBuilding.successBody',
    defaultMessage: 'Building updated',
  },
  successSettingMessage: {
    id: 'App.ManageBuildings.EditBuilding.SuccessSettingMessage',
    defaultMessage: 'Setting updated',
  },
  errorSettingMessage: {
    id: 'App.ManageBuildings.EditBuilding.ErrorSettingMessage',
    defaultMessage: 'Error updating setting',
  },
  errorBody: {
    id: 'App.ManageBuildings.EditBuilding.errorBody',
    defaultMessage: 'Error updating Building',
  },
  errorMissingRequired: {
    id: 'App.ManageBuildings.EditBuilding.errorMissingRequired',
    defaultMessage: 'Missing required information',
  },
  errorInvalidZipCode: {
    id: 'App.ManageBuildings.EditBuilding.errorInvalidZipCode',
    defaultMessage: 'Invalid Zip Code / Postal Code',
  },
  buildingPISD: {
    id: 'App.ManageBuildings.EditBuilding.buildingPISD',
    defaultMessage: 'Building PISD',
  },
  xmlAltSiteName: {
    id: 'App.ManageBuildings.BuildingsTable.xmlAltSiteName',
    defaultMessage: 'Alternative Site Name (XML)',
  },
  heraSpecial: {
    id: 'App.ManageBuildings.BuildingsTable.HeraSpecial',
    defaultMessage: 'HERA Special',
  },
  singleBuildingElection: {
    id: 'App.ManageBuildings.BuildingsTable.SingleBuildingElection',
    defaultMessage: 'Single Building Election',
  },
  rdSiteId: {
    id: 'App.ManageBuildings.BuildingsTable.RDSiteId',
    defaultMessage: 'RD Site ID',
  },
});

export default messages;
