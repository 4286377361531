import React from 'react';
import { FormattedMessage } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Custom Components
import { CheckIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Switch } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  TableHeader,
  VirtualizedTable,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import ComplianceOverviewTabs from './ComplianceOverviewTabs';
import ComplianceOverviewNotes from './ComplianceOverviewNotes';
import ComplianceOverviewLegend from './ComplianceOverviewLegend';
import ModalCertificationHistory from './modal.CertificationHistory';
import ComplianceOverviewLayout from './ComplianceOverviewLayout';

// Hooks
import { useComplianceOverview } from './hooksV2';

// Constants
import messages from './messages';
import { COMPLIANCE_TYPES } from './constants';

const ComplianceOverview = ({ intl }) => {
  const {
    allColumnsHidden,
    columnOptions,
    complianceType,
    count,
    countName,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleDownloadCSVPDF,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    handleTruncateChange,
    isLoading,
    isCSVPDFExportLoading,
    ModalProps,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    setComplianceType,
    totalCount,
    truncatedColumns,
    isCertStatusVisible,
    toggleCertStatusColumn,
    shouldNotStartedCertsButtonBeDisplayed,
  } = useComplianceOverview({ intl });

  return (
    <DocumentTitle
      data-testid={name}
      title={intl.formatMessage(messages.title)}
    >
      <>
        <ComplianceOverviewLayout name={name}>
          {(tableHeight) => (
            <>
              <TableHeader
                title={intl.formatMessage(messages.title)}
                count={count}
                countName={countName}
                totalCount={totalCount}
                sx={{ padding: '0 !important' }}
                Tabs={
                  <ComplianceOverviewTabs
                    complianceType={complianceType}
                    setComplianceType={setComplianceType}
                  />
                }
              />
              <ComplianceOverviewLegend complianceType={complianceType} />
              <ComplianceOverviewNotes complianceType={complianceType} />
              {shouldNotStartedCertsButtonBeDisplayed &&
                complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS && (
                  <Switch
                    sx={{ paddingX: 1, paddingY: 2 }}
                    checked={isCertStatusVisible}
                    onChange={() => toggleCertStatusColumn()}
                    label={
                      <FormattedMessage
                        {...messages.includeNotStartedCertifications}
                      />
                    }
                    icon={<CheckIcon />}
                    checkedIcon={<CheckIcon />}
                    disabled={isLoading}
                  />
                )}
              <VirtualizedTable
                ReactBeautifulDnD={{
                  DragDropContext,
                  Droppable,
                  Draggable,
                }}
                ReactRouterLink={ReactRouterLink}
                allColumnsHidden={allColumnsHidden}
                columnOptions={columnOptions}
                dateState={dateState}
                filterState={filterState}
                filterTypeState={filterTypeState}
                handleColumnChange={handleColumnChange}
                handleDateSubmit={handleDateSubmit}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSearchSubmit={handleSearchSubmit}
                handleSortChange={handleSortChange}
                handleTruncateChange={handleTruncateChange}
                headers={filteredHeaders}
                height={tableHeight}
                isLoading={isLoading}
                onCSVButtonClick={() => handleDownloadCSVPDF('csv')}
                onPDFButtonClick={() => handleDownloadCSVPDF('pdf')}
                isCSVExportLoading={isCSVPDFExportLoading}
                isPDFExportLoading={isCSVPDFExportLoading}
                name={name}
                order={order}
                orderBy={orderBy}
                rows={rows}
                count={count}
                searchState={searchState}
                selectedColumns={selectedColumns}
                truncatedColumns={truncatedColumns}
                sx={{
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingLeft: 0,
                  paddingBottom: 0,
                }}
              />
            </>
          )}
        </ComplianceOverviewLayout>
        <Modal {...ModalProps}>
          {ModalProps?.modalType === 'certificationHistory' ? (
            <ModalCertificationHistory
              {...ModalProps?.modalState}
              intl={intl}
            />
          ) : null}
        </Modal>
      </>
    </DocumentTitle>
  );
};

export default injectIntl(ComplianceOverview);
