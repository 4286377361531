import { useContext } from 'react';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import {
  useTableFilterSortData,
  useTableFilterSortSearchManager,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { AppContext } from '../App/context';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useHasPermission from '../../hooks/useHasPermission';
import useManageUnitAvailabilityQuery from '../../hooks/data-fetching/useManageUnitAvailabilityQuery';
import useFetchHUDTransferProps from '../../hooks/data-fetching/useFetchHUDTransferProps';
import useUnitQuoteMutation from '../../hooks/data-modifying/useUnitQuoteMutation';
import useApplicantOrResidentToAssignUnit from './useApplicantOrResidentToAssignUnit';
import useHeaders from './hooks.useHeaders';
import useRows from './hooks.useRows';
import useParseResults from './hooks.useParseResults';
import useCSVExport from './hooks.useCSVExport';
import useFooterRow from './hooks.useFooterRow';
import useUnitSpecials from './hooks.useUnitSpecials';
import useAssignUnit from './hooks.useAssignUnit';
import { PROPERTY_PATH_MAP } from './constants';
import useIntl from '../../hooks/useIntl';
import useIsAffordableProperty from '../../hooks/useIsAffordableProperty';
import useSearchParams from './hooks.useSearchParams';

const useManageUnitAvailability = ({ NAME }) => {
  const intl = useIntl();
  const name = useUniqueTableName(NAME);
  const { isTransfer } = useSearchParams();
  const { userId } = useContext(AppContext);
  const {
    organizationId,
    name: propertyName,
    id: propertyId,
    hasCommercialFloorPlans,
    propertyClass,
  } = useSelectedProperty();

  const isAffordableOrMixedProperty = useIsAffordableProperty({
    propertyClass,
  });
  const unitQuoteMutation = useUnitQuoteMutation({
    propertyName,
    propertyId,
    organizationId,
    userId,
  });

  const hasManageUnitsUpdatePermission = useHasPermission(
    'manage-units-update',
  );

  const applicantToAssignUnit = useApplicantOrResidentToAssignUnit({
    organizationId,
    propertyId,
  });

  const householdId = applicantToAssignUnit?.householdId ?? '';

  const assignUnitProps = useAssignUnit({ applicantToAssignUnit });

  const UnitSpecialsProps = useUnitSpecials({
    propertyId,
    organizationId,
  });

  // Fetch data
  const { results, isLoading } = useManageUnitAvailabilityQuery({
    propertyId,
    organizationId,
  });

  // Parse results
  const parsedResults = useParseResults({ results });

  // Manage Headers
  const headers = useHeaders({
    hasCommercialFloorPlans,
    hasManageUnitsUpdatePermission,
    parsedResults,
    isAffordableOrMixedProperty,
  });

  // Manage Filter and Sort
  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    initialOrderBy: 'moveInReadyStatus',
    initialOrder: 'desc',
    headers,
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: parsedResults,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP: PROPERTY_PATH_MAP ?? {},
  });

  const HUDTransferProps = useFetchHUDTransferProps({
    propertyId,
    organizationId,
    householdId,
    isTransfer,
    isAffordableOrMixedProperty,
  });

  // Manage Rows
  const rows = useRows({
    applicantToAssignUnit,
    assignUnitProps,
    hasManageUnitsUpdatePermission,
    sortedAndFilteredResults,
    unitQuoteMutation,
    HUDTransferProps,
  });

  // Manage Columns
  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  // Manage CSV export
  const { onCSVButtonClick } = useCSVExport({
    filteredHeaders,
    rows,
    hasAnyFilters: Boolean(Object.keys(filterState)?.length),
  });

  const footerRow = useFooterRow({
    sortedAndFilteredResults,
  });

  return {
    allColumnsHidden,
    applicantToAssignUnit,
    assignUnitProps,
    columnOptions,
    count: rows.length ?? 0,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    intl,
    isLoading,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    sortedAndFilteredResults,
    totalCount: results?.units?.length ?? null,
    UnitSpecialsProps,
    HUDTransferProps,
    isTransfer,
  };
};

export default useManageUnitAvailability;
