import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { parse } from 'qs';
import { change, submit } from 'redux-form';
import { NoPrint } from 'react-easy-print';
import queryClient from '../../react-query-client';
import {
  always,
  and,
  any,
  curryN,
  either,
  equals,
  evolve,
  ifElse,
  intersection,
  isEmpty,
  isNil,
  not,
  omit,
  path,
  pathEq,
  pathOr,
  pick,
  reject,
} from 'ramda';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  SizedBox,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { LazyLoad } from '@fortress-technology-solutions/fortress-component-library/HOC';
import TextingTab from '../TextingTab';
import TextingTabIcon from '../TextingTabIcon';
import {
  ELECTRONIC_SIGNING_METHOD,
  getSelectedLease,
  mapCommercialLeaseBasicsToLeaseData,
  mapFormValuesLeaseData,
} from '../../utils/lease-helpers';
import CamTab from '../CamTab';
import { PRORATE_METHODS } from '../../components/CompleteRenewalCheck/RenewalProrates/constants';
import { MOVE_OUT_PRORATE_MEHTODS } from './constants';

/* Foreign Imports */
// Actions
import * as applicationActions from '../ApplicationProfile/actions';
import {
  assignApplication,
  updateApplication,
} from '../ApplicationProfile/actions';
import * as prospectActions from '../PeopleProfile/actions';
import { getCustomerLedgerHeader, refreshLedger } from '../Ledger/actions';
import {
  getAllActivityTypes,
  getAllAffordableRelationships,
  getAllApplicantCancellationReasons,
  getAllApplicantTypes,
  getAllAssignees,
  getAllContactTypes,
  getAllCountries,
  getAllMoveOutReasons,
  getAllHousingSituations,
  getAllNameSuffixes,
  getAllPetBreeds,
  getAllPetTypes,
  getAllProspectStatus,
  getAllReferralTypes,
  getAllRelationships,
  getAllStates,
  getAllProrateMethods,
  getAllMoveOutProrateMethods,
  selectProperty,
  getAllSpecialNeedsDesignations,
  promptToaster,
} from '../App/actions';
import { getResidentHousehold } from '../EditResidentHousehold/actions';
import { getSignatureStatuses } from '../LeaseDataTab/actions';
import { getAllDocuments } from '../ManageDocuments/actions';

// Components
import EditOccupantModal from '../../components/EditOccupantModal';
import {
  EditMoveInDateModal,
  EditMoveOutDateModal,
} from '../../components/EditMoveDateModal';
import EditPropertyMoveInDateModal from '../../components/EditPropertyMoveInDateModal';
import Spinner from '../../components/GifSpinner';
import confirm from '../../components/ConfirmDialogModal';
import { editCompletedActivity } from '../../components/EditCompletedActivityModal';
import { editPendingActivity } from '../../components/EditPendingActivityModal';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import {
  recordActivity,
  scheduleActivity,
} from '../../components/ActivityModal';
import ActivityTableDeprecated from '../../components/ActivityTableDeprecated';

// Containers
import Household from '../Household';
import ManageDocuments from '../ManageDocuments';
import Ledger from '../Ledger';
import WorkOrderTab from '../ManageWorkOrders/WorkOrderTab';
import ProspectInfoTab from '../PeopleProfile/ProspectInfoTab';
import LeaseSigning from '../LeaseSigning';
import LeaseDataTab from '../LeaseDataTab';
import HousingChoiceVoucherTab from '../HousingChoiceVoucher';
import CommercialLeaseDataTab from '../CommercialLeaseDataTab';

// Selectors
import {
  getAffordableRelationshipOptions,
  getApplicantTypeOptions,
  getCountryOptions,
  getMoveOutReasonsOptions,
  getNameSuffixOptions,
  getProspectAssigneesOptions,
  getRelationshipOptions,
  getSelectedPropertyClassType,
  getStateOptions,
  getSpecialNeedsDesignationOptions,
  getHousingSituationOptions,
  getLiveInCaretakerOptions,
} from '../App/selectors';

// Types
import type {
  ActivityType,
  ContactType,
  DropdownOption,
  GlobalState,
  PaginationMeta,
  PetBreed,
  PetType,
  ReferralType,
  SelectOption,
  ProrateMethod,
} from '../App/types';

import {
  configureResidentApplicantAffordableTabsDisplay,
  HUD_PROGRAM_NAME,
  HUD_SUBSIDY_CODES,
} from '../AffordableQualificationTab/Shared/utils';
import {
  AFFORDABLE_QUALIFICATION_STATUSES,
  getIsReceivingAssistance,
  getNonOptionalChargeFromQualifications,
} from '../../utils/affordable';

import type { Applicant } from '../CreateApplication/types';
import type { Unit } from '../ViewUnit/types';
import type { Activity } from '../ProspectProfile/types';

/* ResidentProfile Imports */
import LeaseApplicationTab from './LeaseApplicationTab';
import noticeToVacate from './NoticeToVacateModal';
import ResidentMoveOut from './ResidentMoveOut';
import ResidentProfileDetails from './ResidentProfileDetails';
import { TransferScreenContainer } from './ResidentTransfer';
import Snapshot from './Snapshot';
import SocketService, { base64FileDowload } from '../../services/socketService';
import ActivityTypeService from '../../services/activityTypeService';

import * as residentActions from './actions';
import { getMoveOutProratedTransactions } from '../../utils/prorateCalculator';
import messages from './messages';
import {
  getApplicationStatusOptions,
  getApprovedResidents,
  getCurrentLease,
  getCurrentUser,
  getFinanciallyResponsiblePendingApplicants,
  getFinanciallyResponsibleResidents,
  getHouseholdPendingMembers,
  getHouseholdResidentMembers,
  getIsFutureLease,
  getLabelEndDate,
  getLateMethods,
  getMonthlyTransactions,
  getPreviousLeaseId,
  getRecompleteCertificationStatus,
  getSecurityDeposits,
  hasOpenTransferLease,
  hasOpenRenewalLease,
  isCurrentResident,
  isRenewalComplete,
  getScreeningLetterTypes,
  getMonthlyTransactionsForProrateCalculator,
} from './selectors';
import type { HouseholdMember, NoticeToVacateForm } from './types';
import Tenant from '../Tenant';
import { CERTIFICATION_TYPES } from '../AffordableQualificationTab/HUDTab/ExceptionsDrawer/constants';
import { createActivity } from '../../components/CreateActivityModal';
import ActivityContainer from '../../components/ActivityContents';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { DEPRECATED_MOVE_OUT_REASON_IDS } from './constants';
import { RD_PROGRAM_NAME } from '../GeneralAffordable/constants';

const GeneralAffordableNonHUD = LazyLoad(() =>
  import('../../containers/GeneralAffordable/GeneralAffordable.NonHUD'),
);
const GeneralAffordableHUD = LazyLoad(() =>
  import('../../containers/GeneralAffordable/GeneralAffordable.HUD'),
);
const GeneralAffordableRD = LazyLoad(() =>
  import('../../containers/GeneralAffordable/GeneralAffordable.RD'),
);
const LoadableAffordableQualificationTab = Loadable({
  loader: () => import('../AffordableQualificationTab/GeneralAffordableTab'),
  loading: Spinner,
});
const LoadableHUDQualificationTab = Loadable({
  loader: () => import('../AffordableQualificationTab/HUDTab'),
  loading: Spinner,
});
const LoadableAffordableQualificationHistoryTab = Loadable({
  loader: () => import('../AffordableQualificationHistoryTab'),
  loading: Spinner,
});

type Props = {
  activeTab: string,
  currentUser: any,
  users: Array<DropdownOption>,
  locale: string,
  petTypes: Array<PetType>,
  petBreeds: Array<PetBreed>,
  contactTypes: Array<ContactType>,
  referralTypes: Array<ReferralType>,
  assignedTo: Object,
  applicationInfo: Object,
  applicationQualificationInfo: Object,
  prospectInfo: Object,
  isCompliant: boolean,
  ledgerBalance: string,
  subsidyBalance: string,
  monthsWithLateFees: number,
  householdMembers: Array<HouseholdMember>,
  residents: Array<any>,
  applicantCancellationReasons: Array<any>,
  houseHoldPendingMembers: Array<any>,
  financiallyResponsibleHousehold: Array<any>,
  financiallyResponsiblePendingApplicants: Array<any>,
  activityTypes: Array<ActivityType>,
  pendingActivities: Array<Activity>,
  completedActivities: Array<Activity>,
  completedActivitiesMeta: PaginationMeta,
  allActivities: Array<Activity>,
  allActivitiesMeta: PaginationMeta,
  columnOrder: Object,
  states: Array<SelectOption>,
  moveOutReasons: Array<SelectOption>,
  labelEndDate: any,
  isFutureLease: boolean,
  unit?: Unit,
  propertyClassType: string,
  suffixes: Array<Object>,
  countries: Array<Object>,
  submittingRenewal: boolean,
  selectedProperty: ?Object,
  transferInformation?: Object,
  leases: Array<Object>,
  leaseSignatureStatuses: Object,
  affordableQualifications: Array<Object>,
  affordableException: Object,
  affordableSetup: Object,
  householdAffordableDetails: ?Object,
  firstUnitMoveInAfterPriorResident: any,
  monthsWithNSFFees: number,
  moveInApplyFullAmountToProRate: boolean,
  moveOutProratedRentMethodId: string,
  deprecated_moveOutProratedRentMethodId: string,
  prorateMethods: Array<ProrateMethod>,
  specialNeedsDesignations: Array<SelectOption>,
  fpNonOptionalCharges: Array<Object>,
  fasComplete: boolean,
  propertyPaymentProvider: string,
  propertyHasHUD: boolean,
  housingSituations: Array<SelectOption>,
  liveInCaretakerOptions: Object,
};
type InjectedProps = {
  intl: any,
  actions: Object,
  residentId: string,
  residentInfo: Object,
  history: Object,
  currentLease: Object,
  leaseTerms: Array<any>,
  lateMethods: Array<any>,
  leaseRentPercentage: string,
  securityDeposits: Array<any>,
  basicLeaseFees: Array<any>,
  monthlyTransactions: Array<any>,
  selectedMonthlyOption: string,
  match: Object,
  applicationStatuses: Array<any>,
  applicants: any,
  screening: string,
  previousLeaseId: string,
  hasOpenTransfer: boolean,
  hasOpenRenewal: boolean,
  isResident: boolean,
  isRenewalComplete: boolean,
  openFiscalPeriod: Object,
  isFirstFiscalPeriod: boolean,
  affordableRelationships: Array<Object>,
  relationshipOptions: Array<Object>,
  applicantTypeOptions: Array<Object>,
  applicationDecisionStatus: any,
  reCompleteCertificationStarted: ?boolean,
  deletedApplicants: Array<Object>,
  screeningLetterTypes: Array<Object>,
};

type State = {
  activityHistoryCurrentPage: number,
  activityHistoryField: string,
  activityHistoryLimit: number,
  activityHistoryOrder: string,
  actualMoveOutDate: any,
  actualTransferDate: any,
  complianceApprovalId: string,
  editMode: boolean,
  editModeProspectInfo: boolean,
  editModeRequiresNote: boolean,
  key: string,
  hasHouseholdRentableItems: boolean,
  missingInitialCert: boolean,
  modal: ?string,
  monthlyTransactions: Array<any>,
  occupantToEdit: string,
  processMoveOut: boolean,
  processTransfer: boolean,
  recertEnabled: boolean,
  reCompleteCertificationStarted: ?boolean,
  retrievedPendingActivities: boolean,
  showRecert: boolean,
  householdId: string,
  activityTableUpdateTrigger: boolean,
};

export class ResidentProfile extends Component<Props & InjectedProps, State> {
  constructor(props: Props & InjectedProps) {
    super(props);
    this.state = {
      activityHistoryCurrentCount: 0,
      activityHistoryCurrentPage: 1,
      activityHistoryField: 'dateTime',
      activityHistoryLimit: 10,
      activityHistoryOrder: 'DESC',
      activityHistoryPageCount: 0,
      activityHistoryTotalCount: 0,
      actualMoveOutDate: moment(),
      actualTransferDate: moment(),
      complianceApprovalId: '',
      editMode: false,
      editModeProspectInfo: false,
      editModeRequiresNote: false,
      isFinalized: false,
      missingInitialCert: false,
      modal: null,
      monthlyTransactions: [],
      monthlyTransactionsForProrateCalculator: [],
      occupantToEdit: '',
      processMoveOut: false,
      processTransfer: false,
      recertEnabled: false,
      reCompleteCertificationStarted: false,
      retrievedPendingActivities: false,
      showRecert: false,
      hasHouseholdRentableItems: false,
      hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate: false,
      refetchHouseholdRentableItemsTrigger: 0,
      key: this.props.activeTab,
      householdId: '',
      activityTableUpdateTrigger: false, // tells activity table to re-query activities
    };
  }

  componentDidMount() {
    this.props.actions.getAllActivityTypes();
    this.props.actions.getAllAssignees();
    this.props.actions.getAllPetTypes();
    this.props.actions.getAllPetBreeds();
    this.props.actions.getAllContactTypes();
    this.props.actions.getAllReferralTypes();
    this.props.actions.getAllStates();
    this.props.actions.getAllCountries();
    this.props.actions.getAllMoveOutReasons();
    this.props.actions.getAllHousingSituations();
    this.props.actions.getOneResident(this.props.residentId);
    this.props.actions.updateColumnsSortValue('dateTime', 'descending');
    this.props.actions.getAllApplicationStatus();
    this.props.actions.getResidentHousehold(this.props.residentId);
    this.props.actions.getAllNameSuffixes();
    this.props.actions.getAllAffordableRelationships();
    this.props.actions.getAllApplicantTypes();
    this.props.actions.getAllRelationships();
    this.props.actions.getAllApplicationDecisionStatus();
    this.props.actions.getAllApplicantCancellationReasons();
    this.props.actions.getFirstUnitMoveInAfterPriorResident(
      this.props.residentId,
    );
    this.props.actions.getAllProrateMethods();
    this.props.actions.getAllMoveOutProrateMethods();
    this.props.actions.getAllSpecialNeedsDesignations();

    if (SocketService && SocketService.socket) {
      if (SocketService.socket) {
        SocketService.socket.off('renewalProrateLedgerAdjustment.success');
        SocketService.socket.off('renewalProrateDocument.success');
      }
      SocketService.socket.on(
        'renewalProrateLedgerAdjustment.success',
        this.onRenewalProrateAdjustmentsPosted,
      );
      SocketService.socket.on(
        'renewalProrateDocument.success',
        this.onRenewalProrateDocumentCreated,
      );
      SocketService.socket.on(
        'renewalProrateDocument.error',
        this.onRenewalProrateDocumentError,
      );
    }
  }

  componentWillUnmount() {
    this.props.actions.cleanLoadedResident();
    if (SocketService.socket) {
      SocketService.socket.off('renewalProrateLedgerAdjustment.success');
      SocketService.socket.off('renewalProrateDocument.success');
      SocketService.socket.off('renewalProrateDocument.error');
    }
  }

  componentDidUpdate(prevProps: Object) {
    if (
      this.state.retrievedPendingActivities === false &&
      this.props.prospectInfo.id &&
      this.props.applicationInfo
    ) {
      this.setState({ ...this.state, retrievedPendingActivities: true });
      this.props.actions.getProspectPendingActivities(
        this.props.prospectInfo.id,
      );
      this.refreshActivities();
    }

    const prevPropsleases = pathOr([], ['leases'], prevProps);
    const prevPropsLeaseId =
      prevPropsleases.length > 0 && pathOr(null, ['id'], prevPropsleases[0]);
    const prevPropsDesiredSignatureMethod =
      prevPropsleases.length > 0 &&
      pathOr(null, ['desiredSignatureMethod'], prevPropsleases[0]);
    // $FlowFixMe
    const leases = pathOr([], ['leases'], this.props);
    const newestLease = leases.length > 0 ? leases[0] : {};
    const desiredSignatureMethod = pathOr(
      null,
      ['desiredSignatureMethod'],
      newestLease,
    );
    if (
      (prevPropsLeaseId !== newestLease.id ||
        prevPropsDesiredSignatureMethod !== desiredSignatureMethod) &&
      desiredSignatureMethod === ELECTRONIC_SIGNING_METHOD
    ) {
      this.props.actions.getSignatureStatuses(newestLease.id);
    }

    const previousHouseholdId = pathOr(
      null,
      ['residentInfo', 'householdId'],
      prevProps,
    );
    const currentHouseholdId = pathOr(
      null,
      ['residentInfo', 'householdId'],
      this.props,
    );
    const hasCam = pathOr(
      false,
      ['applicationInfo', 'property', 'isCAMActive'],
      this.props,
    );
    if (
      hasCam &&
      previousHouseholdId !== currentHouseholdId &&
      !isNil(currentHouseholdId)
    ) {
      this.props.actions.fetchCamPools(currentHouseholdId);
    }

    this.props.socketFailed &&
      this.props.fasComplete &&
      this.props.history.replace('?');
  }

  onRenewalProrateAdjustmentsPosted = () => {
    const customerId = pathOr(
      null,
      ['residentInfo', 'residentCustomer', 'customerId'],
      this.props,
    );
    if (!isNil(customerId)) {
      this.props.actions.refreshLedger(customerId, true);
    }
  };

  onRenewalProrateDocumentError = (data) => {
    this.props.actions.promptToaster({
      type: 'error',
      title: this.props.intl.formatMessage(
        messages.errorRenewalProrateDocumentCreatedTitle,
      ),
      message: this.props.intl.formatMessage(
        messages.errorRenewalProrateDocumentCreated,
      ),
    });
  };

  onRenewalProrateDocumentCreated = (data) => {
    this.props.actions.promptToaster({
      type: 'success',
      title: this.props.intl.formatMessage(
        messages.successRenewalProrateDocumentCreatedTitle,
      ),
      message: this.props.intl.formatMessage(
        messages.successRenewalProrateDocumentCreated,
      ),
    });

    // Refresh documents tab
    const applicationId = pathOr(
      '',
      ['residentInfo', 'household', 'currentApplicationId'],
      this.props,
    );
    if (!isNil(applicationId)) {
      this.props.actions.getAllDocuments(applicationId);
    }

    // Download document
    const fileName = pathOr(null, ['file', 'name'], data);
    const fileData = pathOr(null, ['file', 'data'], data);
    if (!isNil(fileName) && !isNil(fileData)) {
      base64FileDowload(fileData, fileName, 'application/pdf');
    }
  };

  static getDerivedStateFromProps(props: any, state: any) {
    const queryString = parse(
      pathOr('', ['location', 'search'], props).replace('?', ''),
    );
    const selectedPropertyId = pathOr('', ['selectedProperty', 'id'], props);
    const { propertyId: queryPropertyId } = queryString;
    const userProperties = pathOr(
      [],
      ['currentUser', 'user', 'properties'],
      props,
    );
    if (
      queryPropertyId &&
      queryPropertyId !== selectedPropertyId &&
      userProperties.some((p) => p.id === queryPropertyId)
    ) {
      const newProperty = userProperties.find((p) => p.id === queryPropertyId);
      props.actions.selectProperty(newProperty);
    } else if (!selectedPropertyId) {
      props.history.push('/');
    }

    if (state.monthlyTransactions !== props.monthlyTransactions) {
      return {
        ...state,
        monthlyTransactions: props.monthlyTransactions,
      };
    }

    if (
      state.monthlyTransactionsForProrateCalculator !==
      props.monthlyTransactionsForProrateCalculator
    ) {
      return {
        ...state,
        monthlyTransactionsForProrateCalculator:
          props.monthlyTransactionsForProrateCalculator,
      };
    }

    return null;
  }

  onProcessMoveOutClick = () => {
    this.setState({
      ...this.state,
      processMoveOut: true,
      actualMoveOutDate: moment(),
    });
  };

  onCancelProcessMoveOutClick = () => {
    this.setState({ ...this.state, processMoveOut: false });
  };

  moveOutDateStatus = (formSyncErrors: Object) => {
    return {
      statusText:
        Object.keys(formSyncErrors).includes('moveOutDate') &&
        typeof formSyncErrors.moveOutDate !== 'undefined'
          ? 'Not Confirmed'
          : 'Confirmed',
      valid:
        Object.keys(formSyncErrors).includes('moveOutDate') &&
        typeof formSyncErrors.moveOutDate !== 'undefined'
          ? 'status status-ball status-red'
          : 'status status-ball status-green',
    };
  };

  handleChecklistOptionChange = (
    applicant: Object,
    item: Object,
    type: string,
    { target: { value } }: Object,
  ) => {
    const checklistChanges = {
      applicationId: applicant.applicationId,
      applicantChecklistId: applicant.applicantChecklistId,
      checklistItemId: item.checklistItemId,
      checklistItemOptionId: type === 'option' ? value : item.selectedOption,
      applicantChecklistDecisionId: type === 'decision' ? value : null,
    };
    return this.props.actions.updateApplicantChecklist(checklistChanges);
  };

  confirmMoveOutModal = (handleSubmit: Function) => {
    const isAllCommercial =
      this.props.selectedProperty.hasCommercialFloorPlans === 'ALL';
    confirm(
      isAllCommercial
        ? this.props.intl.formatMessage(messages.confirmMoveOutTenant)
        : this.props.intl.formatMessage(messages.confirmMoveOut),
      {
        intl: this.props.intl,
      },
    ).then(
      () => {
        handleSubmit();
      },
      () => {},
    );
  };

  approveApplicant = ({ id, applicationId }: any, cancelFunc: ?Function) => {
    confirm(
      `Are you sure you want approve this applicant
    and move him in as a resident of this household?`,
      { cancelFunc },
    ).then(() => this.props.actions.approveApplicant(id, applicationId));
  };
  cancelApplicant = ({ id, applicationId }: any, cancelReasonId: string) => {
    confirm(
      "Are you sure you want deny this applicant and cancel it's application?", // eslint-disable-line
    ).then(() =>
      this.props.actions.cancelApplicant(id, applicationId, cancelReasonId),
    );
  };

  handleSubmitMoveOut = (moveOutInfo: Object) => {
    const newProRatedTransactions = moveOutInfo.proRatedTransactions.map(
      (transaction) => {
        delete transaction.monthlyTransactionId;
        return transaction;
      },
    );
    const newMoveOutInfo = {
      ...moveOutInfo,
      proRatedTransactions: newProRatedTransactions,
    };
    this.props.actions.moveOutResident(newMoveOutInfo, this.props.residentId);
  };

  checkUserPermissions = (testScope: Array<string>) => {
    const userScopes = this.props.currentUser.permissions.map(
      (permission) => permission.scope,
    );
    return intersection(testScope, userScopes).length > 0;
  };

  handleEditHousehold = () => {
    const url = `/resident/${this.props.residentId}/household`;
    navigateToUrlWithSelectedPropertyId(url);
  };

  handleRenewLease = () => {
    const url = `/generate-renewal-offer/${this.props.residentId}`;
    navigateToUrlWithSelectedPropertyId(url);
  };

  handleTransfer = () => {
    try {
      const selectedPropertyId = this.props.selectedProperty?.id ?? '';
      // $FlowFixMe
      const applicationId = pathOr(
        '',
        ['residentInfo', 'household', 'currentApplicationId'],
        this.props,
      );

      // $FlowFixMe
      const applicantdId = pathOr(
        '',
        ['residentInfo', 'applicant', 'id'],
        this.props,
      );
      // $FlowFixMe
      const residentId = pathOr('', ['residentInfo', 'id'], this.props);
      // $FlowFixMe
      const prospectInfo = pathOr({}, ['prospectInfo'], this.props);
      // $FlowFixMe
      const applicationInfo = pathOr({}, ['applicationInfo'], this.props);
      // $FlowFixMe
      const currentUnitNumber = pathOr(null, ['unit', 'number'], this.props);
      Object.assign(applicationInfo, { prospectInfo });
      localStorage.setItem(
        'previous-applicant-id',
        JSON.stringify(applicationInfo) + '',
      );
      let url = `/property/${selectedPropertyId}/manage-unit-availability?`;
      url = url
        .concat(`applicantId=${applicantdId}`)
        .concat(`&residentId=${residentId}`)
        .concat(`&applicationId=${applicationId}`)
        .concat(`&fromUnit=${currentUnitNumber}`)
        .concat('&isTransfer=true');
      this.props.history.push(url);
    } catch (e) {
      /* empty */
    }
  };

  handleTransferCancel = () => {
    this.setState({
      ...this.state,
      processTransfer: false,
      processMoveOut: false,
      actualTransferDate: moment(),
    });
  };

  onCompleteTransferClick = () => {
    this.setState({
      ...this.state,
      processTransfer: true,
      processMoveOut: false,
      actualTransferDate: moment(),
    });
  };

  handleAssignedToChange = (event: Object, newUserId: string) => {
    this.props.actions.assignApplication(
      {
        ...this.props.applicationInfo,
        assignedToId: newUserId,
      },
      () => this.refreshActivityTable(),
    );
  };

  handleDoNotRenewClick = (): void => {
    const {
      applicationInfo: { applicants },
      residentInfo: {
        household: { doNotRenew },
      },
      actions: { updateDoNotRenew },
    } = this.props;
    const updatedCustomers = applicants.reduce((customers, applicant) => {
      const customerId = pathOr(
        null,
        ['applicantCustomer', 'customer', 'id'],
        applicant,
      );
      if (customerId) {
        customers.push({ [customerId]: !doNotRenew });
      }
      return customers;
    }, []);
    updateDoNotRenew(updatedCustomers, !doNotRenew);
  };

  handleFasReady = (): void => {
    const {
      residentInfo: {
        lease: { id, fasReady },
      },
      actions: { updateFasReady },
    } = this.props;
    updateFasReady(id, !fasReady);
  };

  handleUnderEvictionClick = (): void => {
    const {
      applicationInfo: { applicants },
      residentInfo: {
        household: { underEviction },
      },
      currentLease,
    } = this.props;
    const updatedCustomers = applicants.reduce((customers, applicant) => {
      const customerId = pathOr(
        null,
        ['applicantCustomer', 'customer', 'id'],
        applicant,
      );
      if (customerId) {
        customers.push({ [customerId]: !underEviction });
      }
      return customers;
    }, []);

    this.props.actions.updateUnderEviction(
      updatedCustomers,
      !underEviction,
      currentLease.id,
    );
  };
  handleAutoEmailMonthlyInvoiceClick = (): void => {
    const {
      residentInfo: {
        household: { autoEmailMonthlyInvoices, id },
      },
    } = this.props;
    this.props.actions.updateAutoEmailMonthlyInvoice(
      id,
      !autoEmailMonthlyInvoices,
    );
  };

  handleNoticeToVacateSubmit = (values: NoticeToVacateForm) => {
    const { states } = this.props;
    const { toDelete, ...ntv } = values;
    const isDefault = (val) => val === 'default' || val === '';
    const isNilOrDefault = either(isNil, isDefault);
    // $FlowFixMe
    const filteredNtv = reject(isNilOrDefault, ntv);
    const state = states.find(
      (state: SelectOption) => filteredNtv.state === state.value,
    );
    const ntvToSave =
      values.noForwardingAddress === true
        ? {
            ...filteredNtv,
            street: null,
            city: null,
            state: null,
            stateName: null,
            zipCode: null,
          }
        : {
            ...filteredNtv,
            state: state ? state.value : null,
            stateName: state ? state.text : null,
          };

    if (toDelete) {
      this.props.actions.deleteNoticeToVacate(
        ntvToSave,
        this.refreshActivityTable,
      );
    } else {
      this.props.actions.saveNoticeToVacate(
        ntvToSave,
        this.refreshActivityTable,
      );
    }
  };

  handleNoticeToVacateClick = async (isFromFAS = false) => {
    const { residentId, isResident } = this.props;
    const forwardingAddressRequired = !isResident;
    await noticeToVacate(
      this.context.store,
      this.props.intl,
      this.props.propertyHasHUD,
      this.props.propertyClassType,
      this.props.unit,
      this.props.states,
      forwardingAddressRequired,
      this.props.moveOutReasons,
      this.props.housingSituations,
      this.props.applicationInfo.property.turnMoveOutDays,
      this.props.residentInfo,
      this.handleNoticeToVacateSubmit,
      this.props.openFiscalPeriod,
      this.props.leases,
      isFromFAS,
      this.props.financiallyResponsibleHousehold,
    );

    this.props.actions.submit('NoticeToVacate');
    if (forwardingAddressRequired && isFromFAS) {
      navigateToUrlWithSelectedPropertyId(
        `/prior-resident/${residentId}/final-account-statement`,
      );
    }
  };

  onActivityHistoryOrder = (field: string) => {
    const columnOrder = this.props.columnOrder[field];
    const order = columnOrder === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrder === 'sortable'
        ? 'ascending'
        : columnOrder === 'ascending'
        ? 'descending'
        : 'ascending';
    this.props.actions.updateColumnsSortValue(field, icon);
    this.setState({ activityHistoryField: field, activityHistoryOrder: order });
    this.props.actions.getProspectAllActivities(
      this.props.prospectInfo.id,
      this.state.activityHistoryCurrentPage,
      this.state.activityHistoryLimit,
      field,
      order,
    );
    this.props.actions.getProspectCompletedActivities(
      this.props.prospectInfo.id,
      this.state.activityHistoryCurrentPage,
      this.state.activityHistoryLimit,
      field,
      order,
    );
  };

  refreshActivities() {
    if (!!this.props.prospectInfo.id) {
      this.props.actions.getProspectAllActivities(
        this.props.prospectInfo.id,
        this.state.activityHistoryCurrentPage,
        this.state.activityHistoryLimit,
        this.state.activityHistoryField,
        this.state.activityHistoryOrder,
      );
      this.props.actions.getProspectCompletedActivities(
        this.props.prospectInfo.id,
        this.state.activityHistoryCurrentPage,
        this.state.activityHistoryLimit,
        this.state.activityHistoryField,
        this.state.activityHistoryOrder,
      );
    }
  }

  onActivityHistoryPageChange(pageNumber: number) {
    this.setState({ activityHistoryCurrentPage: pageNumber }, () =>
      this.refreshActivities(),
    );
  }

  onRecordActivityClick = async () => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.props.users;
    /* istanbul ignore else */
    if (this.props.prospectInfo.id != null) {
      const data = await recordActivity(
        this.context.store,
        this.props.intl,
        this.props.prospectInfo,
        activityTypesList,
        users,
        'resident',
        this.props.residentId,
      );
      this.props.actions.createProspectActivity(this.getActivityData(data));
      this.setState({ activityHistoryCurrentPage: 1 });
    }
  };

  onScheduleActivityClick = async () => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.props.users;
    /* istanbul ignore else */
    if (this.props.prospectInfo.id != null) {
      const data: Activity = await scheduleActivity(
        this.context.store,
        this.props.intl,
        this.props.prospectInfo,
        activityTypesList,
        users,
        'resident',
        this.props.residentId,
      );
      this.props.actions.createProspectActivity(this.getActivityData(data));
      this.setState({ activityHistoryCurrentPage: 1 });
    }
  };

  onCreateActivityClick = async (refresh?: Function) => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.props.users;
    if (this.props.prospectInfo.id != null) {
      const data: Activity = await createActivity({
        store: this.context.store,
        intl: this.props.intl,
        prospect: this.props.prospectInfo,
        activityTypesList: activityTypesList,
        assigneeList: users,
        stage: 'resident',
        urlId: this.props.residentId,
      });
      this.props.actions.createProspectActivity(
        this.getActivityData(data),
        refresh,
      );
      this.setState({ activityHistoryCurrentPage: 1 });
    }
  };

  onEditActivity = (activity: Activity, refresh?: Function) => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.props.users;
    const stageInfo = {
      currentStage: 'resident',
      urlId: this.props.residentId,
    };

    const editPromise = activity.activityCompletionStatusId
      ? editCompletedActivity(
          this.context.store,
          this.props.intl,
          activity,
          stageInfo,
        )
      : editPendingActivity(
          this.context.store,
          this.props.intl,
          activity,
          activityTypesList,
          users,
          this.props.prospectInfo,
          stageInfo,
        );

    editPromise.then((data: Activity) => {
      if (data.saveAndClose) {
        this.props.actions.updateProspectActivity(data, refresh);
      } else if (data.saveAndAddNew) {
        this.props.actions.updateProspectActivity(data, refresh);
        this.onCreateActivityClick(this.forceRefreshActivityTable);
      } else {
        this.props.actions.deleteProspectActivity(data, refresh);
      }
      this.setState({ activityHistoryCurrentPage: 1 });
    });
  };

  handleEditApplicationForm = (applicant: Applicant) => {
    const isProspect = () =>
      and(
        not(isNil(applicant)),
        pathEq(['applicantCustomer', 'isProspect'], true)(applicant),
      );

    const isFinanciallyResponsible = () =>
      and(
        and(
          not(isNil(applicant)),
          not(isNil(path(['applicantCustomer'], applicant))),
        ),
        and(
          not(isProspect()),
          pathEq(['applicantType', 'financiallyResponsible'], true)(applicant),
        ),
      );

    const isNotFinanciallyResponsible = () =>
      and(
        and(
          not(isNil(applicant)),
          not(isNil(path(['applicantCustomer'], applicant))),
        ),
        and(not(isProspect()), not(isFinanciallyResponsible())),
      );

    if (isProspect()) {
      navigateToUrlWithSelectedPropertyId(
        `/primary-form/${applicant.applicationId}/${applicant.id}`,
      );
    }
    if (isFinanciallyResponsible()) {
      navigateToUrlWithSelectedPropertyId(
        `/non-primary-form/${applicant.applicationId}/${applicant.id}`,
      );
    }
    if (isNotFinanciallyResponsible()) {
      navigateToUrlWithSelectedPropertyId(
        `/shortApplication/${applicant.applicationId}/${applicant.id}`,
      );
    }
  };

  /**
   * TODO: both `handleRenewal` is a higher order function that is taking a
   * callback because we want a toastr and to refresh the data via sagas.
   * Move this into the LeaseDataTab so that we don't need this craziness here.
   *
   *
   * -> Addressed on commmercial lease data tab
   */
  handleRenewal = (updateCurrentLeaseCb: Function) => () => {
    const { residentId, previousLeaseId } = this.props;
    this.props.actions.renewLease(
      residentId,
      previousLeaseId,
      {},
      updateCurrentLeaseCb,
    );
  };

  /**TODO:
   * Refreshing the list of leases is being done by refreshing the whole resident
   * We want to eventually replace this one function to retrieve only that
   */
  handleRefreshLeases = () => {
    const { residentId } = this.props;
    this.props.actions.getOneResident(residentId);
  };

  handleCommercialLeaseBasicsSubmit = (leaseId: string, values: any) => {
    const {
      actions: { saveLeaseDataTabResident },
      residentId,
      unit,
    } = this.props;
    const leaseData = {
      ...mapCommercialLeaseBasicsToLeaseData(values),
      unitId: unit.id,
    };

    saveLeaseDataTabResident(leaseId, leaseData, residentId);
  };

  leaseDataHandleSubmit = (values: any) => {
    const { householdMembers } = this.props;
    const nonFRAdults = householdMembers.filter(
      // $FlowFixMe
      ({ type, financiallyResponsible, status }) =>
        type === 'adult' && !financiallyResponsible && status !== 'Prior',
    );
    const desiredSignatureMethod =
      values.desiredSignatureMethod === ELECTRONIC_SIGNING_METHOD &&
      nonFRAdults.length > 0
        ? 'default'
        : values.desiredSignatureMethod;
    const formData = mapFormValuesLeaseData({
      ...values,
      desiredSignatureMethod,
    });

    this.props.actions.saveLeaseDataTabResident(
      formData.id,
      formData,
      this.props.residentId,
      this.refreshActivityTable,
    );
    return values;
  };

  validateRequired = (value: any) =>
    not(isEmpty(value)) && not(isNil(value)) && !equals('default', value);

  leaseDataHandleGenerateLease = async (values: any) => {
    const data = mapFormValuesLeaseData(values);
    const { currentLease } = this.props;
    await this.props.actions.generateFutureLeaseDocument({
      leaseId: data.id,
      lease: data,
      residentId: this.props.residentId,
      transfer: currentLease.isTransfer,
    });
    return values;
  };

  removeLease = (
    applicationId: string,
    leaseId: string,
    residentId: string,
  ) => {
    // $FlowFixMe
    const prospectId = pathOr('', ['prospectInfo', 'id'], this.props);
    this.props.actions.removeLease(
      applicationId,
      leaseId,
      residentId,
      prospectId,
    );
  };

  getActivityData(data: Object) {
    return Object.assign(data, {
      customerStatus: this.props.isResident
        ? 'Current Resident'
        : 'Prior Resident',
    });
  }

  // TODO: scrub of affordable values when 59 is live
  updateApplication = (changes: any) => {
    const { actions, applicationInfo, residentId } = this.props;
    const propertyId = pathOr('', ['property', 'id'], applicationInfo);
    const HHmoveInInfoManual = pathOr(
      false,
      ['HHmoveInInfoManual'],
      applicationInfo,
    );
    const { HHincomeAtMoveIn, HHnumOccupantsAtMoveIn } = changes;
    if ((HHincomeAtMoveIn || HHnumOccupantsAtMoveIn) && !HHmoveInInfoManual) {
      changes = {
        ...changes,
        HHmoveInInfoManual: true,
      };
    }
    const pickedFields = pick(
      [
        'id',
        'unitTypeId',
        'prospectId',
        'propertyId',
        'notes',
        'vehicleCount',
        'applicationStatusId',
        'updatedById',
        'propertyClassId',
        'adults',
        'minors',
        'pets',
        'fees',
        'affordableQualifyingChecklist',
        'publicHousingAuthority',
        'hudIncomeLimitId',
        'affordableDocuments',
        'reCompleteCertificationStarted',
        'householdUtilityAllowanceId',
        'voucherEffectiveDate',
        'rentalAssistanceSourceId',
        'complianceApprovalId',
        'setAsideProgramId',
      ],
      applicationInfo,
    );

    const toScrubArray = ['HHincomeAtMoveIn', 'HHnumOccupantsAtMoveIn'].reduce(
      (scrubArr, key) => {
        return changes[key] === '' ? [...scrubArr, key] : scrubArr;
      },
      [],
    );
    const scrubbedChanges =
      toScrubArray.length > 0 ? omit(toScrubArray, changes) : changes;

    const applicationUpdates = {
      ...pickedFields,
      propertyId,
      ...scrubbedChanges,
    };

    actions.updateApplication(
      applicationUpdates,
      'successDescriptionQualifcation',
      residentId,
    );
    actions.getOneResident(residentId);
  };

  resetModal = () => {
    this.setState({ modal: null });
  };

  editOccupant = (customerId: string) => {
    this.setState({ occupantToEdit: customerId, modal: 'editOccupantForm' });
  };

  handleEditOccupantSubmit = (values: Object) => {
    const scrubNulls = (section: Object): ?Object =>
      section ? reject(isNil, section) : null;
    const setNulls = (section: Object, fieldNames: Array<string>) => {
      const setNull = (value) =>
        value === '' || value === 'default' ? null : value;
      const transformations = fieldNames.reduce((transformationObj, key) => {
        return {
          ...transformationObj,
          [key]: setNull,
        };
      }, {});
      return evolve(transformations, section);
    };
    const yesNoToBool = (value) => value === 'yes';
    const {
      actions: { updateResident },
      applicationInfo,
      propertyClassType,
      residentId,
    } = this.props;
    const { id, type, minorsResId } = values;
    const applicantPropertyClass = pathOr(
      '',
      ['propertyClass', 'name'],
      applicationInfo,
    );
    const isAffordable =
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' &&
        applicantPropertyClass === 'Affordable');
    const isConventional =
      propertyClassType === 'Conventional' || propertyClassType === 'Mixed';
    let affordableInformation = null;
    if (isAffordable) {
      const setAffordableNulls = setNulls(values.affordableInformation, [
        'gender',
        'handicapped',
        'race',
        'ethnicity',
        'studentType',
      ]);
      affordableInformation = scrubNulls(setAffordableNulls);
      if (affordableInformation && affordableInformation.handicapped) {
        const handicappedValue = pathOr(
          null,
          ['handicapped'],
          affordableInformation,
        );
        if (handicappedValue) {
          affordableInformation.handicapped = yesNoToBool(handicappedValue);
        }
      }
    }
    const generalInformationWithNulls = setNulls(values.generalInformation, [
      'suffixId',
      'preferredName',
      'middleName',
      'relationshipId',
    ]);
    const generalInformation =
      type === 'adult'
        ? generalInformationWithNulls
        : omit(['phoneNumber', 'emailAddress'], generalInformationWithNulls);

    const personalInformation = setNulls(values.personalInformation, [
      'alienRegistration',
      'passportCountry',
      'passportNumber',
      'stateIdNumber',
      'stateIdOrigin',
      'maritalStatus',
      'socialSecurityNumber',
      'dateOfBirth',
    ]);
    const specialNeedsDesignationIds =
      values?.reportingInformation?.specialNeedsDesignationIds ?? [];
    const specialNeedsAffordableChange =
      this.props?.flags?.specialNeedsAffordableChange ?? false;
    const specialNeedsDesignationValues = specialNeedsAffordableChange
      ? { specialNeedsDesignationIds }
      : {};
    const annualIncome = values?.reportingInformation?.annualIncome ?? null;
    const annualIncomeValues =
      isConventional && specialNeedsAffordableChange
        ? {
            annualIncome: annualIncome
              ? parseFloat(annualIncome).toFixed(2)
              : null,
          }
        : {};
    const MINCNumber = values?.reportingInformation?.MINCNumber ?? null;
    const MINCNumberValues =
      isAffordable && MINCNumber
        ? {
            MINCNumber,
          }
        : {};

    const initialResidentInfo = {
      affordableInformation,
      generalInformation,
      personalInformation,
      minorsResId,
      ...specialNeedsDesignationValues,
      ...annualIncomeValues,
      ...MINCNumberValues,
    };
    const residentUpdates = scrubNulls(initialResidentInfo);
    const residentInfo = { residentUpdates, id, residentId, type };
    updateResident(residentInfo);
    setTimeout(() => {
      queryClient.invalidateQueries([
        'getHouseholdProfileAffordableQualificationsByProgram',
      ]);
    }, 2000);
  };

  showMoveInDateModal = () => {
    this.setState({ modal: 'editMoveInDateModal' });
  };

  showMoveOutDateModal = () => {
    this.setState({ modal: 'editMoveOutDateModal' });
  };

  showPropertyMoveInDateModal = () => {
    this.setState({ modal: 'editPropertyMoveInDateModal' });
  };

  handleMoveInDateModalSubmit = (values: Object) => {
    const {
      residentInfo: { id },
    } = this.props;
    const { updateMoveInDate } = this.props.actions;

    const leaseChangeset = {
      actualMoveInDate: values.editMoveDate,
    };

    this.resetModal();
    updateMoveInDate({
      residentId: id,
      modify: leaseChangeset,
      success: 'Move-In Date Updated',
      refreshActivityTable: this.refreshActivityTable,
    });
  };

  handleMoveOutDateModalSubmit = (values: Object) => {
    const {
      residentInfo: { id },
    } = this.props;
    const { updatePriorResidentMoveOutDate } = this.props.actions;

    const leaseChangeset = {
      moveOutDate: values.editMoveDate,
    };

    this.resetModal();
    updatePriorResidentMoveOutDate({
      residentId: id,
      modify: leaseChangeset,
      success: 'Move-Out Date Updated',
    });
  };

  handlePropertyMoveInDateModalSubmit = (values: Object) => {
    const {
      residentInfo: { id },
    } = this.props;
    const { updatePropertyMoveInDate } = this.props.actions;

    const ModifiedPropertyMoveInDate = {
      householdMoveInDate: values.editPropertyMoveInDate,
    };

    this.resetModal();
    updatePropertyMoveInDate({
      residentId: id,
      modify: ModifiedPropertyMoveInDate,
      success: 'Property Move-In Date Updated',
    });
  };
  sendPayLeaseEmail = (customerId: string) => {
    this.props.actions.sendResidentPayLeaseEmail(customerId);
  };

  getMonthsSinceMoveIn = (currentMoveInDate: any) => {
    const now = moment();
    const momentCurrentMoveInDate = moment(currentMoveInDate);
    return now.diff(momentCurrentMoveInDate, 'months');
  };

  handleMoveOutDateUpdate = (date: any) => {
    this.setState({
      actualMoveOutDate: moment(
        moment.utc(date).local().format('MM/DD/YYYY'),
        'MM/DD/YYYY',
      ),
    });
  };

  overrideDisabledRecertification = () => {
    this.setState({ recertEnabled: !this.state.recertEnabled });
  };

  setMissingInitialCert = (numberOfCerts: number) => {
    const { residentInfo } = this.props;
    const residentWithNoCerts = numberOfCerts === 0;
    const actualMoveInDate = pathOr(
      null,
      ['lease', 'actualMoveInDate'],
      residentInfo,
    );
    const firstYearResident = actualMoveInDate
      ? moment(actualMoveInDate).isAfter(moment().subtract(1, 'years'))
      : false;
    const missingInitialCert = residentWithNoCerts && firstYearResident;
    if (missingInitialCert) {
      this.setState({ missingInitialCert: true });
    }
  };

  selectCorrectionTab = (programName?: string) => {
    if (programName) {
      this.setState({ ...this.state, key: programName });
    }
  };

  selectTab = (tabEventKey: string) => {
    this.setState({ ...this.state, key: tabEventKey });
  };

  getResidentButtonsMessage = (type: 'transfer' | 'renewal') => {
    const { residentInfo, unit, applicationInfo } = this.props;

    const isCommercial = applicationInfo.isCommercial === true;

    const {
      household: { doNotRenew, underEviction },
    } = residentInfo;
    // $FlowFixMe
    const unitStatus = pathOr(false, ['unitStatus', 'description'], unit);

    if (underEviction) return '"Under Eviction" is selected.';
    if (doNotRenew) return '"Do Not Renew" is selected.';
    if (
      !isCommercial &&
      type === 'transfer' &&
      unitStatus !== 'Occupied / On Notice to Vacate'
    )
      return 'Must enter NTV to begin transfer.';
  };

  refreshActivityTable = () => {
    this.setState({
      activityTableUpdateTrigger: !this.state.activityTableUpdateTrigger,
    });
  };

  // At the moment of triggering the above from the modals it doesn't actually refreshes the table properly
  forceRefreshActivityTable = () => {
    this.setState(
      {
        activityTableUpdateTrigger: !this.state.activityTableUpdateTrigger,
      },
      () => this.refreshActivities(),
    );
  };

  setHasHouseholdRentableItems = (hasHouseholdRentableItems: boolean) => {
    this.setState({ hasHouseholdRentableItems });
  };

  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate = (
    hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate: boolean,
  ) => {
    this.setState({
      hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
    });
  };

  render() {
    if (!this.props.prospectInfo.id) {
      return <AsyncBox loading={true} sx={{ height: 'calc(100vh - 40px)' }} />;
    }

    const onChecklistOptionChangeCurried = curryN(
      4,
      this.handleChecklistOptionChange.bind(this),
    );
    const {
      affordableRelationships,
      applicantCancellationReasons,
      applicants,
      applicationDecisionStatus,
      applicationInfo,
      applicationStatuses,
      assignedTo,
      basicLeaseFees,
      countries,
      currentLease,
      currentUser,
      deletedApplicants,
      financiallyResponsibleHousehold,
      financiallyResponsiblePendingApplicants,
      hasOpenTransfer,
      hasOpenRenewal,
      history,
      householdMembers,
      houseHoldPendingMembers,
      intl,
      isCompliant,
      isFutureLease,
      isResident,
      labelEndDate,
      lateMethods,
      leases,
      ledgerBalance,
      monthsWithLateFees,
      monthsWithNSFFees,
      openFiscalPeriod,
      isFirstFiscalPeriod,
      propertyClassType,
      prospectInfo,
      reCompleteCertificationStarted,
      residentId,
      residentInfo,
      residents,
      screening,
      securityDeposits,
      selectedMonthlyOption,
      selectedProperty,
      states,
      submittingRenewal,
      suffixes,
      transferInformation,
      unit,
      users,
      leaseSignatureStatuses,
      relationshipOptions,
      applicantTypeOptions,
      affordableQualifications,
      affordableException,
      affordableSetup,
      householdAffordableDetails,
      recertDueDates,
      firstUnitMoveInAfterPriorResident,
      moveOutProratedRentMethodId,
      deprecated_moveOutProratedRentMethodId,
      prorateMethods,
      moveOutProrateMethods,
      flags,
      fpNonOptionalCharges,
      liveInCaretakerOptions,
      locale,
    } = this.props;

    const hasAssignedUnit = unit?.id?.length;
    const roundProratedRents =
      selectedProperty?.config?.roundProratedRents || false;
    const proRatedTransactions = getMoveOutProratedTransactions(
      this.state.monthlyTransactionsForProrateCalculator,
      this.state.actualMoveOutDate,
      this.props.moveInApplyFullAmountToProRate,
      roundProratedRents,
    );

    const { hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate } =
      this.state;

    const {
      lease,
      lease: { fasReady, anticipatedMoveOutDate },
      household: {
        doNotRenew,
        underEviction,
        paymentsDoNotAccept,
        paymentsCertifiedOnly,
        householdMoveInDate,
        autoEmailMonthlyInvoices,
        doNotAcceptPartialPayments,
      },
      householdId,
      residentCustomer: {
        customerId,
        customer: { firstName, lastName },
      },
    } = residentInfo;

    const { moveOutDate } = lease?.noticeToVacate || {};
    const actualOrAnticipatedMoveOutDate =
      moveOutDate || anticipatedMoveOutDate;

    // $FlowFixMe
    const householdInfo = pathOr({}, ['household'], residentInfo);
    const getApprovedStatusId = (applicationStatuses: Array<Object>) => {
      const approvedStatus = applicationStatuses.find(
        (status) => status.text === 'Approved',
      );
      return pathOr(null, ['value'], approvedStatus);
    };
    const pendingApplicants = pathOr([], ['pendingApplicants'], applicants);

    const status = isResident ? 'Resident' : 'Prior Resident';
    const isCommercial = applicationInfo.isCommercial === true;
    const currentMoveInDate = currentLease.moveInDate;
    const monthsSinceMoveIn = this.getMonthsSinceMoveIn(currentMoveInDate);

    // $FlowFixMe
    const recertStarted = pathOr(
      false,
      ['applicationInfo', 'recertificationStarted'],
      this.props,
    );
    const isRecomplete = reCompleteCertificationStarted;
    // $FlowFixMe
    const unitStatus = pathOr(false, ['unitStatus', 'description'], unit);
    const isOnNTV = unitStatus === 'Occupied / On Notice to Vacate';
    const isDisabledRenewal = ifElse(
      always(
        any(equals(true))([
          doNotRenew,
          underEviction,
          hasOpenRenewal,
          isFutureLease,
          isNil(unit),
          isEmpty(unit),
        ]),
      ),
      always(true),
      always(false),
    )();
    const isDisabledTransfer = any(equals(true))([
      !isOnNTV,
      doNotRenew,
      underEviction,
      hasOpenTransfer,
      hasOpenRenewal,
      isFutureLease,
      isNil(unit),
      isEmpty(unit),
    ]);
    const startTransferMessage = !isCommercial
      ? this.getResidentButtonsMessage('transfer')
      : undefined;
    const startRenewalMessage = this.getResidentButtonsMessage('renewal');
    const newestLease = leases.length > 0 ? leases[0] : {};
    const parsedNewestLease = getSelectedLease(leases, newestLease.id);

    /**
     * When some events happen on the CAM tab (such as confirm estimates, reconcile, cancel CAM, ...),
     * the ledger should be refreshed.
     */
    const onCamRefresh = () => {
      // Refresh CAM Pools
      const householdId = pathOr(
        null,
        ['residentInfo', 'householdId'],
        this.props,
      );
      if (householdId) {
        this.props.actions.fetchCamPools(householdId);
      }

      // refresh ledger
      this.props.actions.refreshLedger(customerId, true);
    };

    if (this.state.processMoveOut) {
      const moveOutDate = this.state.actualMoveOutDate
        ? moment(
            moment
              .utc(this.state.actualMoveOutDate)
              .local()
              .format('MM/DD/YYYY'),
            'MM/DD/YYYY',
          )
        : null;
      const moveOutProratedRentAdminSettingFlag =
        flags?.moveOutProratedRentAdminSetting;
      const moveOutProratedRentMethod = moveOutProratedRentAdminSettingFlag
        ? (prorateMethods.find(
            (prm) => prm.id === deprecated_moveOutProratedRentMethodId,
          )?.name ?? PRORATE_METHODS.NO_NEVER) === PRORATE_METHODS.YES_ALWAYS
        : true;

      const getShouldProrate = (methodId) => {
        const method =
          moveOutProrateMethods.find((method) => {
            return method.id === methodId;
          }) ?? MOVE_OUT_PRORATE_MEHTODS.NO_NEVER;

        return [
          MOVE_OUT_PRORATE_MEHTODS.YES_FULL_MONTH,
          MOVE_OUT_PRORATE_MEHTODS.YES_MONTH_START,
        ].includes(method?.name);
      };
      const shouldProrateAtMoveOut = flags?.prorateRentsForMoveOuts
        ? getShouldProrate(moveOutProratedRentMethodId)
        : moveOutProratedRentMethod;

      const proRatedTransactionsToProcess = shouldProrateAtMoveOut
        ? proRatedTransactions
        : [];

      return (
        <ResidentMoveOut
          cancel={this.onCancelProcessMoveOutClick}
          intl={intl}
          initialValues={{
            moveOutDate,
            proRatedTransactions: proRatedTransactionsToProcess,
          }}
          customerName={`${firstName} ${lastName}`}
          unitNumber={unit ? unit.number : ''}
          moveOutClick={this.confirmMoveOutModal}
          onSubmit={this.handleSubmitMoveOut}
          openFiscalPeriod={this.props.openFiscalPeriod}
          isFirstFiscalPeriod={isFirstFiscalPeriod}
          moveOutDateStatus={this.moveOutDateStatus}
          monthlyTransactions={proRatedTransactionsToProcess}
          handleMoveOutDateUpdate={this.handleMoveOutDateUpdate}
          moveInApplyFullAmountToProRate={
            this.props.moveInApplyFullAmountToProRate
          }
          moveOutProratedRent={shouldProrateAtMoveOut}
          roundProratedRents={roundProratedRents}
        />
      );
    }

    if (this.state.processTransfer) {
      const newUnit = pathOr([{}], ['units'], parsedNewestLease)[0];
      return (
        <TransferScreenContainer
          handleCancel={this.handleTransferCancel}
          intl={intl}
          lease={currentLease}
          customerName={`${firstName} ${lastName}`}
          userId={currentUser.user.id}
          leaseId={currentLease.id}
          unitNumber={newUnit ? newUnit.number : ''}
          openFiscalPeriod={this.props.openFiscalPeriod}
          residentId={residentId}
        />
      );
    }

    // $FlowFixMe
    const leaseRentPercentage = pathOr(
      '20',
      ['applicationInfo', 'property', 'leaseRentPercentage'],
      this.props,
    );

    const applicantPropertyClass = pathOr(
      '',
      ['propertyClass', 'name'],
      applicationInfo,
    );
    const complianceNotes = pathOr('', ['complianceNotes'], applicationInfo);
    const showQualificationTab =
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' &&
        applicantPropertyClass === 'Affordable');
    const qualTabInitialValues = pick(
      [
        'complianceApprovalId',
        'hapPayment',
        'HHincomeAtMoveIn',
        'HHnumOccupantsAtMoveIn',
        'householdUtilityAllowanceId',
        'hudIncomeLimitId',
        'publicHousingAuthority',
        'receivingAssistance',
        'rentalAssistanceSourceId',
        'residentPayment',
        'voucherEffectiveDate',
      ],
      applicationInfo,
    );
    const showDocumentsTab = this.checkUserPermissions(['document-read']);
    const showTextingTab =
      selectedProperty.isTwoWayCommunicationActive &&
      this.checkUserPermissions(['communication-create']);

    const actualMoveInDate = pathOr(null, ['actualMoveInDate'], lease);
    const currentFiscalStartDate = pathOr(
      null,
      ['startDate'],
      openFiscalPeriod,
    );

    let showEditMoveInDateLinkFlag = false;
    if (actualMoveInDate && currentFiscalStartDate) {
      const momentMoveInDate = moment(actualMoveInDate);
      const momentFiscalStartDate = moment(currentFiscalStartDate);

      if (
        isResident &&
        momentMoveInDate.isBefore(momentFiscalStartDate, 'days')
      ) {
        showEditMoveInDateLinkFlag = true;
      }
    }

    const showEditMoveInDateLink = () => {
      if (showEditMoveInDateLinkFlag) {
        const isAffordableUnit = unit?.floorPlan?.isAffordable ?? false;
        const tooltipMessage = isAffordableUnit
          ? this.props.intl.formatMessage(
              messages.unableUpdateMoveInDateAffordable,
            )
          : '';
        return (
          <ElementWithPermissions scope={['lease-current-lease-end-date-edit']}>
            <Tooltip title={tooltipMessage} containerSX={{ display: 'inline' }}>
              <a
                onClick={() => {
                  this.showMoveInDateModal();
                }}
                disabled={isAffordableUnit}
              >
                <i className="icon et-pencil" />
              </a>
            </Tooltip>
          </ElementWithPermissions>
        );
      } else {
        return null;
      }
    };

    const showEditMoveOutDateLink = () => {
      if (!isResident) {
        return (
          <ElementWithPermissions scope={['prior-resident-move-out-date-edit']}>
            <a
              onClick={() => {
                this.showMoveOutDateModal();
              }}
            >
              <i className="icon et-pencil" />
            </a>
          </ElementWithPermissions>
        );
      } else {
        return null;
      }
    };

    let showEditPropertyMoveInDateFlag = false;
    if (householdMoveInDate && currentFiscalStartDate) {
      const momenthouseholdMoveInDate = moment(householdMoveInDate);
      const momentFiscalStartDate = moment(currentFiscalStartDate);

      if (
        isResident &&
        momenthouseholdMoveInDate.isBefore(momentFiscalStartDate, 'days')
      ) {
        showEditPropertyMoveInDateFlag = true;
      }
    }
    const showEditPropertyMoveInDateLink = () => {
      if (showEditPropertyMoveInDateFlag) {
        return (
          <ElementWithPermissions scope={['lease-current-lease-end-date-edit']}>
            <a
              onClick={() => {
                this.showPropertyMoveInDateModal();
              }}
            >
              <i className="icon et-pencil" />
            </a>
          </ElementWithPermissions>
        );
      } else {
        return null;
      }
    };

    const editMoveInDateModalInitial = {
      editMoveDate: actualMoveInDate ? moment(actualMoveInDate) : null,
    };
    const editMoveOutDateModalInitial = {
      editMoveDate:
        !isResident && currentLease && currentLease.moveOutDate
          ? moment(currentLease.moveOutDate)
          : null,
    };
    const editPropertyMoveInDateModalInitial = {
      editPropertyMoveInDate: householdMoveInDate
        ? moment(householdMoveInDate)
        : null,
    };
    const leasedRent = pathOr(0, ['leasedRent'], currentLease);

    const allHouseholdMembers = householdMembers.concat(
      houseHoldPendingMembers,
    );
    // $FlowFixMe
    const dropdownHouseholdMembers = pathOr(
      [],
      ['applicationInfo', 'applicants'],
      this.props,
    )
      .filter((applicant) => applicant.applicantPet === null)
      .map((applicant) => ({ label: applicant.name, value: applicant.id }));

    const occupantToEdit =
      allHouseholdMembers.find(
        // $FlowFixMe
        (e) => pathOr('', ['id'], e) === this.state.occupantToEdit,
      ) || {};

    const frNames = financiallyResponsibleHousehold.map((f) => f.completeName);

    const paymentRestrictions = {
      doNotAccept: paymentsDoNotAccept,
      certifiedOnly: paymentsCertifiedOnly,
      doNotAcceptPartialPayments: doNotAcceptPartialPayments,
    };

    // Live-In Caretakers are non financial responsible adults
    // but they shouldn't be included in the household members list for the lease
    const nonFRAdults = householdMembers.filter(
      // $FlowFixMe
      ({ type, financiallyResponsible, status, applicantTypeId }) =>
        type === 'adult' &&
        !financiallyResponsible &&
        applicantTypeId !== liveInCaretakerOptions?.applicantType?.id &&
        status !== 'Prior',
    );
    const { affordableTabs, hudTab, householdUtilityAllowanceId } =
      configureResidentApplicantAffordableTabsDisplay(
        affordableQualifications,
        selectedProperty,
        unit || {},
        flags,
      );

    // Get all affordable program Ids open to pass down to Qualification History Tab
    const openAffordableQualProgramIds = [
      ...new Set(
        affordableQualifications
          .filter((aq) => aq.isActive)
          .filter((aq) => aq.certificationType !== 'GROSS_RENT')
          .map((openQual) => openQual.propertyAffordableProgramId),
      ),
    ];

    const hasOpenRDCertifications = affordableQualifications?.some(
      (aq) =>
        aq?.propertyAffordableProgram?.masterAffordableProgram?.name ===
          RD_PROGRAM_NAME && aq.isActive,
    );

    const hasReOpenedRDCertifications = affordableQualifications?.some(
      (aq) =>
        aq?.propertyAffordableProgram?.masterAffordableProgram?.name ===
          RD_PROGRAM_NAME &&
        aq.isActive &&
        aq.certificationStatus === AFFORDABLE_QUALIFICATION_STATUSES.REOPEN,
    );

    const isReceivingAssistance = getIsReceivingAssistance(
      affordableTabs || [],
    );

    const showHUD = isResident
      ? hudTab
      : hudTab && hudTab.affordableQualificationId;

    /**
     * This is to only disable approval of applicant for LIHTC units without HUD
     * Not relevant for HUD since HUD can do an IR cert
     * Since it means there is a loop hole in the system that allows a resident to move in into a
     * income certified unit until the next recert
     * https://fortress-technology.atlassian.net/browse/ENG-2634
     */
    const disallowApplicantApproval =
      monthsSinceMoveIn < 6 &&
      financiallyResponsiblePendingApplicants.length > 0 &&
      !recertStarted &&
      !showHUD;

    const editMoveInValidateFiscalPeriod = (moveInDate) => {
      const fiscalPeriodStartDate = pathOr(
        null,
        ['startDate'],
        openFiscalPeriod,
      );
      if (moveInDate && fiscalPeriodStartDate) {
        const momentMoveInDate = moment(moveInDate);
        const momentStartDate = moment(fiscalPeriodStartDate);

        if (momentMoveInDate.isSameOrAfter(momentStartDate, 'days')) {
          const formattedStartDate = momentStartDate.format('MM-DD-YYYY');
          return `Move-In Date cannot be a date in the current fiscal period (on or after ${formattedStartDate}).`;
        }
      }
      return null;
    };

    const ntv = pathOr(null, ['lease', 'noticeToVacate'], unit);

    const editMoveOutValidation = (newMoveOutDate) => {
      if (!newMoveOutDate) {
        return;
      }

      const momentNewMoveOutDate = moment(newMoveOutDate);

      // Move-out cannot be in the future from now
      if (moment().isBefore(momentNewMoveOutDate, 'days')) {
        return 'Move-Out Date cannot be in the future';
      }

      // Move-out cannot be before move-in
      if (actualMoveInDate) {
        const momentActualMoveInDate = moment(actualMoveInDate);
        if (momentActualMoveInDate.isAfter(momentNewMoveOutDate, 'days')) {
          const formatted = momentActualMoveInDate.format('MM-DD-YYYY');
          return `Move-Out Date cannot be before the Move-In date (on or after ${formatted})`;
        }
      }

      // Move-out cannot be later than the current one if another resident has moved in
      // the same unit
      if (firstUnitMoveInAfterPriorResident) {
        const momentFirstMoveIn = moment(firstUnitMoveInAfterPriorResident);
        if (momentFirstMoveIn.isBefore(momentNewMoveOutDate, 'days')) {
          const formatted = momentFirstMoveIn.format('MM-DD-YYYY');
          return `Move-Out Date cannot be after another resident moved in (on or before ${formatted})`;
        }
      }
    };

    const isCAMActive = pathOr(
      false,
      ['applicationInfo', 'property', 'isCAMActive'],
      this.props,
    );

    // show CAM Tab both for prior and current tenants
    // only for commercial units on properties with active CAM
    const showCamTab = isCommercial && isCAMActive;
    const showCamPoolsOnCreateTransaction =
      showCamTab &&
      !isNil(this.props.camPools) &&
      !isEmpty(this.props.camPools);
    const camPoolsForTransactions = showCamPoolsOnCreateTransaction
      ? this.props.camPools
      : null;

    const frMembersForAffordable = financiallyResponsibleHousehold.filter(
      (m) => !(m?.status ?? '').includes('Prior'),
    );

    const hasCompletedNonHUDInitialCertification = !isNil(
      affordableQualifications?.find(
        (aqh) =>
          aqh.certificationType === CERTIFICATION_TYPES.INITIAL &&
          aqh.propertyAffordableProgram?.masterAffordableProgram?.name !==
            HUD_PROGRAM_NAME,
      ),
    );

    const qualificationHistoryIds = affordableQualifications
      .filter((aq) => aq.isActive)
      .map((aq) => aq.id);

    const nonOptionalCharge = getNonOptionalChargeFromQualifications(
      affordableQualifications,
    );

    const miniProfileApplicant =
      applicationInfo?.applicants?.find((applicant) => {
        return applicant?.resident?.id === this.state.occupantToEdit;
      }) ?? null;

    // Check applicant annual income which comes from saving from mini-profile
    // if doesn't exist populate with applicantCustomer data which comes from application forms
    const initialAnnualIncome = miniProfileApplicant?.annualIncome
      ? miniProfileApplicant.annualIncome
      : miniProfileApplicant?.applicantCustomer?.annualIncome ?? '0.00';
    const initialMINCNumber = miniProfileApplicant?.applicantMinor
      ? miniProfileApplicant?.applicantMinor?.MINCNumber
      : miniProfileApplicant?.applicantCustomer?.customer?.MINCNumber ?? null;
    const reportingInformationInitialValues = {
      specialNeedsDesignationIds:
        miniProfileApplicant?.specialNeedsDesignationIds ?? [],
      annualIncome: initialAnnualIncome,
      MINCNumber: initialMINCNumber,
    };

    const hasOpenAffordableQualification =
      affordableQualifications?.filter((aq) => aq.isActive)?.length > 0;

    const appliesForPhaseIn =
      householdInfo?.householdPhaseInEligibility?.appliesForPhaseIn;

    const section236Flag = flags?.section236;
    let isSection236 = false;
    if (showHUD && section236Flag) {
      const floorplan = unit?.floorPlan;
      const fpHudCode = (floorplan?.floorPlanAffordablePrograms ?? []).find(
        (fp) =>
          fp?.propertyAffordableProgram?.masterAffordableProgram?.name ===
          HUD_PROGRAM_NAME,
      )?.hudCode;
      const propertyHUDSubsidy = (
        selectedProperty?.propertyHUDSubsidy ?? []
      ).map((hud) => hud?.masterHUDSubsidy?.code);

      isSection236 =
        propertyHUDSubsidy.includes(HUD_SUBSIDY_CODES.SECTION_236) ||
        fpHudCode === HUD_SUBSIDY_CODES.SECTION_236;
    }

    const onCreateFinalAccountStatementReversal = (
      finalAccountStatementReversal,
    ) => {
      this.props.actions.getOneResident(residentId);
      this.props.actions.refreshLedger(customerId, true);
      this.refreshActivityTable();
    };

    const isAffordable =
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' &&
        applicantPropertyClass === 'Affordable');

    return (
      <DocumentTitle
        title={this.props.intl.formatMessage(messages.title)}
        className="creditstatus-page"
      >
        <NoPrint>
          <EditMoveInDateModal
            moveType="moveIn"
            show={this.state.modal === 'editMoveInDateModal'}
            onSubmit={this.handleMoveInDateModalSubmit}
            dismiss={this.resetModal}
            initialValues={editMoveInDateModalInitial}
            additionalValidation={editMoveInValidateFiscalPeriod}
          />
          <EditMoveOutDateModal
            moveType="moveOut"
            show={this.state.modal === 'editMoveOutDateModal'}
            onSubmit={this.handleMoveOutDateModalSubmit}
            dismiss={this.resetModal}
            initialValues={editMoveOutDateModalInitial}
            additionalValidation={editMoveOutValidation}
          />
          <EditPropertyMoveInDateModal
            show={this.state.modal === 'editPropertyMoveInDateModal'}
            onSubmit={this.handlePropertyMoveInDateModalSubmit}
            dismiss={this.resetModal}
            initialValues={editPropertyMoveInDateModalInitial}
            openFiscalPeriod={openFiscalPeriod}
          />
          <EditOccupantModal
            affordableRelationships={affordableRelationships}
            countries={countries}
            initialValues={{
              ...occupantToEdit.residentInfo,
              reportingInformation: reportingInformationInitialValues,
            }}
            isAffordable={showQualificationTab}
            isCommercial={isCommercial}
            selectedProperty={selectedProperty}
            unit={unit}
            occupantInfo={occupantToEdit}
            onClose={this.resetModal}
            onSubmit={this.handleEditOccupantSubmit}
            sendPayLeaseEmail={this.sendPayLeaseEmail}
            show={this.state.modal === 'editOccupantForm'}
            states={states}
            suffixes={suffixes}
            unitNumber={pathOr('', ['units', '0', 'number'], currentLease)}
            newestLease={newestLease}
            relationshipOptions={relationshipOptions}
            applicantTypeOptions={applicantTypeOptions}
            applicant={miniProfileApplicant}
            flags={flags}
          />
          <Grid fluid>
            <Row>
              <Col xs={12} md={5} className="people-profile-left">
                <ResidentProfileDetails
                  applicants={applicationInfo.applicants}
                  isResident={isResident}
                  unitNumber={unit ? unit.number : ''}
                  unitId={unit ? unit.id : ''}
                  isCommercial={isCommercial}
                  isUnitSoftDeleted={typeof unit?.deletedAt === 'string'}
                />
                <Snapshot
                  isResident={isResident}
                  initialValues={{ assignedToId: assignedTo?.id }}
                  doNotRenew={doNotRenew}
                  underEviction={underEviction}
                  ledgerBalance={ledgerBalance}
                  monthsWithLateFees={monthsWithLateFees}
                  monthsWithNSFFees={monthsWithNSFFees}
                  users={users}
                  handleAssignedToChange={this.handleAssignedToChange}
                  handleNoticeToVacateClick={this.handleNoticeToVacateClick}
                  handleDoNotRenewClick={this.handleDoNotRenewClick}
                  handleUnderEvictionClick={this.handleUnderEvictionClick}
                  handleRenewLease={this.handleRenewLease}
                  history={history}
                  householdInfo={householdInfo}
                  onProcessMoveOutClick={this.onProcessMoveOutClick}
                  fasReady={!!fasReady}
                  accountFinalized={!!applicationInfo.accountFinalized}
                  customerId={residentId}
                  manualWriteOff={this.props.actions.manualWriteOff}
                  currentLease={lease}
                  newestLease={parsedNewestLease}
                  showEditMoveInDateLink={showEditMoveInDateLink}
                  hasOpenTransfer={hasOpenTransfer}
                  paymentRestrictions={paymentRestrictions}
                  isCommercial={isCommercial}
                  showEditPropertyMoveInDateLink={
                    showEditPropertyMoveInDateLink
                  }
                  showEditMoveOutDateLink={showEditMoveOutDateLink}
                  autoEmailMonthlyInvoices={autoEmailMonthlyInvoices}
                  handleAutoEmailMonthlyInvoiceClick={
                    this.handleAutoEmailMonthlyInvoiceClick
                  }
                  applicationId={applicationInfo.id}
                  propertyId={this.props?.selectedProperty?.id}
                  organizationId={this.props?.selectedProperty?.organizationId}
                  promptToaster={this.props.actions.promptToaster}
                  fasComplete={this.props.fasComplete}
                  propertyPaymentProvider={this.props.propertyPaymentProvider}
                  hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate={
                    hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate
                  }
                  hasReOpenedRDCertifications={hasReOpenedRDCertifications}
                  residentInfo={residentInfo}
                  onCreateFinalAccountStatementReversal={
                    onCreateFinalAccountStatementReversal
                  }
                />
                {isCommercial === true && (
                  <Tenant
                    householdId={householdId}
                    householdMembers={allHouseholdMembers}
                    editHouseholdMember={this.editOccupant}
                    residentId={this.props.residentId}
                  />
                )}
                {isCommercial === false && (
                  <Household
                    applicationId={applicationInfo.id}
                    disableEdit={!isResident}
                    editHouseholdMember={this.editOccupant}
                    householdId={householdId}
                    householdMembers={allHouseholdMembers}
                    onEdit={this.handleEditHousehold}
                    residentId={this.props.residentId}
                    vehicleCount={applicationInfo.vehicleCount}
                    unitId={unit ? unit.id : ''}
                    hasAssignedUnit={hasAssignedUnit}
                    isPriorResident={this.props.isResident ? false : true}
                    actualOrAnticipatedMoveOutDate={
                      actualOrAnticipatedMoveOutDate
                    }
                    setHasHouseholdRentableItems={
                      this.setHasHouseholdRentableItems
                    }
                    hasHouseholdRentableItems={
                      this.state.hasHouseholdRentableItems
                    }
                    isOnNTV={isOnNTV}
                    setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate={
                      this
                        .setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate
                    }
                  />
                )}
                {newestLease.desiredSignatureMethod === 'ELECTRONIC' &&
                  (newestLease.isRenewal || newestLease.isTransfer) && (
                    <LeaseSigning
                      leaseSignatureStatuses={leaseSignatureStatuses}
                    />
                  )}
              </Col>
              <Col xs={12} md={7} className="people-profile-right">
                <Panel className="block block-white-shadow">
                  <Tabs
                    id="peoples-tab"
                    activeKey={this.state.key}
                    onSelect={(key) => this.setState({ ...this.state, key })}
                    mountOnEnter
                  >
                    <Tab
                      eventKey="1"
                      title={<FormattedMessage {...messages.activityTab} />}
                      disabled={!this.checkUserPermissions(['activity-read'])}
                      mountOnEnter
                    >
                      <Panel.Body>
                        {this.props.flags
                          .activityFilterHideAutomatedActivitiesCheckbox ? (
                          <ActivityContainer
                            intl={this.props.intl}
                            prospectId={this.props?.prospectInfo.id || ''}
                            locale={this.props.locale}
                            onEditActivity={this.onEditActivity}
                            onCreateActivityClick={this.onCreateActivityClick}
                            updateTrigger={
                              this.state.activityTableUpdateTrigger
                            }
                          />
                        ) : (
                          <ActivityTableDeprecated
                            intl={intl}
                            selectedProperty={selectedProperty}
                            prospectId={pathOr(
                              '',
                              ['prospectInfo', 'id'],
                              this.props,
                            )}
                            activities={this.props.allActivities}
                            onEditActivity={this.onEditActivity}
                            onCreateActivityClick={this.onCreateActivityClick}
                            locale={this.props.locale}
                            updateTrigger={
                              this.state.activityTableUpdateTrigger
                            }
                          />
                        )}
                      </Panel.Body>
                    </Tab>
                    {this.props.flags.workOrderTabHouseholdProfile && (
                      <Tab eventKey="10" title={'Work Orders'}>
                        <Panel.Body>
                          <WorkOrderTab
                            householdId={householdId}
                            unitId={unit && unit.id}
                            intl={this.props.intl}
                          ></WorkOrderTab>
                        </Panel.Body>
                      </Tab>
                    )}
                    {showTextingTab && (
                      <Tab
                        eventKey="texting"
                        title={
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {this.props.intl.formatMessage(messages.texting)}{' '}
                            <SizedBox w={3} />
                            <TextingTabIcon householdId={householdId} />
                          </span>
                        }
                      >
                        <Panel.Body>
                          <TextingTab householdId={householdId} />
                        </Panel.Body>
                      </Tab>
                    )}
                    <Tab
                      eventKey="2"
                      title={<FormattedMessage {...messages.ledgerTab} />}
                    >
                      <Ledger
                        customerStatus={status}
                        residentId={residentId}
                        customerId={customerId}
                        customerName={`${firstName} ${lastName}`}
                        frMembers={financiallyResponsibleHousehold}
                        frNames={financiallyResponsibleHousehold.map(
                          (f) => f.completeName,
                        )}
                        fasReady={!!fasReady}
                        accountFinalized={!!applicationInfo.accountFinalized}
                        fasDate={lease?.FASDate}
                        handleFasReadyClick={this.handleFasReady}
                        unit={unit}
                        household={householdInfo}
                        applicationId={applicationInfo.id}
                        isResident={true}
                        camPools={camPoolsForTransactions}
                        householdMembers={householdMembers}
                        lateMethodId={lease?.lateMethodId}
                      />
                    </Tab>
                    <Tab
                      eventKey="3"
                      title={<FormattedMessage {...messages.leaseDataTab} />}
                    >
                      <Panel.Body>
                        {isCommercial === true && (
                          <CommercialLeaseDataTab
                            frNames={frNames}
                            leases={leases}
                            residentId={residentId}
                            isResident={isResident}
                            labelEndDate={labelEndDate}
                            selectedMonthlyOption={selectedMonthlyOption}
                            customer={pathOr(
                              {},
                              ['residentCustomer', 'customer'],
                              residentInfo,
                            )}
                            unit={unit || {}}
                            lateMethods={lateMethods || []}
                            onLeaseBasicsSubmit={
                              this.handleCommercialLeaseBasicsSubmit
                            }
                            applicationId={applicationInfo.id}
                            prospectInfo={prospectInfo}
                            isDisabledRenewal={isDisabledRenewal}
                            refreshLeases={this.handleRefreshLeases}
                            navigationHistory={history}
                            doNotRenew={doNotRenew}
                            underEviction={underEviction}
                            startRenewalMessage={startRenewalMessage}
                            noticeToVacate={ntv}
                          />
                        )}
                        {isCommercial === false && (
                          <LeaseDataTab
                            allAffordableTabsApproved={true}
                            applicationId={applicationInfo.id}
                            application={applicationInfo}
                            basicLeaseFees={basicLeaseFees}
                            doNotRenew={doNotRenew}
                            frNames={frNames}
                            handleRenewal={this.handleRenewal}
                            hasNonFR={nonFRAdults.length > 0}
                            history={history}
                            handleTransfer={this.handleTransfer}
                            handleTransferComplete={
                              this.onCompleteTransferClick
                            }
                            isDisabledRenewal={isDisabledRenewal}
                            isDisabledTransfer={isDisabledTransfer}
                            isResident={isResident}
                            labelEndDate={labelEndDate}
                            lateMethods={lateMethods}
                            leases={leases}
                            leaseRentPercentage={leaseRentPercentage}
                            leaseSignatureStatuses={leaseSignatureStatuses}
                            leaseTerms={this.props.leaseTerms}
                            softDeletedLeaseTerms={
                              this.props.softDeletedLeaseTerms
                            }
                            onGenerate={this.leaseDataHandleGenerateLease}
                            onSubmit={this.leaseDataHandleSubmit}
                            openFiscalPeriod={this.props.openFiscalPeriod}
                            prospectInfo={prospectInfo}
                            removeLease={this.removeLease}
                            residentId={residentId}
                            securityDeposits={securityDeposits}
                            selectedMonthlyOption={selectedMonthlyOption}
                            submittingRenewal={submittingRenewal}
                            transferInformation={transferInformation}
                            utilityAllowanceId={
                              householdUtilityAllowanceId ||
                              applicationInfo.householdUtilityAllowanceId
                            }
                            isReceivingAssistance={isReceivingAssistance}
                            startTransferMessage={startTransferMessage}
                            startRenewalMessage={startRenewalMessage}
                            underEviction={underEviction}
                            noticeToVacate={ntv}
                            nonOptionalCharge={nonOptionalCharge}
                          />
                        )}
                      </Panel.Body>
                    </Tab>
                    {this.props.flags.housingChoiceVouchers &&
                      selectedProperty?.config?.housingChoiceVouchers && (
                        <Tab
                          eventKey="housing-choice-voucher-tab"
                          title={
                            <FormattedMessage
                              {...messages.housingChoiceVoucherTab}
                            />
                          }
                        >
                          <Panel.Body>
                            <HousingChoiceVoucherTab
                              organizationId={selectedProperty.organizationId}
                              propertyId={selectedProperty.id}
                              householdId={householdId}
                              intl={this.props.intl}
                            />
                          </Panel.Body>
                        </Tab>
                      )}
                    {showCamTab && (
                      <Tab
                        eventKey="9"
                        title={<FormattedMessage {...messages.camTab} />}
                      >
                        {this.state.key === '9' && (
                          <Panel.Body>
                            <CamTab
                              unit={unit}
                              selectedProperty={selectedProperty}
                              householdId={householdId}
                              intl={intl}
                              promptToaster={this.props.actions.promptToaster}
                              isPriorResident={
                                this.props.isResident ? false : true
                              }
                              onCamRefresh={onCamRefresh}
                            ></CamTab>
                          </Panel.Body>
                        )}
                      </Tab>
                    )}
                    {isCommercial === false && (
                      <Tab
                        eventKey="4"
                        title={
                          <FormattedMessage {...messages.applicationTab} />
                        }
                      >
                        <Panel.Body>
                          <LeaseApplicationTab
                            intl={intl}
                            currentRecord={applicationInfo}
                            filteredResidents={residents}
                            applicantCancellationReasons={
                              applicantCancellationReasons
                            }
                            pendingApplicants={applicants.pendingApplicants}
                            canceledApplicants={applicants.canceledApplicants}
                            initialValues={{
                              applicationStatusId:
                                getApprovedStatusId(applicationStatuses),
                            }}
                            checkUserPermissions={this.checkUserPermissions}
                            applicationId={applicationInfo.id}
                            applicationDecisionStatus={
                              applicationDecisionStatus
                            }
                            applicationStatuses={applicationStatuses}
                            screening={screening}
                            generateAdverseActionLetter={
                              this.props.actions.generateAdverseActionLetter
                            }
                            screeningLetterTypes={
                              this.props.screeningLetterTypes
                            }
                            handleEditApplicationForm={
                              this.handleEditApplicationForm
                            }
                            handleChecklistOptionChange={
                              onChecklistOptionChangeCurried
                            }
                            currentUser={currentUser}
                            isResident={isResident}
                            approveApplicant={this.approveApplicant}
                            cancelApplicant={this.cancelApplicant}
                            isCompliant={
                              isCompliant &&
                              disallowApplicantApproval &&
                              showQualificationTab
                            }
                            selectedProperty={selectedProperty}
                            householdId={householdId}
                          />
                        </Panel.Body>
                      </Tab>
                    )}
                    <Tab
                      eventKey="5"
                      title={<FormattedMessage {...messages.documentsTab} />}
                      disabled={!showDocumentsTab}
                    >
                      <Panel.Body>
                        {showDocumentsTab && (
                          <ManageDocuments
                            intl={this.props.intl}
                            applicationId={applicationInfo.id}
                            customerType="resident"
                            customerName={`${firstName} ${lastName}`}
                            deletedApplicants={deletedApplicants}
                            isAffordable={isAffordable}
                            isCommercial={isCommercial}
                            leaseId={lease.id}
                          />
                        )}
                      </Panel.Body>
                    </Tab>
                    {isCommercial === false && (
                      <Tab
                        eventKey="6"
                        title={
                          <FormattedMessage {...messages.prospectInformation} />
                        }
                      >
                        <Panel.Body>
                          {this.props.prospectInfo.id ? (
                            <ProspectInfoTab
                              intl={this.props.intl}
                              prospect={prospectInfo}
                              petTypes={this.props.petTypes}
                              contactTypes={this.props.contactTypes}
                              referralTypes={this.props.referralTypes}
                            />
                          ) : (
                            ''
                          )}
                        </Panel.Body>
                      </Tab>
                    )}
                    {showQualificationTab &&
                      (isResident || hasOpenAffordableQualification) &&
                      affordableTabs &&
                      affordableTabs.map(
                        ({
                          affordableQualificationId,
                          programId,
                          programName,
                          propertyAffordableProgramId,
                          affordableQualification,
                          recertificationStarted,
                        }) => {
                          const urlProgramName = (programName ?? '').replace(
                            /\//g,
                            '_',
                          );
                          return (
                            <Tab
                              key={urlProgramName}
                              eventKey={urlProgramName}
                              title={`${programName} Qualification`}
                            >
                              <Panel.Body>
                                {programName !== 'RD' ? (
                                  <>
                                    {this.props.flags
                                      .customerOverhaulAffordableTabs ? (
                                      <GeneralAffordableNonHUD
                                        tabKey={this.state.key}
                                        applicationId={applicationInfo.id}
                                        hasPendingApplicants={
                                          pendingApplicants.length > 0
                                        }
                                        householdId={householdId}
                                        intl={intl}
                                        programName={programName}
                                        programId={programId}
                                        propertyAffordableProgramId={
                                          propertyAffordableProgramId
                                        }
                                        residentId={residentId}
                                        recertDueDates={recertDueDates}
                                        unit={unit}
                                        refetchProfile={() => {
                                          this.props.actions.getOneResident(
                                            this.props.residentId,
                                          );
                                        }}
                                        currentMoveInDate={currentMoveInDate}
                                        locale={locale}
                                      />
                                    ) : (
                                      <LoadableAffordableQualificationTab
                                        affordableSetup={affordableSetup}
                                        applicationInfo={applicationInfo}
                                        currentApplicationId={
                                          applicationInfo.id
                                        }
                                        frMembers={frMembersForAffordable}
                                        checklists={
                                          applicationInfo.affordableQualifyingChecklist
                                        }
                                        affordableQualification={
                                          affordableQualification
                                        }
                                        unit={unit}
                                        complianceNotes={complianceNotes}
                                        leasedRent={leasedRent}
                                        affordableQualificationId={
                                          affordableQualificationId
                                        }
                                        householdId={householdId}
                                        updateApplication={
                                          this.updateApplication
                                        }
                                        programId={programId}
                                        programName={programName}
                                        propertyAffordableProgramId={
                                          propertyAffordableProgramId
                                        }
                                        recertificationStarted={
                                          recertificationStarted
                                        }
                                        residentId={residentId}
                                        hasPendingApplicants={
                                          pendingApplicants.length > 0
                                        }
                                        pendingApplicants={
                                          applicants.pendingApplicants
                                        }
                                        householdAffordableDetails={
                                          householdAffordableDetails
                                        }
                                        recertDueDates={recertDueDates}
                                        hasCompletedNonHUDInitialCertification={
                                          hasCompletedNonHUDInitialCertification
                                        }
                                        qualificationHistoryIds={
                                          qualificationHistoryIds
                                        }
                                        fpNonOptionalCharges={
                                          fpNonOptionalCharges
                                        }
                                        editOccupant={this.editOccupant}
                                      />
                                    )}
                                  </>
                                ) : null}
                                {programName === 'RD' ? (
                                  <GeneralAffordableRD
                                    tabKey={this.state.key}
                                    affordableQualificationId={
                                      affordableQualificationId
                                    }
                                    applicationId={applicationInfo.id}
                                    householdId={householdId}
                                    programId={programId}
                                    programName={programName}
                                    propertyAffordableProgramId={
                                      propertyAffordableProgramId
                                    }
                                    recertDueDates={recertDueDates}
                                    residentId={residentId}
                                    appliesForPhaseIn={appliesForPhaseIn}
                                    refetchProfile={() =>
                                      this.props.actions.getOneResident(
                                        this.props.residentId,
                                      )
                                    }
                                    intl={intl}
                                    locale={locale}
                                  />
                                ) : null}
                              </Panel.Body>
                            </Tab>
                          );
                        },
                      )}
                    {showHUD && hudTab && (
                      <Tab
                        key={hudTab.programName}
                        eventKey={hudTab.programName}
                        title={`${hudTab.programName} Qualification`}
                      >
                        <Panel.Body>
                          {this.props.flags
                            .customerOverhaulHudQualificationTab ? (
                            <GeneralAffordableHUD
                              tabKey={this.state.key}
                              affordableQualificationId={
                                hudTab.affordableQualificationId
                              }
                              applicationId={applicationInfo.id}
                              hasPendingApplicants={
                                pendingApplicants.length > 0
                              }
                              householdId={householdId}
                              programId={hudTab.programId}
                              programName={hudTab.programName}
                              propertyAffordableProgramId={
                                hudTab.propertyAffordableProgramId
                              }
                              residentId={residentId}
                              recertDueDates={recertDueDates}
                              appliesForPhaseIn={appliesForPhaseIn}
                              isSection236={isSection236}
                              intl={intl}
                              locale={locale}
                            />
                          ) : (
                            <LoadableHUDQualificationTab
                              currentApplicationId={applicationInfo.id}
                              frMembers={frMembersForAffordable}
                              householdMembers={dropdownHouseholdMembers}
                              checklists={
                                applicationInfo.affordableQualifyingChecklist
                              }
                              applicationInfo={applicationInfo}
                              unit={unit}
                              complianceNotes={complianceNotes}
                              leasedRent={leasedRent}
                              affordableQualificationId={
                                hudTab.affordableQualificationId
                              }
                              householdId={householdId}
                              updateApplication={this.updateApplication}
                              programId={hudTab.programId}
                              programName={hudTab.programName}
                              propertyAffordableProgramId={
                                hudTab.propertyAffordableProgramId
                              }
                              residentId={residentId}
                              recertificationStarted={
                                hudTab.recertificationStarted
                              }
                              affordableQualification={
                                hudTab.affordableQualification
                              }
                              affordableExceptionValues={affordableException}
                              affordableSetup={affordableSetup}
                              recertDueDates={recertDueDates}
                              selectHistoryTab={() => this.selectTab('8')}
                              qualificationHistoryIds={qualificationHistoryIds}
                              appliesForPhaseIn={appliesForPhaseIn}
                              isSection236={isSection236}
                            />
                          )}
                        </Panel.Body>
                      </Tab>
                    )}
                    {showQualificationTab ? (
                      <Tab
                        eventKey="8"
                        title={
                          <FormattedMessage
                            {...messages.qualificationHistoryTab}
                          />
                        }
                      >
                        <Panel.Body>
                          <LoadableAffordableQualificationHistoryTab
                            intl={intl}
                            isSelected={this.state.key === '8'}
                            applicationId={applicationInfo.id}
                            applicationInfo={applicationInfo}
                            complianceNotes={complianceNotes}
                            initialValues={qualTabInitialValues}
                            isRecomplete={isRecomplete}
                            residentId={residentId}
                            setMissingInitialCert={this.setMissingInitialCert}
                            updateApplication={this.updateApplication}
                            householdAffordableDetails={
                              householdAffordableDetails
                            }
                            householdId={householdId}
                            selectCorrectionTab={(programName) =>
                              this.selectCorrectionTab(programName)
                            }
                            recertDueDates={recertDueDates}
                            showHUD={showHUD}
                            openAffordableQualProgramIds={
                              openAffordableQualProgramIds
                            }
                            hasOpenRDCertifications={hasOpenRDCertifications}
                            isSection236={isSection236}
                            unit={unit}
                          />
                        </Panel.Body>
                      </Tab>
                    ) : null}
                  </Tabs>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </NoPrint>
      </DocumentTitle>
    );
  }
}

ResidentProfile.contextTypes = {
  store: PropTypes.any,
};

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): Props => {
  const { app, residentProfile, leaseDataTab, languageProvider } = state;

  const residentIdFromParams = ownProps?.match?.params?.residentId;
  const residentId = residentIdFromParams
    ? residentIdFromParams.split('?').shift()
    : '';
  return {
    residentId,
    activeTab: pathOr(
      '2',
      ['tab'],
      parse(ownProps.location.search.replace('?', '')),
    ),
    activityTypes: app.activityTypes,
    affordableRelationships: getAffordableRelationshipOptions(state),
    relationshipOptions: getRelationshipOptions(state),
    applicantTypeOptions: getApplicantTypeOptions(state),
    applicantCancellationReasons: app.applicantCancellationReasons,
    applicants: residentProfile.applicants,
    applicationDecisionStatus: residentProfile.applicationDecisionStatuses,
    applicationInfo: residentProfile.applicationInfo,
    applicationQualificationInfo: residentProfile.applicationQualificationInfo,
    applicationStatuses: getApplicationStatusOptions(state),
    assignedTo: residentProfile.assignedTo,
    basicLeaseFees: residentProfile.basicLeaseFees,
    columnOrder: residentProfile.columnOrder,
    completedActivities: residentProfile.completedActivities,
    completedActivitiesMeta: residentProfile.completedActivitiesMeta,
    allActivities: residentProfile.allActivities,
    allActivitiesMeta: residentProfile.allActivitiesMeta,
    contactTypes: app.contactTypes,
    countries: getCountryOptions(state),
    currentLease: getCurrentLease(state),
    currentUser: getCurrentUser(state),
    deletedApplicants: residentProfile.deletedApplicants,
    financiallyResponsibleHousehold: getFinanciallyResponsibleResidents(state),
    financiallyResponsiblePendingApplicants:
      getFinanciallyResponsiblePendingApplicants(state),
    householdMembers: getHouseholdResidentMembers(state),
    houseHoldPendingMembers: getHouseholdPendingMembers(state),
    isCompliant: residentProfile.isCompliant,
    isFutureLease: getIsFutureLease(state),
    isRenewalComplete: isRenewalComplete(state),
    hasOpenTransfer: hasOpenTransferLease(state),
    hasOpenRenewal: hasOpenRenewalLease(state),
    housingSituations: getHousingSituationOptions(state),
    isResident: isCurrentResident(state),
    labelEndDate: getLabelEndDate(state),
    lateMethods: getLateMethods(state),
    leases: residentProfile.leases,
    leaseSignatureStatuses: leaseDataTab.leaseSignatureStatuses,
    leaseTerms: residentProfile.leaseTerms,
    softDeletedLeaseTerms: residentProfile.softDeletedLeaseTerms,
    ledgerBalance: residentProfile.ledgerBalance,
    locale: languageProvider.locale,
    monthlyTransactions: getMonthlyTransactions(state),
    monthlyTransactionsForProrateCalculator:
      getMonthlyTransactionsForProrateCalculator(state),
    monthsWithLateFees: residentProfile.monthsWithLateFees,
    monthsWithNSFFees: residentProfile.monthsWithNSFFees,
    moveOutReasons: getMoveOutReasonsOptions(
      state,
      DEPRECATED_MOVE_OUT_REASON_IDS,
    ),
    openFiscalPeriod: residentProfile.openFiscalPeriod,
    isFirstFiscalPeriod: residentProfile.isFirstFiscalPeriod,
    pendingActivities: residentProfile.pendingActivities,
    petBreeds: app.petBreeds,
    petTypes: app.petTypes,
    previousLeaseId: getPreviousLeaseId(state),
    propertyClassType: getSelectedPropertyClassType(state),
    prospectInfo: residentProfile.prospectInfo,
    reCompleteCertificationStarted: getRecompleteCertificationStatus(state),
    referralTypes: app.referralTypes,
    residentInfo: residentProfile.residentInfo,
    residents: getApprovedResidents(state),
    screening: residentProfile.screening,
    securityDeposits: getSecurityDeposits(state),
    selectedMonthlyOption: residentProfile.selectedMonthlyOption,
    selectedProperty: app.selectedProperty,
    specialNeedsDesignations: getSpecialNeedsDesignationOptions(state),
    states: getStateOptions(state),
    submittingRenewal: residentProfile.submittingRenewal,
    subsidyBalance: residentProfile.subsidyBalance,
    suffixes: getNameSuffixOptions(state),
    transferInformation: residentProfile.transferInformation,
    unit: residentProfile.unit,
    users: getProspectAssigneesOptions(state),
    affordableQualifications: residentProfile.affordableQualifications,
    affordableException: residentProfile.affordableException,
    affordableSetup: residentProfile.affordableSetup,
    householdAffordableDetails: residentProfile.householdAffordableDetails,
    recertDueDates: residentProfile.recertDueDates,
    firstUnitMoveInAfterPriorResident:
      residentProfile.firstUnitMoveInAfterPriorResident,
    screeningLetterTypes: getScreeningLetterTypes(state),
    camPools: residentProfile.camPools,
    moveInApplyFullAmountToProRate: pathOr(
      false,
      ['app', 'selectedProperty', 'moveInApplyFullAmountToProRate'],
      state,
    ),
    prorateMethods: app?.prorateMethods ?? [],
    moveOutProrateMethods: app?.moveOutProrateMethods ?? [],
    moveOutProratedRentMethodId:
      state?.app?.selectedProperty?.moveOutProrateMethodId,
    deprecated_moveOutProratedRentMethodId:
      state?.app?.selectedProperty?.deprecated_moveOutProrateMethodId,
    fpNonOptionalCharges: residentProfile?.fpNonOptionalCharges ?? [],
    fasComplete:
      parse(ownProps.location.search.replace('?', ''))?.fasComplete ?? false,
    socketFailed: residentProfile?.socketFailed,
    propertyPaymentProvider: app?.propertyPaymentMethod?.paymentProvider,
    propertyHasHUD: (app?.selectedProperty?.pap ?? []).some(
      (pap) => pap?.masterAffordableProgram?.name === 'HUD',
    ),
    liveInCaretakerOptions: getLiveInCaretakerOptions(state),
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...residentActions,
      ...prospectActions,
      ...applicationActions,
      change,
      assignApplication,
      getAllActivityTypes,
      getAllAffordableRelationships,
      getAllApplicantTypes,
      getAllRelationships,
      getAllApplicantCancellationReasons,
      getAllAssignees,
      getAllContactTypes,
      getAllCountries,
      getAllMoveOutReasons,
      getAllHousingSituations,
      getAllNameSuffixes,
      getAllPetBreeds,
      getAllPetTypes,
      getAllProspectStatus,
      getAllReferralTypes,
      getAllStates,
      getCustomerLedgerHeader,
      refreshLedger,
      getResidentHousehold,
      getSignatureStatuses,
      getAllDocuments,
      getAllProrateMethods,
      getAllMoveOutProrateMethods,
      getAllSpecialNeedsDesignations,
      selectProperty,
      submit,
      updateApplication,
      getLiveInCaretakerOptions,
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

const InjectedResidentProfile = injectIntl(ResidentProfile);
export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(InjectedResidentProfile),
);
