import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';

// Components
import {
  Table,
  TableHeader,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import AddNewVoucherButton from '../AddNewVoucherButton';

// Hooks
import useUniqueTableName from '../../../hooks/useUniqueTableName';

// Others
import HousingChoiceVoucherService from '../../../services/housingChoiceVoucherService';
import messages from '../messages';
import { HousingChoiceVoucher } from '../../App/types';

const useEditHousingChoiceVoucher = ({
  organizationId,
  propertyId,
  householdId,
  options,
}) => {
  const housingChoiceVoucherService = useMemo(
    () => new HousingChoiceVoucherService(),
    [],
  );
  const mutation = useMutation(
    ({ housingChoiceVoucherId, payload }) =>
      housingChoiceVoucherService.editVoucher(
        organizationId,
        propertyId,
        householdId,
        housingChoiceVoucherId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

const useDeleteHousingChoiceVoucher = ({
  organizationId,
  propertyId,
  housingChoiceVoucherId,
  options,
}) => {
  const housingChoiceVoucherService = useMemo(
    () => new HousingChoiceVoucherService(),
    [],
  );
  const mutation = useMutation(
    () =>
      housingChoiceVoucherService.deleteVoucher(
        organizationId,
        propertyId,
        housingChoiceVoucherId,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

type Props = {
  organizationId: string,
  propertyId: string,
  householdId: string,
  handleSortChange: Function,
  headers: Object[],
  intl: Object,
  isLoading: boolean,
  order: string,
  orderBy: string,
  housingChoiceVouchers: Array<HousingChoiceVoucher>,
};

const HousingChoiceVoucherTable = ({
  organizationId,
  propertyId,
  householdId,
  handleSortChange,
  headers,
  intl,
  isLoading,
  order,
  orderBy,
  housingChoiceVouchers = [],
}: Props) => {
  const editHousingChoiceVoucherMutation = useEditHousingChoiceVoucher({
    organizationId,
    propertyId,
    householdId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        // TODO: closeModal here
      },
      onError: (error) => {
        toastr.error('Error', error.message);
      },
    },
  });

  const deleteHousingChoiceVoucherMutation = useDeleteHousingChoiceVoucher({
    organizationId,
    propertyId,
    householdId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        // TODO: closeConfirmModal here
      },
      onError: (error) => {
        toastr.error('Error', error.message);
      },
    },
  });

  const name = useUniqueTableName('household-housing-choice-vouchers');
  const rows = useMemo(() => {
    return housingChoiceVouchers.map((row) => {
      const voucherSource = row.voucherSource;
      const voucherAmount = row.voucherAmount;
      const residentRent = row.residentRent;
      const voucherStartDate = row.voucherStartDate;
      const voucherEndDate = row.voucherEndDate;

      return {
        voucherSource: {
          variant: 'description',
          value: voucherSource,
        },
        voucherAmount: {
          variant: 'currency',
          value: voucherAmount,
        },
        residentRent: {
          variant: 'currency',
          value: residentRent,
        },
        voucherStartDate: {
          variant: 'date',
          value: voucherStartDate,
        },
        voucherEndDate: {
          variant: 'date',
          value: voucherEndDate || null,
        },
        actions: {
          variant: 'iconButtons',
          array: [
            {
              name: 'edit',
              iconName: 'PencilIcon',
              color: 'primary',
              onClick: () => {
                // TODO: add openModal case
                // editHousingChoiceVoucherMutation.mutate(
                //   row.id,
                //   'payload created with modal fields',
                // );
              },
            },
            {
              name: 'delete',
              iconName: 'TrashIcon',
              color: 'error',
              disabled: false,
              onClick: () => {
                // TODO: add openConfirmModal case
                // deleteHousingChoiceVoucherMutation.mutate(row.id);
              },
            },
          ],
        },
      };
    });
    // TODO: Add housing choice voucher read permissions if required
  }, [
    housingChoiceVouchers,
    deleteHousingChoiceVoucherMutation,
    editHousingChoiceVoucherMutation,
  ]);

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  return (
    <div data-testid="housing-choice-vouchers-table">
      <TableHeader
        title={<FormattedMessage {...messages.title} />}
        count={housingChoiceVouchers.length ?? 0}
        actions={<AddNewVoucherButton />}
      />
      <Table
        ReactBeautifulDnD={{
          DragDropContext,
          Droppable,
          Draggable,
        }}
        allColumnsHidden={allColumnsHidden}
        columnOptions={columnOptions}
        count={housingChoiceVouchers.length || 0}
        handleColumnChange={handleColumnChange}
        handleSortChange={handleSortChange}
        headers={filteredHeaders}
        height={500}
        intl={intl}
        isLoading={isLoading}
        name={name}
        order={order}
        orderBy={orderBy}
        rows={rows}
        selectedColumns={selectedColumns}
        showSettings={false}
      />
    </div>
  );
};

export default HousingChoiceVoucherTable;
