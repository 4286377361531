import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ModalConfirm } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  TableHeader,
  VirtualizedTable,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import TableLayout from '../../components/TableLayout';
import Summary from './Summary';
import UnitSpecials from './UnitSpecials';
import GoBackLinks from './GoBackLinks';
import useManageUnitAvailability from './hooks';
import messages from '../ManageUnitAvailability/messages';
import AffordableTransferBanner from './AffordableTransferBanner';

const NAME = 'manage-unit-availability';
const ManageUnitAvailability = () => {
  const {
    allColumnsHidden,
    applicantToAssignUnit,
    assignUnitProps,
    columnOptions,
    count,
    countName,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    handleTruncateChange,
    intl,
    isLoading,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    sortedAndFilteredResults,
    totalCount,
    UnitSpecialsProps,
    HUDTransferProps,
    isTransfer,
  } = useManageUnitAvailability({ NAME });

  return (
    <DocumentTitle
      data-testid={NAME}
      title={intl.formatMessage(messages.title)}
    >
      <>
        <TableLayout name={name}>
          {(tableHeight) => (
            <>
              <TableHeader
                id={'table-header'}
                title={<FormattedMessage {...messages.header} />}
                count={count}
                countName={countName}
                totalCount={totalCount}
                actions={
                  <GoBackLinks applicantToAssignUnit={applicantToAssignUnit} />
                }
              />
              <UnitSpecials intl={intl} {...UnitSpecialsProps} />

              <Summary
                name={name}
                isLoading={isLoading}
                sortedAndFilteredResults={sortedAndFilteredResults}
              />

              <AffordableTransferBanner
                intl={intl}
                show={
                  HUDTransferProps.hasMultipleHUDSubsidies &&
                  !HUDTransferProps.isLoading &&
                  isTransfer
                }
              />
              <VirtualizedTable
                ReactBeautifulDnD={{
                  DragDropContext,
                  Droppable,
                  Draggable,
                }}
                ReactRouterLink={ReactRouterLink}
                intl={intl}
                allColumnsHidden={allColumnsHidden}
                columnOptions={columnOptions}
                dateState={dateState}
                filterState={filterState}
                filterTypeState={filterTypeState}
                footerRow={footerRow}
                handleColumnChange={handleColumnChange}
                handleDateSubmit={handleDateSubmit}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSearchSubmit={handleSearchSubmit}
                handleSortChange={handleSortChange}
                handleTruncateChange={handleTruncateChange}
                headers={filteredHeaders}
                height={tableHeight}
                isLoading={isLoading}
                onCSVButtonClick={onCSVButtonClick}
                name={name}
                order={order}
                orderBy={orderBy}
                rows={rows}
                count={count}
                searchState={searchState}
                selectedColumns={selectedColumns}
                sx={{
                  paddingTop: 0,
                }}
              />
            </>
          )}
        </TableLayout>
        <ModalConfirm
          actionsProps={assignUnitProps.actionsProps}
          onClose={assignUnitProps.onClose}
          open={assignUnitProps.openConfirm}
          title={assignUnitProps.title}
        />
      </>
    </DocumentTitle>
  );
};

export default ManageUnitAvailability;
