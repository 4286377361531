import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { PlusIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from '../messages';

const AddNewVoucherButton = ({ disabled, intl, ...props }) => {
  return (
    <Button
      variant={'shout'}
      disabled={disabled}
      {...props}
      startIcon={<PlusIcon />}
    >
      <FormattedMessage {...messages.addNewVoucher} />
    </Button>
  );
};

export default AddNewVoucherButton;
