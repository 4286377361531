import DocumentTitle from 'react-document-title';
import DOMPurify from 'dompurify';
import { injectIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Redirect } from 'react-router-dom';
import { Prompt } from 'react-router-dom';
import {
  Box,
  Spinner,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { CentralizedDocumentForm } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useQuery } from 'react-query';
import { useContext, useMemo } from 'react';
import { AppContext } from '../App/context';
import UserService from '../../services/userService';
import useHasPermission from '../../hooks/useHasPermission';
import { get, post, apiURL } from '../../utils/api';
import { toastr } from 'react-redux-toastr';
import { useState } from 'react';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import { handlePreview } from '../AddDocument/utils';
import { CentralizedDocumentFormContainer } from '../AddDocument/';
import useExitPrompt from '../../hooks/useExitPrompt';

function EditDocument({ intl, history, match }) {
  const { documentManagementMvp } = useFlags();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { userOrganizationId, isLdUserContextReady } = useContext(AppContext);
  const isPageEnabled = documentManagementMvp && isLdUserContextReady;
  const mergeFieldTableName = useUniqueTableName(
    'edit-document-merge-field-inventory',
  );
  const documentId = match.params.documentId;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasEditPermission = useHasPermission('centralized-docs-edit');
  const { data: properties, isLoading: propertiesLoading } = useQuery(
    ['properties', userOrganizationId],
    () => {
      const service = new UserService();
      return service.getAllActiveProperties(userOrganizationId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const { data: documentCategories } = useQuery(
    ['documentCategories', userOrganizationId],
    () => {
      return get(`/${userOrganizationId}/document-categories`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
      initialData: [],
    },
  );
  const { data: document, isLoading: isDocumentLoading } = useQuery(
    ['document', userOrganizationId, documentId],
    () => {
      return get(`/${userOrganizationId}/centralized-documents/${documentId}`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const { data: mergeFields, isLoading: mergeFieldsLoading } = useQuery(
    ['mergeFields'],
    () => {
      return get('/universal-merge-field-configurations?hideFromUsers=false');
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const initialValues = useMemo(
    () => ({
      documentName: document?.name,
      documentCategoryId: document?.documentCategoryId,
      documentContent: document?.documentContent,
      assignedProperties: document?.propertyIds,
      isAddendumRequired: document?.leaseAddendum?.isRequired,
      unitTypes: document?.leaseAddendum?.unitTypes,
    }),
    [document],
  );

  useExitPrompt({
    active: isFormDirty && !submitted,
  });

  if (!isLdUserContextReady) return null;
  if (isPageEnabled === false) return <Redirect to="/404" />;

  if (isDocumentLoading || !document) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <DocumentTitle title="Edit Document">
      <CentralizedDocumentFormContainer>
        <Prompt
          when={isFormDirty && !submitted}
          message="Any unsaved changes will be lost."
        />
        <CentralizedDocumentForm
          onFormDirtyChange={(isDirty) => {
            setIsFormDirty(isDirty);
          }}
          intl={intl}
          initialValues={initialValues}
          properties={properties ?? []}
          propertiesLoading={propertiesLoading}
          documentCategories={documentCategories.map((c) => ({
            value: c.id,
            text: c.name,
          }))}
          onCancel={() => history.push('/manage-documents')}
          onSubmit={async (values) => {
            try {
              setIsSubmitting(true);

              await post(
                `/${userOrganizationId}/centralized-documents/${documentId}`,
                JSON.stringify({
                  documentContent: DOMPurify.sanitize(values.documentContent),
                  propertyIds: values.assignedProperties,
                  categoryDetails: {
                    isAddendumRequired: values.isAddendumRequired,
                    unitTypes: values.unitTypes ?? [],
                  },
                }),
              );
              toastr.success('Success', 'The document has been updated.');
              setSubmitted(true);
              history.push('/manage-documents');
            } catch {
              toastr.error(
                'Error',
                'The document changes were unsuccessful. Please try again.',
              );
              setIsSubmitting(false);
            }
          }}
          isSubmitting={isSubmitting}
          disabled={hasEditPermission !== true}
          MergeFieldInventoryProps={{
            intl,
            mergeFields,
            isLoading: mergeFieldsLoading,
            mergeFieldTableName,
          }}
          onPreview={(content) =>
            handlePreview({
              content,
              organizationId: userOrganizationId,
            })
          }
          ifwUrl={`${apiURL}/ckeditor/import-from-word`}
        />
      </CentralizedDocumentFormContainer>
    </DocumentTitle>
  );
}

export default injectIntl(EditDocument);
