import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';
import { Button, Row, Col } from 'react-bootstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FileSaver from 'file-saver';
import { toastr } from 'react-redux-toastr';
import { apiURL, requestToDownload } from '../../../../utils/api';
import useSelectedProperty from '../../../../hooks/useSelectedProperty';

import messages from './messages';
import { formatCurrency } from '../../../../utils/index';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import FormattedDateOrDashes from '../../../../components/FormattedDateOrDashes';

type Props = {
  intl: Object,
  reconciliationsLoading: boolean,
  reconciliations: Array<Object>,
};

const CamReconciliationHistory = ({
  intl,
  reconciliationsLoading,
  reconciliations,
}: Props) => {
  const { camReconciliationReport: camReconciliationReportFlag } = useFlags();
  const selectedProperty = useSelectedProperty();
  const [showTable, setShowTable] = useState(true);
  const hasReconciliations = reconciliations.length > 0;
  const hideShowMessage = showTable ? (
    <FormattedMessage {...messages.hide} />
  ) : (
    <FormattedMessage {...messages.show} />
  );

  return (
    <React.Fragment>
      <Row className="padtop10 padbottom10 padleft5">
        <Col xs={6}>
          <h2>
            <i className="et-clipboard text-blue padright10" />
            <FormattedMessage
              {...(camReconciliationReportFlag
                ? messages.camHistory
                : messages.reconciliationHistory)}
            />
          </h2>
        </Col>
        <Col xs={6}>
          <a
            className="pull-right padright10"
            onClick={() => setShowTable(!showTable)}
          >
            {hideShowMessage}
          </a>
        </Col>
      </Row>

      {!reconciliationsLoading &&
        showTable &&
        (hasReconciliations ? (
          <div className="table-scroll table-units-container">
            <table className="table table-fixed-headers table-prospects table-striped">
              <thead className="table-header">
                <tr>
                  <th>
                    <FormattedMessage
                      {...(camReconciliationReportFlag
                        ? messages.type
                        : messages.reconciliationType)}
                    />
                  </th>
                  <th>
                    <FormattedMessage {...messages.completedOn} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.completedBy} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.amount} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.report} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderReconciliationRows({
                  intl,
                  reconciliations,
                  camReconciliationReportFlag,
                  propertyId: selectedProperty.id,
                  organizationId: selectedProperty.organizationId,
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <Row className="padtop10 padbottom10 padleft20 font-bold">
            <FormattedMessage {...messages.noHistory} />
          </Row>
        ))}
    </React.Fragment>
  );
};

const downloadReport = async ({
  organizationId,
  propertyId,
  reconciliationId,
  type,
}) => {
  try {
    toastr.info('Pending', 'Downloading report...');

    const file = await requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/cam-reconciliations/${reconciliationId}/report`,
      {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('session_id'),
          'Content-Type': 'application/json',
        },
      },
    );

    FileSaver.saveAs(file, `${type} Reconciliation Report.pdf`);
  } catch (error) {
    let message = typeof error === 'string' ? error : error.toString();

    if (message.includes('[object Object]')) {
      message = 'Failed to download report.';
      console.error(error); // eslint-disable-line
    }

    toastr.error('Error', message);
  }
};

const renderReconciliationRows = ({
  intl,
  reconciliations,
  camReconciliationReportFlag,
  propertyId,
  organizationId,
}) => {
  return (
    reconciliations &&
    reconciliations.map((reconciliation, i) => {
      const reconciliationType = pathOr(
        null,
        ['camReconciliationType', 'name'],
        reconciliation,
      );
      const completedOn = pathOr(null, ['createdAt'], reconciliation);

      const firstName = pathOr(null, ['createdBy', 'lastName'], reconciliation);
      const lastName = pathOr(null, ['createdBy', 'firstName'], reconciliation);
      const fullName = `${firstName} ${lastName}`;
      const completedBy = fullName === ' ' ? null : fullName;

      const amount = pathOr(null, ['amount'], reconciliation);
      const formattedAmount =
        amount === 0 ? '$0' : formatCurrency(intl, +amount);
      const displayAmount = amount !== null ? formattedAmount : '---';
      const { id, reportId } = reconciliation;

      return (
        <tr key={i}>
          <td>
            <DashesIfNullOrUndefined data={reconciliationType} />
          </td>
          <td>
            <FormattedDateOrDashes
              value={completedOn}
              format="YYYY/MM/DD hh:mm:ss A"
            />
          </td>
          <td>
            <DashesIfNullOrUndefined data={completedBy} />
          </td>
          <td>{displayAmount}</td>
          <td>
            <Button
              id="download-button"
              bsStyle="default"
              bsSize="small"
              onClick={() => {
                downloadReport({
                  reconciliationId: id,
                  type: reconciliationType,
                  propertyId,
                  organizationId,
                });
              }}
              disabled={!camReconciliationReportFlag || !reportId}
            >
              <i className="icon et-download" />
            </Button>
          </td>
        </tr>
      );
    })
  );
};

export default CamReconciliationHistory;
