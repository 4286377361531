import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.HousingChoiceVouchers.Title',
    defaultMessage: 'Housing Choice Voucher',
  },
  voucherSource: {
    id: 'App.HousingChoiceVouchers.VoucherSource',
    defaultMessage: 'Voucher Source',
  },
  voucherAmount: {
    id: 'App.HousingChoiceVouchers.VoucherAmount',
    defaultMessage: 'Voucher Amount',
  },
  residentRent: {
    id: 'App.HousingChoiceVouchers.ResidentRent',
    defaultMessage: 'Resident Rent',
  },
  voucherStartDate: {
    id: 'App.HousingChoiceVouchers.VoucherStartDate',
    defaultMessage: 'Voucher Start Date',
  },
  voucherEndDate: {
    id: 'App.HousingChoiceVouchers.VoucherEndDate',
    defaultMessage: 'Voucher End Date',
  },
  actions: {
    id: 'App.HousingChoiceVouchers.Actions',
    defaultMessage: 'Actions',
  },
  addNewVoucher: {
    id: 'App.HousingChoiceVouchers.AddNewVoucher',
    defaultMessage: 'Add New Voucher',
  },
});

export default messages;
