import { isEmpty, isNil } from 'ramda';

const {
  apiURL,
  requestToDownload,
  get,
  post,
  getDownload,
} = require('../utils/api');

const request = require('../utils/api').default;

const reportingServiceAPIURL = process.env.REACT_APP_REPORTING_SERVICE_API_URL;
export default class ReportService {
  createCentralizedReport(
    {
      dateRangeStart,
      dateRangeEnd,
      organizationId,
      userId: requestedBy,
      username: requestedByUsername,
      fortressReportId,
    },
    options,
  ) {
    return post(
      `${reportingServiceAPIURL}/reports`,
      JSON.stringify({
        dateRangeStart: dateRangeStart.format('YYYY-MM-DD'),
        dateRangeEnd: dateRangeEnd.format('YYYY-MM-DD'),
        requestedBy,
        requestedByUsername,
        fortressReportId,
        organizationId,
      }),
      { ...options, isOtherApi: true },
    );
  }
  getCentralizedReports({ organizationId, options }) {
    return get(`/${organizationId}/reports`, options);
  }
  getCentralizedReportStatus({ organizationId, options }) {
    return get(`${reportingServiceAPIURL}/reports/${organizationId}/latest`, {
      ...options,
      isOtherApi: true,
    });
  }
  downloadCentralizedReport(
    organizationId: string,
    reportId: string,
    filename: string,
  ) {
    return getDownload(`/${organizationId}/reports/${reportId}`, filename);
  }
  getCentralizedHistoricalReports({ organizationId, reportId, options }) {
    return get(`/${organizationId}/reports/historical/${reportId}`, options);
  }
  getAllReports(
    organizationId: string,
    propertyId: string,
    sorting?: any,
    searchText: ?string,
    optionArgs?: Object,
  ) {
    const defaultOptions = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const options = { ...defaultOptions, ...optionArgs };
    const sort =
      sorting && sorting.fieldName === ''
        ? ''
        : // $FlowFixMe
          `sort=${sorting.fieldName}&order=${sorting.order}`;
    const query =
      isNil(searchText) || searchText === '' ? '' : `&query=${searchText}`;
    const queryString = `?${sort}${query}`;

    return request(
      `${apiURL}/${organizationId}/${propertyId}/reports${queryString}`,
      options,
    );
  }
  getHistoricalReports({
    organizationId,
    propertyId,
    dateTo,
    dateFrom,
    reportId,
  }: Object) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const filterDates = `dateTo=${dateTo}&dateFrom=${dateFrom}`;
    const queryString = `?${filterDates}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/reports/historical/${reportId}${queryString}`,
      options,
    );
  }

  downloadCSVReport(
    organizationId: string,
    propertyId: string,
    reportId: string,
    startDate: string,
    endDate: string,
    period: string,
    date: string,
    startPeriod: string,
    endPeriod: string,
    scheduledPeriod: string,
    actualPeriod: string,
    currentPeriod: string,
    futurePeriod: string,
    customDateField: string,
    leaseType: string,
    query = '',
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    const periodQuery = `?period=${period}`;
    const rangeQuery = `?startDate=${startDate}&endDate=${endDate}`;
    const periodRangeQuery = `?startPeriod=${startPeriod}&endPeriod=${endPeriod}`;
    const dateQuery = `?date=${date}`;
    const scheduledActualPeriodQuery = `?scheduledPeriod=${scheduledPeriod}&actualPeriod=${actualPeriod}`;
    const currentAndFuturePeriodQuery = `?currentPeriod=${currentPeriod}&futurePeriod=${futurePeriod}`;

    let finalQuery = '';
    if (!isNil(period)) {
      finalQuery = periodQuery;
    } else if (!isNil(startDate)) {
      finalQuery =
        rangeQuery +
        (isNil(customDateField) ? '' : `&customDateField=${customDateField}`);
    } else if (!isNil(date)) {
      finalQuery = dateQuery;
    } else if (!isNil(startPeriod)) {
      finalQuery = periodRangeQuery;
    } else if (!isNil(scheduledPeriod)) {
      finalQuery = scheduledActualPeriodQuery;
    } else if (!isNil(futurePeriod)) {
      finalQuery = currentAndFuturePeriodQuery;
    }

    if (leaseType) finalQuery += `&leaseType=${leaseType}`;

    if (query) {
      finalQuery += finalQuery ? `&${query}` : `?${query}`;
    }

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/csv-reports/${reportId}${finalQuery}`,
      options,
    );
  }

  downloadPDFReport(
    organizationId: string,
    propertyId: string,
    reportId: string,
    startDate: string,
    endDate: string,
    period: string,
    date: string,
    startPeriod: string,
    endPeriod: string,
    scheduledPeriod: string,
    actualPeriod: string,
    currentPeriod: string,
    futurePeriod: string,
    customDateField: string,
    leaseType: string,
    query = '',
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    const periodQuery = `?period=${period}`;
    const rangeQuery = `?startDate=${startDate}&endDate=${endDate}`;
    const periodRangeQuery = `?startPeriod=${startPeriod}&endPeriod=${endPeriod}`;
    const scheduledActualPeriodQuery = `?scheduledPeriod=${scheduledPeriod}&actualPeriod=${actualPeriod}`;
    const currentAndFuturePeriodQuery = `?currentPeriod=${currentPeriod}&futurePeriod=${futurePeriod}`;
    const dateQuery = `?date=${date}`;

    let finalQuery = '';
    if (!isNil(period)) {
      finalQuery = periodQuery;
    } else if (!isNil(startDate)) {
      finalQuery =
        rangeQuery +
        (isNil(customDateField) ? '' : `&customDateField=${customDateField}`);
    } else if (!isNil(startPeriod)) {
      finalQuery = periodRangeQuery;
    } else if (!isNil(scheduledPeriod)) {
      finalQuery = scheduledActualPeriodQuery;
    } else if (!isNil(futurePeriod)) {
      finalQuery = currentAndFuturePeriodQuery;
    } else if (!isNil(date)) {
      finalQuery = dateQuery;
    }

    if (leaseType) finalQuery += `&leaseType=${leaseType}`;
    if (query) {
      finalQuery += finalQuery ? `&${query}` : `?${query}`;
    }
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/pdf-reports/${reportId}${finalQuery}`,
      options,
    );
  }
  download(organizationId: string, reportId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/reports/${reportId}/download-last-created`,
      options,
    );
  }

  downloadAffordableStateReport(
    organizationId: string,
    propertyId: string,
    id: ?string,
    startDate: string,
    endDate: string,
    period: ?string,
    date: ?string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    const finalQuery = `?startDate=${startDate}&endDate=${endDate}`;
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/affordable-state-reports${finalQuery}`,
      options,
    );
  }

  downloadMINCTenantTransactionsReport(
    organizationId: string,
    propertyId: string,
    id: ?string,
    startDate: string,
    endDate: string,
    period: ?string,
    date: ?string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    const finalQuery = `?startDate=${startDate}&endDate=${endDate}`;
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/minc-tenant-transactions${finalQuery}`,
      options,
    );
  }

  downloadScheduleAReport(
    organizationId: string,
    propertyId: string,
    reportId: string,
    startDate: string,
    endDate: string,
    period: string,
    date: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/schedule-A-report?date=${date}`,
      options,
    );
  }

  uploadReport(
    organizationId: string,
    propertyId: string,
    file: any,
    reportId: string,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body: JSON.stringify(file),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/reports/${reportId}`,
      options,
    );
  }
  downloadPreviousReport(
    organizationId: string,
    reportId: string,
    propertyId: string,
    reportHistoryId: string,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/reports/${reportId}/download-previous/${reportHistoryId}`,
      options,
    );
  }

  downloadZipFileForMonthEndClose(
    organizationId: string,
    propertyId: string,
    fileType: string,
    period: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/ame-reports?fileType=${fileType}&period=${period}`,
      options,
    );
  }

  downloadComplianceOverviewReport(
    organizationId: string,
    complianceType: string,
    includeNotStartedCerts: boolean,
    fileType: string,
    searchText?: string,
    filters?: Object,
    sorting?: Object,
  ) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    const includeNotStarted = includeNotStartedCerts
      ? '&includeNotStartedCerts=true'
      : '';
    const queryString = new URLSearchParams({
      ...(!isEmpty(searchText ?? '') && { searchText }),
      ...(!isEmpty(filters) && { filter: JSON.stringify(filters) }),
      ...(!isEmpty(sorting) && {
        sort_by: sorting.fieldName,
        order_by: sorting.order,
      }),
      timeZone,
    }).toString();

    return requestToDownload(
      // eslint-disable-next-line max-len
      `${apiURL}/${organizationId}/compliance-overview/report/${fileType}?complianceType=${complianceType}${includeNotStarted}${
        !isEmpty(queryString) ? `&${queryString}` : ''
      }`,
      options,
    );
  }
}
