import { post, get, put, del } from '../utils/api';

type HousingChoiceVoucher = {
  voucherSource: string,
  voucherAmount: number,
  voucherStartDate: Date,
  voucherEndDate: Date,
};

/**
 * Will manage CRUD operations for Housing Choice Vouchers
 */
export default class HousingChoiceVoucherService {
  createVoucher(
    organizationId: string,
    propertyId: string,
    householdId: string,
    payload: HousingChoiceVoucher,
    options?: Object,
  ): any {
    return post(
      `/${organizationId}/${propertyId}/households/${householdId}/recert-due-dates`,
      JSON.stringify(payload),
      options,
    );
  }

  getVoucherById(
    organizationId: string,
    propertyId: string,
    housingChoiceVoucherId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/housing-choice-vouchers/${housingChoiceVoucherId}`,
      options,
    );
  }

  getAllVouchersByHouseholdId(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/${householdId}/housing-choice-vouchers`,
      options,
    );
  }

  editVoucher(
    organizationId: string,
    propertyId: string,
    householdId: string,
    housingChoiceVoucherId: string,
    payload: HousingChoiceVoucher,
    options?: Object,
  ): any {
    return put(
      `/${organizationId}/${propertyId}/${householdId}/housing-choice-vouchers/${housingChoiceVoucherId}`,
      JSON.stringify(payload),
      options,
    );
  }

  deleteVoucher(
    organizationId: string,
    propertyId: string,
    housingChoiceVoucherId: string,
    options?: Object,
  ): any {
    return del(
      `/${organizationId}/${propertyId}/housing-choice-vouchers/${housingChoiceVoucherId}`,
      options,
    );
  }
}
