import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const HEADERS = [
  {
    id: 'voucherSource',
    label: <FormattedMessage {...messages.voucherSource} />,
    dataType: 'string',
    sortable: true,
  },
  {
    id: 'voucherAmount',
    label: <FormattedMessage {...messages.voucherAmount} />,
    dataType: 'string',
    sortable: true,
  },
  {
    id: 'residentRent',
    label: <FormattedMessage {...messages.residentRent} />,
    dataType: 'string',
    sortable: true,
  },
  {
    id: 'voucherStartDate',
    label: <FormattedMessage {...messages.voucherStartDate} />,
    dataType: 'date',
    sortable: true,
  },
  {
    id: 'voucherEndDate',
    label: <FormattedMessage {...messages.voucherEndDate} />,
    dataType: 'date',
    sortable: true,
  },
  {
    id: 'voucherEndDate',
    label: <FormattedMessage {...messages.voucherEndDate} />,
    dataType: 'date',
    sortable: true,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...messages.actions} />,
  },
];
