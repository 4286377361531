import React, { useCallback, useEffect, useMemo, useState } from 'react';

import HousingChoiceVoucherTable from './HousingChoiceVoucherTable';

import HousingChoiceVoucherService from '../../services/housingChoiceVoucherService';

import { HEADERS } from './constants';

type Props = {
  organizationId: string,
  propertyId: string,
  householdId: string,
  intl: Object,
};

const SortType = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function HousingChoiceVoucherTab({
  organizationId,
  propertyId,
  householdId,
  intl,
}: Props) {
  const service = useMemo(() => new HousingChoiceVoucherService(), []);
  const [housingChoiceVouchersResponse, setHousingChoiceVouchersResponse] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(SortType.DESC);
  const [orderBy, setOrderBy] = useState('createdAt');

  const fetchHousingChoiceVouchers = useCallback(async () => {
    if (householdId) {
      try {
        setIsLoading(true);
        const response = await service.getAllVouchersByHouseholdId(
          organizationId,
          propertyId,
          householdId,
        );
        setHousingChoiceVouchersResponse(response);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
  }, [organizationId, propertyId, householdId, service]);

  const handleSort = (id: string, order?: typeof SortType) => {
    setOrder(order);
    setOrderBy(id);
  };

  useEffect(() => {
    fetchHousingChoiceVouchers();
  }, [orderBy, order, fetchHousingChoiceVouchers]);

  return (
    <HousingChoiceVoucherTable
      organizationId={organizationId}
      propertyId={propertyId}
      householdId={householdId}
      isLoading={isLoading}
      headers={HEADERS}
      intl={intl}
      housingChoiceVouchers={housingChoiceVouchersResponse}
      handleSortChange={handleSort}
      orderBy={orderBy}
      order={order}
    />
  );
}

export default HousingChoiceVoucherTab;
