export const HUD_PROGRAM_NAME = 'HUD';
export const HOME_PROGRAM_NAME = 'HOME';
export const CITC_PROGRAM_NAME = 'CITC';
export const RD_PROGRAM_NAME = 'RD';
export const LIHTC_PROGRAM_NAME = 'LIHTC';
export const LIHTC_HOME_PROGRAM_NAME = 'LIHTC/HOME';
export const RECERT_CERT_TYPE = 'RECERT';
export const RECERT_UT_CERT_TYPE = 'RECERT_UT';
export const INITIAL_CERT_TYPE = 'INITIAL';
export const INITIAL_UT_CERT_TYPE = 'INITIAL_UT';
export const MOVE_IN_CERT_TYPE = 'MOVE_IN';
export const MOVE_OUT_CERT_TYPE = 'MOVE_OUT';
export const TERMINATION_CERT_TYPE = 'TERMINATION';
export const INTERIM_CERT_TYPE = 'INTERIM';
export const INTERIM_UT_CERT_TYPE = 'INTERIM_UT';
export const EVICTION_CERT_TYPE = 'EVICTION';
export const CO_TENANT_TO_TENANT_CERT_TYPE = 'CO_TENANT_TO_TENANT';
export const INITIATE_ABSENCE_CERT_TYPE = 'INITIATE_ABSENCE';
export const TERMINATE_ABSENCE_CERT_TYPE = 'TERMINATE_ABSENCE';
export const VACATE_CERT_TYPE = 'VACATE';
export const ASSIGN_REMOVE_RA_CERT_TYPE = 'ASSIGN_REMOVE_RA';
export const GROSS_RENT_CERT_TYPE = 'GROSS_RENT';
export const UNIT_TRANSFER_CERT_TYPE = 'UNIT_TRANSFER';
export const GUARANTOR_LEASE_SIGNER = 'Guarantor Lease Signer';
export const MINOR = 'Minor';
export const CODES_FOR_811 = ['6', '8'];
export const certificationTypeNameDefinitions = {
  CO_TENANT_TO_TENANT: 'Co-Tenant to Tenant (C)',
  CORRECTION: 'Correction for Recert',
  EVICTION: 'Eviction (E)',
  INITIAL: 'Initial Certification (IC)',
  INITIATE_ABSENCE: 'Initiate Absence (L)',
  INTERIM: 'Interim Recert (IR)',
  MODIFY: 'Modified Cert (M)',
  MOVE_IN: 'Move-in (MI)',
  MOVE_IN_RD: 'Initial (I)',
  MOVE_OUT: 'Move-out (MO)',
  RECERT: 'Annual Recert (AR)',
  RECERT_RD: 'Recertification (R)',
  TERMINATION: 'Termination (TM)',
  TERMINATE_ABSENCE: 'Terminate Absence (B)',
  VACATE: 'Vacate (V)',
  ASSIGN_REMOVE_RA: 'Assign/Remove RA (A)',
  HUD_UNIT_TRANSFER: 'Unit Transfer (UT) 59A',
  UNIT_TRANSFER: 'Unit Transfer (UT)',
};
export const certStatusNameMap = {
  REOPEN: 'REOPEN',
};
export const certificationTypeNameMap = {
  LIHTC: {
    MOVE_IN: certificationTypeNameDefinitions.MOVE_IN,
    RECERT: certificationTypeNameDefinitions.RECERT,
    INITIAL: certificationTypeNameDefinitions.INITIAL,
    INTERIM: certificationTypeNameDefinitions.INTERIM,
    CORRECTION: certificationTypeNameDefinitions.CORRECTION,
    TERMINATION: certificationTypeNameDefinitions.TERMINATION,
    MOVE_OUT: certificationTypeNameDefinitions.MOVE_OUT,
  },
  // HOME uses the same certification types as LITHC
  HOME: {
    MOVE_IN: certificationTypeNameDefinitions.MOVE_IN,
    RECERT: certificationTypeNameDefinitions.RECERT,
    INITIAL: certificationTypeNameDefinitions.INITIAL,
    INTERIM: certificationTypeNameDefinitions.INTERIM,
    CORRECTION: certificationTypeNameDefinitions.CORRECTION,
    TERMINATION: certificationTypeNameDefinitions.TERMINATION,
    MOVE_OUT: certificationTypeNameDefinitions.MOVE_OUT,
  },
  // LIHTC/HOME is basically LIHTC
  'LIHTC/HOME': {
    MOVE_IN: certificationTypeNameDefinitions.MOVE_IN,
    RECERT: certificationTypeNameDefinitions.RECERT,
    INITIAL: certificationTypeNameDefinitions.INITIAL,
    INTERIM: certificationTypeNameDefinitions.INTERIM,
    CORRECTION: certificationTypeNameDefinitions.CORRECTION,
    TERMINATION: certificationTypeNameDefinitions.TERMINATION,
    MOVE_OUT: certificationTypeNameDefinitions.MOVE_OUT,
  },
  // CITC uses the same certification types as LITHC
  CITC: {
    MOVE_IN: certificationTypeNameDefinitions.MOVE_IN,
    RECERT: certificationTypeNameDefinitions.RECERT,
    INITIAL: certificationTypeNameDefinitions.INITIAL,
    INTERIM: certificationTypeNameDefinitions.INTERIM,
    CORRECTION: certificationTypeNameDefinitions.CORRECTION,
    TERMINATION: certificationTypeNameDefinitions.TERMINATION,
    MOVE_OUT: certificationTypeNameDefinitions.MOVE_OUT,
  },
  HUD: {
    MOVE_IN: certificationTypeNameDefinitions.MOVE_IN,
    RECERT: certificationTypeNameDefinitions.RECERT,
    RECERT_UT: certificationTypeNameDefinitions.RECERT,
    INITIAL: certificationTypeNameDefinitions.INITIAL,
    INITIAL_UT: certificationTypeNameDefinitions.INITIAL,
    INTERIM: certificationTypeNameDefinitions.INTERIM,
    INTERIM_UT: certificationTypeNameDefinitions.INTERIM,
    CORRECTION: certificationTypeNameDefinitions.CORRECTION,
    TERMINATION: certificationTypeNameDefinitions.TERMINATION,
    MOVE_OUT: certificationTypeNameDefinitions.MOVE_OUT,
    UNIT_TRANSFER: certificationTypeNameDefinitions.HUD_UNIT_TRANSFER,
  },
  RD: {
    CO_TENANT_TO_TENANT: certificationTypeNameDefinitions.CO_TENANT_TO_TENANT,
    EVICTION: certificationTypeNameDefinitions.EVICTION,
    MOVE_IN: certificationTypeNameDefinitions.MOVE_IN_RD, // RD uses Initial for Move In
    INITIATE_ABSENCE: certificationTypeNameDefinitions.INITIATE_ABSENCE,
    RECERT: certificationTypeNameDefinitions.RECERT_RD,
    TERMINATE_ABSENCE: certificationTypeNameDefinitions.TERMINATE_ABSENCE,
    MODIFY: certificationTypeNameDefinitions.MODIFY,
    VACATE: certificationTypeNameDefinitions.VACATE,
    ASSIGN_REMOVE_RA: certificationTypeNameDefinitions.ASSIGN_REMOVE_RA,
  },
};
export const GR_SIGNATURE_REQUIRED_CODE = '8';
export const TRACS_CERT_TYPES = [
  'INITIAL',
  'MOVE_IN',
  'RECERT',
  'MOVE_OUT',
  'TERMINATION',
  'GROSS_RENT',
  'INTERIM',
  'UNIT_TRANSFER',
  'RECERT_UT',
  'INTERIM_UT',
  'INITIAL_UT',
];

export const TRANSFER_CERTIFICATION_TYPES = [
  UNIT_TRANSFER_CERT_TYPE,
  RECERT_UT_CERT_TYPE,
  INTERIM_UT_CERT_TYPE,
  INITIAL_UT_CERT_TYPE,
];
