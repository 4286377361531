import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ApplicationProfile.Title',
    defaultMessage: 'Fortress - Application profile',
  },
  activityTab: {
    id: 'App.ApplicationProfile.ActivityTab',
    defaultMessage: 'Activity',
  },
  documentsTab: {
    id: 'App.ApplicationProfile.DocumentsTab',
    defaultMessage: 'Documents',
  },
  applicationTab: {
    id: 'App.ApplicationProfile.ApplicationTab',
    defaultMessage: 'Lease App',
  },
  leaseDataTab: {
    id: 'App.ApplicationProfile.LeaseDataTab',
    defaultMessage: 'Lease Data',
  },
  housingChoiceVoucherTab: {
    id: 'App.ApplicationProfile.HousingChoiceVoucherTab',
    defaultMessage: 'Housing Choice Voucher',
  },
  qualificationTab: {
    id: 'App.ApplicationProfile.QualificationTab',
    defaultMessage: 'Qualification',
  },
  ledgerTab: {
    id: 'App.ApplicationProfile.ledgerTab',
    defaultMessage: 'Ledger',
  },
  successHeader: {
    id: 'App.ApplicationProfile.SuccessHeader',
    defaultMessage: 'Success',
  },
  successHeaderDeleteActivity: {
    id: 'App.ApplicationProfile.successHeaderDeleteActivity',
    defaultMessage: 'Success',
  },
  successHeaderApplication: {
    id: 'App.ApplicationProfile.successHeaderApplication',
    defaultMessage: 'Success',
  },
  successDescriptionApplication: {
    id: 'App.ApplicationProfile.successDescriptionApplication',
    defaultMessage: 'The application was updated.',
  },
  successDescriptionApplicant: {
    id: 'App.ApplicationProfile.successDescriptionApplicant',
    defaultMessage: 'The applicant was updated.',
  },
  successDescriptionQualifcation: {
    id: 'App.ApplicationProfile.successDescriptionQualification',
    defaultMessage: 'Qualification information has been updated.',
  },
  successDescriptionAssignee: {
    id: 'App.ApplicationProfile.successDescriptionAssignee',
    defaultMessage: 'The assignee was updated.',
  },
  successDescriptionNotes: {
    id: 'App.ApplicationProfile.successDescriptionNotes',
    defaultMessage: 'The note was saved.',
  },
  errorHeader: {
    id: 'App.ApplicationProfile.ErrorHeader',
    defaultMessage: 'Error',
  },
  chooseOption: {
    id: 'App.ApplicationProfile.chooseOption',
    defaultMessage: 'Choose an option',
  },
  prospectInformation: {
    id: 'App.ApplicationProfile.PeopleTab',
    defaultMessage: 'Prospect Information',
  },
  cancelConfirmation: {
    id: 'App.ApplicationProfile.CancelConfirmation',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  finalDecision: {
    id: 'App.ApplicationProfile.FinalDecision',
    defaultMessage: 'Final Screening decision saved.',
  },
  successLeaseSaved: {
    id: 'App.ApplicationProfile.successLeaseSaved',
    defaultMessage: 'Lease saved successfully.',
  },
  scheduledMoveInDateUpdatedInPortal: {
    id: 'App.ApplicationProfile.scheduledMoveInDateUpdatedInPortal',
    defaultMessage: 'Scheduled Move-In date has been updated in Portal',
  },
  successLeaseRenewalComplete: {
    id: 'App.ApplicationProfile.successLeaseRenewalComplete',
    defaultMessage: 'Lease Renewal Completed.',
  },
  successLeaseSavedTitle: {
    id: 'App.ApplicationProfile.successLeaseSavedTitle',
    defaultMessage: 'Success',
  },
  unitUnassigned: {
    id: 'App.ApplicationProfile.UnitUnassigned',
    defaultMessage: 'Success: Unit Unassigned.',
  },
  successMonthlyChargeSaved: {
    id: 'App.ApplicationProfile.successMonthlyChargeSaved',
    defaultMessage: 'Monthly Transaction saved successfully.',
  },
  successMonthlyChargeTitle: {
    id: 'App.ApplicationProfile.successMonthlyChargeTitle',
    defaultMessage: 'Success',
  },
  successMonthlyChargeDeleted: {
    id: 'App.ApplicationProfile.successMonthlyChargeDeleted',
    defaultMessage: 'Monthly Transaction deleted successfully.',
  },
  successMonthlyChargeEndedStopped: {
    id: 'App.ApplicationProfile.successMonthlyChargeEndedStopped',
    defaultMessage: 'Monthly Transaction successfully ended/stopped.',
  },
  successMonthlyChargeDeletedTitle: {
    id: 'App.ApplicationProfile.successMonthlyChargeDeletedTitle',
    defaultMessage: 'Success',
  },
  convertClass: {
    id: 'App.ApplicationProfile.convertClass',
    defaultMessage:
      'Are you sure you want to change the Unit Type between Conventional and Affordable?',
  },
  arsSuccessNotification: {
    id: 'App.Socket.ARS.notification.success',
    defaultMessage: 'Applicant Refund Statement download ready',
  },
  arsErrorNotification: {
    id: 'App.Socket.ARS.notification.error',
    defaultMessage:
      'An error occurred while generating the Applicant Refund Statement',
  },
  camTab: {
    id: 'App.ApplicationProfile.CamTab',
    defaultMessage: 'CAM',
  },
  generateAdverseActionLetter: {
    id: 'App.ApplicationProfile.GenerateAdverseActionLetter',
    defaultMessage: 'Adverse Action Letter generated successfully',
  },
  documentErrorHeader: {
    id: 'App.ApplicationProfile.DocumentErrorHeader',
    defaultMessage: 'Error generating screening record',
  },
  pendingDownload: {
    id: 'App.ApplicationProfile.PendingDownload',
    defaultMessage: 'Downloading Lease',
  },
  pendingDownloadHeader: {
    id: 'App.ApplicationProfile.PendingDownloadHeader',
    defaultMessage: 'Pending',
  },
  texting: {
    id: 'App.ApplicationProfile.Texting',
    defaultMessage: 'Texting',
  },
});

export default messages;
