import React, { ChangeEvent, FunctionComponent } from 'react';
import DocumentTitle from 'react-document-title';
import messages from './messages';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { BuildingsTable } from './BuildingsTable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { promptToaster } from '../App/actions';
import {
  useAsyncBuildings,
  useAsyncStates,
  useAsyncPropertyConfigs,
} from './hooks';
import useIsAffordableProperty from '../../hooks/useIsAffordableProperty';
import type { GlobalState } from '../App/types';
import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import useHasPermission from '../../hooks/useHasPermission';

type Props = {
  selectedProperty: Object,
  intl: Object,
  actions: Object,
};

const ManageBuildings = ({
  selectedProperty,
  intl,
  actions,
  flags,
}: Props): FunctionComponent<Props> => {
  const { buildings, updateBuilding } = useAsyncBuildings(selectedProperty);
  const { states } = useAsyncStates();
  const { singleBuildingElection, updateSingleBuildingElection } =
    useAsyncPropertyConfigs(selectedProperty, actions.promptToaster, intl);

  const onSingleBuildingElectionChange = (value: boolean) => {
    updateSingleBuildingElection(value);
  };

  const isAffordableProperty = useIsAffordableProperty(selectedProperty);

  const canEditFloorplan = useHasPermission('floor-plan-update');

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <Grid className="bodywrap" fluid>
        <Row>
          <Col xs={12}>
            <Row className="section-header">
              <Col xs={10}>
                <h1>
                  <FormattedMessage {...messages.header} />
                </h1>
              </Col>
              {isAffordableProperty && (
                <Col xs={2}>
                  <Checkbox
                    disabled={!canEditFloorplan}
                    name="singleBuildingElection"
                    label={intl.formatMessage(messages.singleBuildingElection)}
                    checked={singleBuildingElection}
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                      checked: boolean,
                    ) => {
                      onSingleBuildingElectionChange(checked);
                    }}
                    formControlLabelProps={{
                      sx: {
                        float: 'right',
                        marginRight: 0,
                      },
                    }}
                  />
                </Col>
              )}
            </Row>
            <BuildingsTable
              buildings={buildings}
              updateBuilding={updateBuilding}
              states={states}
              selectedProperty={selectedProperty}
              promptToaster={actions.promptToaster}
              intl={intl}
            />
          </Col>
        </Row>
      </Grid>
    </DocumentTitle>
  );
};

const mapStateToProps = ({ app }: GlobalState): Object => {
  const { selectedProperty } = app;
  return { selectedProperty };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  actions: bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  ),
});
const connectedForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageBuildings));

export default withLDConsumer()(connectedForm);
