import { FormattedMessage } from 'react-intl';

// Components
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { SetupSwitch, SetupValue } from '../shared';
import ProgramPassbookRate from '../ProgramPassbookRate';
import SetupGridItem from '../../../SetupGridItem';
import type { PassbookRate } from '../shared';

// Constants
import messages from './messages';
import messagesAffordableSetup from '../../messages';

import { stripOnlyWhiteSpaceToNull } from '../../utils';

interface LIHTCflags {
  initialCertsForLihtc: boolean;
  hasHUDProgramOnly: boolean;
  rolloverIncomeAndAssets: boolean;
}

interface LIHTCValues {
  stateId: string;
  projectIdTic: string;
  XMLPropertyName: string;
  XMLProjectName: string;
  requiresInitialCertificationForNonHUD: boolean;
  isOverrideQualificationEnabled: boolean;
  autoRolloverIncomeAndAssets: boolean;
  flags: LIHTCflags;
  passbookRates: Array<PassbookRate>;
}

type Props = {
  promptToaster: Function,
  isLoadingPassbookRateData: boolean,
  editMode: boolean,
  onChange: Function,
  values: LIHTCValues,
};

const LIHTCSetup = ({
  editMode,
  onChange,
  values,
  promptToaster,
  isLoadingPassbookRateData,
}: Props) => {
  const {
    stateId,
    projectIdTic,
    XMLPropertyName,
    XMLProjectName,
    requiresInitialCertificationForNonHUD,
    isOverrideQualificationEnabled,
    autoRolloverIncomeAndAssets,
    flags: { initialCertsForLihtc, hasHUDProgramOnly, rolloverIncomeAndAssets },
    passbookRates,
  } = values;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h3'}>
          <FormattedMessage {...messages.title} />
        </Typography>
      </Grid>

      <SetupGridItem
        label={<FormattedMessage {...messages.stateProjectIdXml} />}
      >
        <SetupValue
          editMode={editMode}
          name="setup.stateId"
          value={stateId}
          placeholder="i.e. AR012-345"
          onChange={onChange}
          required
        />
      </SetupGridItem>
      {!hasHUDProgramOnly && initialCertsForLihtc && (
        <SetupGridItem
          label={<FormattedMessage {...messages.initialCertForNonHUD} />}
          TooltipProps={{
            title: <FormattedMessage {...messages.initialCertForNonHUDTip} />,
          }}
        >
          <SetupSwitch
            switchId={'initial-cert-for-non-HUD-switch'}
            editMode={editMode}
            name="setup.requiresInitialCertificationForNonHUD"
            disabled={!editMode}
            value={requiresInitialCertificationForNonHUD}
            onChange={onChange}
          />
        </SetupGridItem>
      )}
      <SetupGridItem
        label={<FormattedMessage {...messages.stateProjectIdTicHec} />}
      >
        <SetupValue
          editMode={editMode}
          name="setup.projectIdTic"
          value={projectIdTic}
          placeholder="i.e. AR012-345"
          onChange={onChange}
          editable={editMode}
          required
        />
      </SetupGridItem>
      <SetupGridItem
        label={<FormattedMessage {...messages.overrideQualification} />}
      >
        <SetupSwitch
          switchId={'override-qualification-switch'}
          editMode={editMode}
          name="setup.isOverrideQualificationEnabled"
          disabled={!editMode}
          value={isOverrideQualificationEnabled}
          onChange={onChange}
        />
      </SetupGridItem>
      {[
        <SetupGridItem
          label={<FormattedMessage {...messages.altXMLPropertyName} />}
        >
          <SetupValue
            editMode={editMode}
            name="setup.XMLPropertyName"
            value={XMLPropertyName}
            placeholder=""
            onChange={({ target: { name, value } }) => {
              onChange({
                target: {
                  name,
                  value: stripOnlyWhiteSpaceToNull(value),
                },
              });
            }}
            maxLength={'100'}
          />
        </SetupGridItem>,
        <SetupGridItem
          label={<FormattedMessage {...messages.altXMLProjectName} />}
          TooltipProps={{
            title: <FormattedMessage {...messages.altXMLProjectNameTooltip} />,
          }}
        >
          <SetupValue
            editMode={editMode}
            name="setup.XMLProjectName"
            value={XMLProjectName}
            placeholder=""
            onChange={({ target: { name, value } }) => {
              onChange({
                target: {
                  name,
                  value: stripOnlyWhiteSpaceToNull(value),
                },
              });
            }}
            maxLength={'100'}
          />
        </SetupGridItem>,
      ]}
      {rolloverIncomeAndAssets && (
        <>
          <Grid item xs={12} sm={6} />
          <SetupGridItem
            label={
              <FormattedMessage
                {...messagesAffordableSetup.autoRolloverIncomeAndAssets}
              />
            }
            TooltipProps={{
              title: (
                <FormattedMessage
                  {...messagesAffordableSetup.autoRolloverIncomeAndAssetsDescription}
                />
              ),
            }}
          >
            <SetupSwitch
              switchId={'lihtc-auto-rollover-income-and-assets-switch'}
              editMode={editMode}
              name="setup.LIHTCAutoRolloverIncomeAndAssets"
              disabled={!editMode}
              value={autoRolloverIncomeAndAssets}
              onChange={onChange}
            />
          </SetupGridItem>
        </>
      )}
      <Grid item xs={12}>
        <ProgramPassbookRate
          key={'LIHTC_PassbookRates'}
          programName={'LIHTC'}
          isLoadingPassbookRateData={isLoadingPassbookRateData}
          passbookRates={passbookRates}
        />
      </Grid>
    </Grid>
  );
};

export default LIHTCSetup;
