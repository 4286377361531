import { useState, useEffect } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import {
  useTableFilterSortSearchManager,
  useTableManageColumns,
  useTableFilterSortData,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useRows from './hooks.useRows';
import useHeaders from './hooks.useHeaders';
import useParseResults from './hooks.useParseResults';
import useModalProps from './hooks.useModalProps';
import useComplianceOverviewQuery from '../../hooks/data-fetching/useComplianceOverviewQuery';
import useUserOrganizationId from '../../hooks/useUserOrganizationId';
import useDownloadCSVPDF from './hooks.useDownloadCSVPDF';
import { COMPLIANCE_TYPES } from './constants';
import { PROPERTY_PATH_MAP } from './constantsV2';
import moment from 'moment-business-days';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import useCertStatusColumn from './hooks.useCertStatusColumn';

export const useComplianceOverview = ({ intl }) => {
  moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5],
  });

  const ldClient = useLDClient();
  useEffect(() => {
    ldClient.identify({
      kind: 'user',
      key: 'fortressUser',
      organizationId,
    });
  });

  const {
    complianceOverviewReviewedAndNumberOfDays,
    allOpenCertsNotStartedAndStatus,
  } = useFlags();

  const organizationId = useUserOrganizationId();

  const [complianceType, setComplianceType] = useState(
    complianceOverviewReviewedAndNumberOfDays
      ? COMPLIANCE_TYPES.ALL_OPEN_CERTS
      : COMPLIANCE_TYPES.NEEDS_REVIEW,
  );
  const [includeNotStartedCerts, setIncludeNotStartedCerts] = useState(false);

  const name = useUniqueTableName(`compliance-overview_${complianceType}`);
  // Fetch data
  const { results, isLoading } = useComplianceOverviewQuery({
    organizationId,
    complianceType,
    includeNotStartedCerts,
  });
  // Parse results
  const parsedResults = useParseResults({
    results,
    complianceType,
    complianceOverviewReviewedAndNumberOfDays,
  });

  // Manage Headers
  const headers = useHeaders({
    results,
    complianceType,
    complianceOverviewReviewedAndNumberOfDays,
    includeNotStartedCerts,
  });

  // Manage Modal
  const ModalProps = useModalProps();

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    initialOrderBy: complianceOverviewReviewedAndNumberOfDays
      ? 'voucherEffectiveDate'
      : 'uploadDate',
    initialOrder: 'desc',
    headers,
  });

  const isFeatureFlagActiveAndIncludeNotStartedCerts =
    allOpenCertsNotStartedAndStatus && includeNotStartedCerts;
  const sortedAndFilteredResults = useTableFilterSortData({
    results: parsedResults,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP:
      PROPERTY_PATH_MAP(isFeatureFlagActiveAndIncludeNotStartedCerts) ?? {},
  });

  const rows = useRows({
    sortedAndFilteredResults,
    ModalProps,
    isFeatureFlagActiveAndIncludeNotStartedCerts,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    handleTruncateChange,
    truncatedColumns,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const {
    isCertStatusVisible,
    toggleCertStatusColumn,
    shouldNotStartedCertsButtonBeDisplayed,
  } = useCertStatusColumn({
    selectedColumns,
    handleColumnChange,
    setIncludeNotStartedCerts,
    allOpenCertsNotStartedAndStatusFlag: allOpenCertsNotStartedAndStatus,
  });

  const { handleDownloadCSVPDF, isCSVPDFExportLoading } = useDownloadCSVPDF({
    complianceType,
    dateState,
    filterState,
    intl,
    order,
    orderBy,
    organizationId,
    searchState,
    isFeatureFlagActiveAndIncludeNotStartedCerts,
  });

  return {
    allColumnsHidden,
    columnOptions,
    complianceType,
    count: rows.length ?? 0,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleDownloadCSVPDF,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    handleTruncateChange,
    isLoading,
    isCSVPDFExportLoading,
    ModalProps,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    setComplianceType,
    sortedAndFilteredResults,
    totalCount: results.length ?? null,
    truncatedColumns,
    isCertStatusVisible,
    toggleCertStatusColumn,
    shouldNotStartedCertsButtonBeDisplayed,
  };
};
