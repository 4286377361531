import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AffordableQualificationDocumentService from '../../services/affordableQualificationDocumentService';
import KpiService from '../../services/kpiService';
import useAbortController from '../../hooks/useAbortController';
import { COMPLIANCE_TYPES } from '../../containers/ComplianceOverview/constants';

const useComplianceOverviewQuery = ({
  organizationId,
  complianceType,
  options,
  includeNotStartedCerts,
}: {
  organizationId: string,
  complianceType: string,
  options?: UseQueryOptions,
}) => {
  const { complianceOverviewReviewedAndNumberOfDays } = useFlags();
  const queryKey = [
    'useComplianceOverviewQuery',
    organizationId,
    complianceType,
    includeNotStartedCerts,
  ];

  const abortControllerOptions = useAbortController();

  const { data, isLoading } = useQuery(
    queryKey,
    async () => {
      let response = [];
      if (
        !complianceOverviewReviewedAndNumberOfDays ||
        complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
      ) {
        response =
          await new AffordableQualificationDocumentService().getComplianceDocumentsNeedsReview(
            organizationId,
            abortControllerOptions,
          );
      }

      if (complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS) {
        response = await new KpiService().getComplianceOverviewOpenCerts(
          organizationId,
          includeNotStartedCerts,
          abortControllerOptions,
        );
      }

      return response;
    },
    {
      enabled: Boolean(organizationId && complianceType),
      staleTime: 60000,
      ...options,
    },
  );

  const results = useMemo(() => data ?? [], [data]);

  return {
    results,
    isLoading,
  };
};

export default useComplianceOverviewQuery;
